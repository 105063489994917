export const userInfoKeymap = {
  userID: 'User ID',
  userName: 'Username',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  createdOn: 'Created',
  language: 'Language',
  userState: 'User Status',
  lastDateAttempted: 'Last Login Attempted',
  lastDateSucceded: 'Last Login Succeeded',
  failedAttemptCount: 'Failed Login Attempts Since Successful',
};

export const languageMap = {
  en: 'English (en)',
  es: 'Spanish (es)',
  fr: 'French (fr)',
  fr_ca: 'French Canadian (fr_ca)',
  it: 'Italian (it)',
  de: 'German (de)',
  pt_br: 'Portuguese (pt_br)',
  ja: 'Japanese (ja)',
  ko: 'Korean (ko)',
  zh: 'Chinese (zh)',
};

export const shopStateInfoKeymap = {
  shopState: 'Shop',
  shopSubscriptionState: 'Subscription',
  shopPaymentNotificationState: 'Payment Notification',
  braintreeSubscriptionState: 'Braintree Subscription',
};

export const addressInfoKeymap = {
  streetAddress1: 'Street Address 1',
  streetAddress2: 'Street Address 2',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  country: 'Country',
  phone: 'Phone',
};

export const shopInfoKeymap = {
  ...addressInfoKeymap,
  shopName: 'Shop Name',
}

export const paymentInfoKeymap = {
  customerId: 'Customer ID',
  subscriptionId: 'Subscription ID',
  planId: 'Plan ID',
  token: 'Token',
  status: 'Status',
  nextBillAmount: 'Next Bill',
  newBillAmount: 'New Bill Amount',
  nextBillingDate: 'Next Bill Date',
  paidThroughDate: 'Paid Thru',
  transactions: 'Transactions',
};

export const toolInfoKeymap = {
  toolManufacturer: 'Manufacturer',
  toolModel: 'Model',
  toolSerial: 'Serial Number',
  toolName: 'Tool Name',
  toolNotes: 'Tool Notes',
  toolID: 'Tool ID',
  shopID: 'Shop ID',
  addedBy: 'Added By',
  addedOn: 'Added On',
  toolModelUUID: 'Model UUID',
  toolManufacturerUUID: 'Manufacturer UUID',
  vin: 'VIN',
  currentShopID: 'Current Shop ID',
  isCurrentlyAssignedToShop: 'Is Currently Assigned to Shop',
  lastADExecuteDate: 'Last ADExecute Date',
  lastADInitiateDate: 'Last ADInitiate Date',
  lastADRequestDate: 'Last ADRequest Date',
  lastErrorDate: 'Last Error Date',
  totalADExecuteEvents: 'Total ADExecute Events',
  totalADInitiateEvents: 'Total ADInitiate Events',
  totalADRequestEvents: 'Total ADRequest Events',
  totalToolErrorEvents: 'Total Tool Error Events'
};
