import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { OptionType } from '../../components/ReactSelect/Option';

const DropdownIndicator = (props: DropdownIndicatorProps<OptionType, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="c-select-chevron"></span>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
