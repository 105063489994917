import React from 'react';
import parse from 'html-react-parser';
import CopyLink from '../../components/CopyLink';
import { userInfoKeymap, shopInfoKeymap } from './tableKeymaps';

export const LogSubcomponent = (row) => {
  const {
    addUserID,
    removeUserID,
    shopUserRole,
    shopUserState,
    targetUserID,
    email,
    userName,
    userEmail,
    numToAdd,
    shopID,
    shopName,
    statusChange,
    response,
    linkID,
    linkState,
    shopSubscriptionNotificationState,
    shopSubscriptionState,
    toolID,
    toolMfgUUID,
    toolModelUUID,
    toolManufacturer,
    toolModel,
    toolSerial,
    toolName,
    toolNotes,
    imageUrl,
    reason,
    requesterTicketID,
    ownerTicketID,
    recoveryStatus,
    apiErrorMessage,
    errorDetail,
    oemID,
    sourceIP,
    vin,
    newNumAddons,
    newShopUserCap,
    newShopToolCap,
    newShopUserMax,
    userID,
    userChanges,
    shopChanges,
    oemIDs,
    oemNames,
    removedOemIDs,
    removedOemNames,
    actionCode,
    reinstatedOems,
    addedOems,
    reinstatedOemNames,
    addedOemNames
  } = row.original;

  let userObj = {
    'User ID': userID,
    'Added User ID': addUserID,
    'Removed User ID': removeUserID,
    'Target User ID': targetUserID,
    'Shop User Role': shopUserRole,
    'Shop User State': shopUserState,
    'Email': email,
    'Username': userName,
    'User Email': userEmail,
    'User Changes': userChanges,
  };

  let userCard = cardBodyBuilder(userObj);

  let shopObj = {
    'Number Added': numToAdd,
    'Shop ID': shopID,
    'Shop Name': shopName,
    'Status Change': statusChange,
    'Response': response,
    'Link ID': linkID,
    'Link State': linkState,
    'Updated Number of User Addons': newNumAddons,
    'New Shop User Cap': newShopUserCap,
    'New Shop Tool Cap': newShopToolCap,
    'New Shop User Max': newShopUserMax,
    'Shop Changes': shopChanges,
    'Removed Manufacturer': removedOemNames ?? removedOemIDs,
    'Updated Manufacturers List': actionCode === "UPDATE_SHOP_OEM_REGIONS"
      || (actionCode === "REMOVE_SHOP_OEM_REGIONS" && !removedOemIDs)
      ? (oemNames ?? oemIDs)
      : undefined,
  };

  if (actionCode === 'REINSTATE_SHOP_OEM_REGIONS') {
    if (reinstatedOems?.length || addedOems?.length) {
      if (reinstatedOems?.length) {
        shopObj['Reinstated Manufacturers'] = reinstatedOemNames ?? reinstatedOems;
      }
      if (addedOems?.length) {
        shopObj['Added Manufacturers'] = addedOemNames ?? addedOems;
      }
    } else {
      shopObj['Added/Reinstated Manufacturers'] = oemNames ?? oemIDs;
    }
  }

  let shopCard = cardBodyBuilder(shopObj);

  let subscriptionObj = {
    'Subscription Notification': shopSubscriptionNotificationState,
    'Subscription': shopSubscriptionState,
  };
  let subscriptionCard = cardBodyBuilder(subscriptionObj);

  let rejectReasons = {
    'toolInCorrectShop': 'This tool is already in the correct shop',
    'requestMistake': 'This request was made by mistake',
    'maliciousRequest': 'This request is redundant or malicious',
  };

  let toolObj = {
    'Tool ID': toolID,
    'Tool Manufacturer': toolManufacturer,
    'Tool Model': toolModel,
    'Tool Serial': toolSerial,
    'Tool Mfg. UUID': toolMfgUUID,
    'Tool Model UUID': toolModelUUID,
    'Tool Name': toolName,
    'Tool Notes': toolNotes,
    'Image': imageUrl,
    'Reason': rejectReasons[reason],
    'Requester Ticket ID': requesterTicketID,
    'Owner Ticket ID': ownerTicketID,
    'Recovery Status': recoveryStatus,
    'API Error': apiErrorMessage,
    'VIN': vin,
    'Error Detail': errorDetail,
    'OEM': oemID,
    'Source IP': sourceIP,
  };

  let toolCard = cardBodyBuilder(toolObj, {
    'API Error': 'u-text-error',
    'Error Detail': 'u-text-error',
  });

  let vehicleObj;

  let vinCache = JSON.parse(localStorage.getItem('vinCache'));
  if (vinCache && vin && vinCache.hasOwnProperty(vin)) {
    let { make, model, year, trim } = vinCache[vin];

    vehicleObj = {
      VIN: vin,
      Make: make || 'The make could not be determined',
      Model: model || 'The model could not be determined',
      Year: year || 'The year could not be determined',
      Trim: trim || 'The trim could not be determined',
    };
  }

  let vehicleCard = vehicleObj ? cardBodyBuilder(vehicleObj) : null;

  let showUserCard = userCard !== null;
  let showShopCard = shopCard !== null;
  let showSubscriptionCard = subscriptionCard !== null;
  let showToolCard = toolCard !== null;
  let showVehicleCard = vehicleCard !== null;

  if (
    [
      showUserCard,
      showShopCard,
      showSubscriptionCard,
      showToolCard,
      showVehicleCard,
    ].every((entry) => !entry)
  ) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="l-flex-between u-margin-top">
        {(showUserCard || showSubscriptionCard) && (
          <div className="u-margin-right">
            {showUserCard && (
              <div className="c-card u-margin-bottom">
                <h3 className="c-card__title u-margin-none">User Related</h3>
                <div className="c-card__description u-margin-none">
                  <table className="card-table text-smaller">
                    <colgroup>
                      <col className="card-table-col-labels-20" />
                      <col />
                    </colgroup>
                    <tbody>{userCard}</tbody>
                  </table>
                </div>
              </div>
            )}
            {showSubscriptionCard && (
              <div className="c-card u-margin-bottom">
                <h3 className="c-card__title u-margin-none">
                  Subscription Related
                </h3>
                <div className="c-card__description u-margin-none">
                  <table className="card-table text-smaller">
                    <colgroup>
                      <col className="card-table-col-labels-20" />
                      <col />
                    </colgroup>
                    <tbody>{subscriptionCard}</tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {showShopCard && (
        <div className="c-card u-margin-bottom">
          <h3 className="c-card__title u-margin-none">Shop Related</h3>
          <div className="c-card__description u-margin-none">
            <table className="card-table text-smaller">
              <colgroup>
                <col className="card-table-col-labels-20" />
                <col />
              </colgroup>
              <tbody>{shopCard}</tbody>
            </table>
          </div>
        </div>
      )}
      <div className="l-flex-between">
        <div className="u-margin-right">
          {showToolCard && (
            <div className="c-card u-margin-bottom">
              <h3 className="c-card__title u-margin-none">Tool Related</h3>
              <div className="c-card__description u-margin-none">
                <table className="card-table text-smaller">
                  <colgroup>
                    <col className="card-table-col-labels-20" />
                    <col />
                  </colgroup>
                  <tbody>{toolCard}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div>
          {showVehicleCard && (
            <div className="c-card u-margin-bottom">
              <h3 className="c-card__title u-margin-none">Vehicle Related</h3>
              <div className="c-card__description u-margin-none">
                <table className="card-table text-smaller">
                  <colgroup>
                    <col className="card-table-col-labels-20" />
                    <col />
                  </colgroup>
                  <tbody>{vehicleCard}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export const EmailLogSubcomponent = (row) => {
  let trimmedHtml = '';
  if (row.original.messageBodyHtml) {
    trimmedHtml = row.original.messageBodyHtml
      .replace('<html>', '')
      .replace('</html>', '');
  }
  let trimmedText = '';
  if (row.original.messageBodyText) {
    trimmedText = row.original.messageBodyText.replace(/\n/g, '<br>');
  }

  return (
    <div className="c-card u-margin-bottom u-margin-top expander">
      <h3 className="c-card__title u-margin-none">Body</h3>
      <div className="c-card__description u-margin-none">
        {row.original.messageBodyHtml
          ? parse(trimmedHtml)
          : parse(trimmedText)}
      </div>
    </div>
  );
};


export const SupportLogSubcomponent = (row) => {
  const { newUserID, queryType, queryValue, shopID } = row.original;

  let cardBodyBuilder = (cardObj) => {
    if (
      Object.keys(cardObj).every((attr) => {
        return cardObj[attr] === undefined;
      })
    ) {
      return null;
    }
    return Object.keys(cardObj).map((attr) => {
      let attrValue = cardObj[attr];
      if (attrValue !== undefined) {
        if (typeof attrValue === 'boolean') {
          attrValue = attrValue ? 'TRUE' : 'FALSE';
        }
        return (
          <tr className="card-tr" key={attr}>
            <th className="card-th">{attr}: </th>
            <td className="card-td">{attrValue}</td>
          </tr>
        );
      }
      return null;
    });
  };

  let supportLogCardObj = {
    'New User ID': newUserID,
    'Query Type': queryType,
    'Query Value': queryValue,
    'Shop ID': shopID,
  };

  let supportLogCard = cardBodyBuilder(supportLogCardObj);

  let showSupportLogCard = supportLogCard !== null;

  if (!showSupportLogCard) {
    return null;
  }
  return (
    <div className="l-flex-between u-margin-top">
      <div className="c-card u-margin-bottom u-margin-top">
        <h3 className="c-card__title u-margin-none">Additional Fields</h3>
        <div className="c-card__description u-margin-none">
          <table className="card-table text-smaller">
            <colgroup>
              <col className="card-table-col-labels-20" />
              <col />
            </colgroup>
            <tbody>{supportLogCard}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export const TransactionSubComponent = (row) => {
  const { oemNames,
          userAddons,
          numUsersAdded,
          addedOemNames,
          type,
          techCertAddons,
          numTechCertsAdded,
          refundDetails, } = row.original;

  // Build an array of items to list under 'Refunded:' field
  const refundedList = [];
  if (typeof refundDetails === 'object' && refundDetails !== null) {
    for (let key in refundDetails) {
      if (key === 'plusUpgradeRefunded') {
        refundedList.push('Plus Upgrade');
      // Skip the oem ID field if oem name is present
      } else if ((key === 'oemIDRefunded' && refundDetails.oemNameRefunded)
                // Skip the reason if there is one
                || key === 'reason') {
        continue;
      } else {
        refundedList.push(refundDetails[key]);
      }
    }
  }

  const subObj = {
    'OEMs': oemNames,
    'User Addons': userAddons,
    'OEM Addons': oemNames ? oemNames.length - 1 : 0,
    'Tech Certification Addons': techCertAddons || 0
  };

  const tranObj = {
    'Number of Users Added': numUsersAdded,
    'OEM(s) Added': addedOemNames,
    'Number of TechCertsAdded': numTechCertsAdded,
    'Refunded': refundedList.length ? refundedList : undefined,
    'Refund Reason': refundDetails?.reason,
  };

  const subCard = cardBodyBuilder(subObj);
  const tranCard = cardBodyBuilder(tranObj);

  if (type !== 'paymentRefund' || refundDetails) {
    return (
      <React.Fragment>
        {((type !== 'subscriptionRenew' || type !== 'initialSubscriptionCharge')
          && Object.keys(tranObj).length > 0) && (
          <div className="u-margin-right">
            <div className="c-card u-margin-bottom">
              <h3 className="c-card__title u-margin-none">Transaction Details</h3>
              <div className="c-card__description u-margin-none">
                <table className="card-table text-smaller">
                  <colgroup>
                    <col className="card-table-col-labels-20" />
                    <col />
                  </colgroup>
                  <tbody>{tranCard}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {Object.keys(subObj).length && type !== 'paymentRefund' && (
          <div className="c-card u-margin-bottom">
            <h3 className="c-card__title u-margin-none">Subscription Details</h3>
            <div className="c-card__description u-margin-none">
              <table className="card-table text-smaller">
                <colgroup>
                  <col className="card-table-col-labels-20" />
                  <col />
                </colgroup>
                <tbody>{subCard}</tbody>
              </table>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  } else {
    return null;
  }
}

const cardBodyBuilder = (cardObj, styles) => {
  let monoFields = [
    'Added User ID',
    'Removed User ID',
    'Target User ID',
    'Email',
    'Username',
    'User Email',
    'Shop ID',
    'Shop Name',
    'Link ID',
    'Tool ID',
    'Tool Mfg. UUID',
    'Tool Model UUID',
    'Tool Serial',
    'VIN',
  ];

  let clickable = {
    'User ID': {
      type: 'USER ID',
      redirect: '/userFunctions/getUserSummary',
      queryType: 'USER+ID',
    },
    'Added User ID': {
      type: 'USER ID',
      redirect: '/userFunctions/getUserSummary',
      queryType: 'USER+ID',
    },
    'Removed User ID': {
      type: 'USER ID',
      redirect: '/userFunctions/getUserSummary',
      queryType: 'USER+ID',
    },
    'Target User ID': {
      type: 'USER ID',
      redirect: '/userFunctions/getUserSummary',
      queryType: 'USER+ID',
    },
    'Username': {
      type: 'USERNAME',
      redirect: '/userFunctions/getUserSummary',
      queryType: 'USERNAME',
    },
    'User Email': {
      type: 'EMAIL',
      redirect: '/userFunctions/getUserSummary',
      queryType: 'EMAIL',
    },
    'Added By': {
      type: 'USER ID',
      redirect: '/userFunctions/getUserSummary',
      queryType: 'USER+ID',
    },
    'Shop ID': {
      type: 'SHOP ID',
      redirect: '/shopFunctions/getShopSummary',
      queryType: 'SHOP+ID',
    },
    'Shop Name': {
      type: 'SHOP NAME',
      redirect: '/shopFunctions/getShopSummary',
      queryType: 'SHOP+NAME',
    },
    'Current Shop ID': {
      type: 'SHOP ID',
      redirect: '/shopFunctions/getShopSummary',
      queryType: 'SHOP+ID',
    },
    'Tool ID': {
      type: 'TOOL ID',
      redirect: '/shopFunctions/toolLookup',
      queryType: 'TOOL+ID',
    },
    'Tool Serial': {
      type: 'TOOL SERIAL',
      redirect: '/shopFunctions/toolLookup',
      queryType: 'TOOL+SERIAL',
    },
  };

  if (
    Object.keys(cardObj).every((attr) => {
      return cardObj[attr] === undefined;
    })
  ) {
    return null;
  }
  return Object.keys(cardObj).map((attr) => {

    let attrValue = cardObj[attr];
    if (attrValue !== undefined) {
      if (attr !== "User Changes" && attr !== "Shop Changes") {
        attrValue = normalizeField(attrValue);
      }

      let monoStyle = monoFields.includes(attr) ? 'u-font-mono' : '';

      return (
        <tr className="card-tr" key={attr}>
          <th className={`card-th ${styles ? styles[attr] : ''}`}>{attr}: </th>
          <td className={`card-td ${monoStyle} ${styles ? styles[attr] : ''}`}>
            {clickable.hasOwnProperty(attr) ?
              <CopyLink
                content={attrValue}
                type={clickable[attr].type}
                redirect={clickable[attr].redirect}
                urlParams={`?query=${attrValue}&queryType=${clickable[attr].queryType}`}
              />
            : attr === 'Image' ? (
              <button
                className="c-btn-icon"
                onClick={() => window.open(attrValue, '_blank')}
              >
                <div className="c-btn__inner">
                  <span
                    className="c-btn__icon fa fa-image"
                    title="Image URL"
                  />
                </div>
              </button>
            ) : (attr === "User Changes" || attr === "Shop Changes") ? (
              <div className="l-inline-flex">
                {Array.isArray(attrValue) ?
                  attrValue.map((change) => (
                    <span className="u-padding-right-large">
                      <div className="u-font-weight-bold">
                          {attr === "User Changes" ?
                          userInfoKeymap[change.field] :
                          shopInfoKeymap[change.field]}
                      </div>
                      <div className="u-text-warning">
                        {!change.oldVal ? '<None>' : change.oldVal}
                      </div>
                      <div className="">
                        &#x2B07;
                      </div>
                      <div className="u-text-success">
                        {!change.newVal ? '<None>' : change.newVal}
                      </div>
                    </span>
                  ))
                  :
                  attrValue
                }
              </div>
            ) : (
              attrValue
            )}
          </td>
        </tr>
      );
    }
    return null;
  });
};

const normalizeField = (attrValue) => {
  if (typeof attrValue === 'boolean') {
    return attrValue ? 'TRUE' : 'FALSE';
  }

  if (Array.isArray(attrValue)) {
    return attrValue.join(', ');
  }

  if (typeof attrValue === 'object') {
    return JSON.stringify(attrValue);
  }

  return attrValue;
};
