import React from 'react';

interface ChipProps {
  fontWeight: string;
  backgroundColor: string;
  color: string;
  border: string;
  children: string | undefined;
}

const Chip = ({
  fontWeight,
  backgroundColor,
  color,
  border,
  children,
}: ChipProps) => {
  return (
    <span
      style={{
        color: color,
        fontWeight: fontWeight,
        backgroundColor: backgroundColor,
        border: border,
        borderRadius: '25px',
        padding: '0.5rem 1rem',
        cursor: 'none',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </span>
  );
};

export default Chip;
