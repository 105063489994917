import React, { useEffect, useReducer } from 'react';
import { initialState, reducer } from './store';
import FadeIn from 'react-fade-in';
import _ from 'underscore';

import FloatingButton from '../../../components/FloatingButton';
import { Header } from '../../../components/Header';
import LoaderButton from '../../../components/LoaderButton';
import CustomDatePicker from '../../../components/CustomDatePicker';
import GenericTable from '../../../components/GenericTable';
import CustomReactSelect from '../../../components/CustomReactSelect';
import Switch from '../../../components/Switch';
import FieldWithHistory from '../../../components/FieldWithHistory';
import HelpTooltip from '../../../components/Tooltips/HelpTooltip';
import { OptionType } from '../../../components/ReactSelect/Option';

import { getExtendedToolInfo, getToolLog } from '../../../libs/db-lib';

import {
  allFilterKeys,
  logTableStructure,
} from '../../../libs/tables/logTableStructure';
import {
  extractAndDecodeVinsFromLog,
  getRecentSearches,
  setRecentSearches,
  storeClipboard,
} from '../../../libs/utils-ts';
import { formatTimezone, toolValidate } from '../../../libs/utils';

import { baseLogColumns } from '../../../libs/tables/columnDefinitions';
import { LogSubcomponent } from '../../../libs/tables/subComponents';

import { ChildProps, Log, Tool } from '../../../types';

import {
  setLog,
  setGroupedLog,
  setQuery,
  setQueryText,
  setQueryType,
  setOptional,
  setOptionalText,
  setQueryError,
  setStartDate,
  setEndDate,
  setSelectedActionCodes,
  setIsLoading,
  setQueriedTool,
  setToolFound,
  setTabset,
  setShowLog,
  setShowSelectTool,
  setTools,
  setShowToolIDCol,
  setShowActionCodeCol,
  setEnterPressed,
} from './store/actions';
import CopyData from '../../../components/CopyData';

let state = initialState;

const GetToolLog = (props: ChildProps) => {
  const [getToolLogStore, dispatch] = useReducer(reducer, state);
  const {
    log,
    groupedLog,
    queryText,
    queryError,
    optionalText,
    queryType,
    startDate,
    endDate,
    selectedActionCodes,
    isLoading,
    queriedTool,
    toolFound,
    tabset,
    showLog,
    showSelectTool,
    tools,
    showToolIDCol,
    showActionCodeCol,
    enterPressed,
  } = getToolLogStore;

  let { query, optional } = getToolLogStore;
  state = { ...getToolLogStore, isLoading: false };

  let pageData: { [k: string]: any } = {
    'Query Type': queryType,
    Query: query,
    'Date Range': `${formatTimezone(
      startDate.utc().toISOString()
    )} to ${formatTimezone(endDate.utc().toISOString())}`,
    'Tool Log Count': log.length,
    'Tool Log': log,
  };

  storeClipboard(pageData);

  const topRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    let groups = log.reduce((group: any, entry: any) => {
      group[entry.actionCode] = group[entry.actionCode] || [];
      group[entry.actionCode].push(entry);
      return group;
    }, Object.create(null));
    setGroupedLog(dispatch, groups);
  }, [log]);

  const handleChangeQuery = _.debounce((query: string) => {
    if (queryType === 'TOOL ID') {
      setQueryError(
        dispatch,
        !toolValidate(query)
          ? 'Tool ID must be exactly 64 characters long (lowercase letters and numbers).'
          : ''
      );
      query = query.toLowerCase();
    } else {
      setQueryError(dispatch, '');
    }
    setQuery(dispatch, query.trim());
  }, 300);

  const handleChangeOptional = _.debounce((optional: string) => {
    optional = optional.toLowerCase();
    setOptional(dispatch, optional.trim());
  }, 300);

  const handleChangeQueryType = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setQueryError(dispatch, '');
    setQueryType(dispatch, event.target.value);
    query = ''
    pageData.Query = ''
    setQueryText(dispatch, '');
    setOptionalText(dispatch, '');
    handleChangeOptional('')
  };

  const handleRangeChange = (value: any) => {
    setStartDate(dispatch, value.start);
    setEndDate(dispatch, value.end);
  };

  const validateForm = () => {
    switch (queryType) {
      case 'TOOL ID':
        return toolValidate(query);
      case 'TOOL SERIAL':
        return query.length > 0;
      default:
        return false;
    }
  };

  const handleSubmit = async (
    e?: React.SyntheticEvent,
    queryBySelectTool?: string,
    queryTypeBySelectTool?: string
  ) => {
    e?.preventDefault();
    setShowLog(dispatch, false);
    setShowSelectTool(dispatch, false);
    setTools(dispatch, []);
    setIsLoading(dispatch, true);
    setLog(dispatch, []);
    setSelectedActionCodes(dispatch, []);
    setGroupedLog(dispatch, {} as Log);
    setQueriedTool(dispatch, {} as Tool);
    setToolFound(dispatch, false);

    const startDateStr = startDate.utc().toISOString();
    const endDateStr = endDate.utc().toISOString();

    try {
      let result: any = null;

      if (queryBySelectTool && queryTypeBySelectTool) {
        setQueriedTool(dispatch, {
          toolID: queryBySelectTool,
          toolModel: '',
          toolManufacturer: '',
        } as Tool);
        result = await getToolLog(
          'TOOL ID',
          queryBySelectTool,
          undefined,
          startDateStr,
          endDateStr
        );
        setToolFound(dispatch, true);
      } else if (queryType === 'TOOL SERIAL') {
        // See if tool serial is associated first
        let toolResults = await getExtendedToolInfo(query, queryType);

        if (Object.hasOwn(toolResults, 'error')) {
          if ([401, 403].indexOf(toolResults.error.status) === -1) {
            props.handleShowAlert('Error', toolResults.error);
          }
          return;
        }
        // If not associated, use tool serial index when querying for logs
        if (toolResults?.error === 'Tool was not found!') {
          result = await getToolLog(
            'TOOL SERIAL',
            query,
            optional || undefined,
            startDateStr,
            endDateStr
          );
          setQueriedTool(dispatch, {
            toolSerial: query,
            shopID: optional ? optional : toolResults.shopID,
          } as Tool);
        } else if (toolResults.tools.length === 1) {
          // Otherwise, we can use its tool ID to search for logs instead
          // If we find a single tool, it will be returned in object form.
          result = await getToolLog(
            'TOOL ID',
            toolResults.tools[0].toolID,
            optional || undefined,
            startDateStr,
            endDateStr
          );
          setQueriedTool(dispatch, {
            toolID: toolResults.tools[0].toolID,
            shopID: optional ? optional : toolResults.tools[0].shopID,
          } as Tool);
          setToolFound(dispatch, true);
        } else {
          // Otherwise we found multiple tools
          // We'll filter on the toolResults if a shopID was specified
          if (optional) {
            toolResults = toolResults.tools.filter(
              (tool: any) => tool.shopID === optional
            );
          }
          if (toolResults.length === 0) {
            props.handleShowAlert(
              'Get Logs',
              <span>
                No logs found for query: <strong>{query}</strong> in that date
                range.
              </span>,
              false
            );
            return;
          }
          setShowSelectTool(dispatch, true);
          setTools(dispatch, toolResults);
          setIsLoading(dispatch, false);
          setToolFound(dispatch, true);
          return;
        }
      } else {
        let toolInfo = await getExtendedToolInfo(query, queryType);
        setToolFound(dispatch, true);

        if (toolInfo.error === 'Tool was not found!') {
          setToolFound(dispatch, false);
        }
        setQueriedTool(dispatch, {
          toolID: query,
          shopID: optional,
          toolModel: '',
          toolManufacturer: '',
        } as Tool);
        result = await getToolLog(
          queryType,
          query,
          optional || undefined,
          startDateStr,
          endDateStr
        );
      }

      if (!result || Object.hasOwn(result, 'error')) {
        if ([401, 403].indexOf(result.error.status) === -1) {
          props.handleShowAlert('Error', result.error);
        } else {
          return;
        }
        if (result.error.message) {
          props.handleShowAlert('Error', result.error.message);
        } else {
          props.handleShowAlert('Error', JSON.stringify(result.error));
        }
      } else if (result.logEntries.length === 0) {
        props.handleShowAlert(
          'Get Logs',
          <span>
            No logs found for query: <strong>{query}</strong> in that date
            range.
          </span>,
          false
        );
      } else {
        await extractAndDecodeVinsFromLog(result.logEntries);
        setLog(dispatch, result.logEntries);
        setShowLog(dispatch, true);
      }
    } catch (e: any) {
      props.handleShowAlert('Error', JSON.stringify(e));
    } finally {
      setIsLoading(dispatch, false);
      let recentSearches = getRecentSearches(queryType);

      if (recentSearches) {
        if (recentSearches.length > 4) recentSearches.pop();
        if (recentSearches.indexOf(query) === -1) recentSearches.unshift(query);
      } else {
        recentSearches = [query];
      }
      setRecentSearches(queryType, recentSearches);
    }
  };

  if (enterPressed) {
    handleSubmit();
    setEnterPressed(dispatch, false);
  }

  const getPlaceholder = () => {
    switch (queryType) {
      case 'TOOL ID':
        return '64 characters long';
      case 'TOOL SERIAL':
        return 'Serial numbers vary';
    }
  };

  const getQueryTypeHelper = () => {
    switch (queryType) {
      case 'TOOL ID':
        return `${queryType} (lowercase)`;
      case 'TOOL SERIAL':
        return `${queryType} (case-sensitive)`;
    }
  };

  return (
    <>
      <Header context="Shop Functions" title="Get Tool Log" />
      <FloatingButton
        onClick={() => {
          if (topRef && topRef.current) {
            topRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }}
      />
      <div className="l-container" ref={topRef}>
        <form onSubmit={handleSubmit}>
          <div className="c-field">
            <label htmlFor="queryType" className="c-field__label">
              Query Type
            </label>
            <div className="c-select">
              <select
                onChange={handleChangeQueryType}
                id="queryType"
                defaultValue={queryType}
              >
                {['TOOL ID', 'TOOL SERIAL'].map((type, i) => {
                  return <option key={i}>{type}</option>;
                })}
              </select>
            </div>
          </div>
          <div className={`c-field ${queryError && 'u-margin-bottom-none'}`}>
            <label
              htmlFor="query"
              className="c-field__label u-margin-top-large"
            >
              {getQueryTypeHelper()}
            </label>
            <FieldWithHistory
              fieldId="query"
              searchKey={queryType}
              selected={queryText}
              onInputChange={(e) => {
                if (e !== undefined) {
                  if (queryType === 'TOOL ID') {
                    e = e.toLowerCase();
                  }
                  setQueryText(dispatch, e);
                  handleChangeQuery(e);
                }
              }}
              onChange={(e) => {
                if (e !== undefined) {
                  if (queryType === 'TOOL ID') {
                    e = e.toLowerCase();
                  }
                  setQueryText(dispatch, e);
                  handleChangeQuery(e);
                }
              }}
              placeholder={getPlaceholder()}
              onEnter={() => {
                setEnterPressed(dispatch, true);
              }}
            />
          </div>
          {queryError && queryType === 'TOOL ID' && (
            <div className="u-text-error u-margin-left-large">
              <small>{queryError}</small>
            </div>
          )}
          <div className="c-field">
            <label
              htmlFor="query"
              className="c-field__label u-margin-top-large"
            >
              Shop ID (optional)
            </label>
            <FieldWithHistory
              fieldId="optional"
              searchKey={'SHOP ID'}
              selected={optionalText}
              onInputChange={(e) => {
                if (e !== undefined) {
                  e = e.toLowerCase();
                  setOptionalText(dispatch, e);
                  handleChangeOptional(e);
                }
              }}
              onChange={(e) => {
                if (e !== undefined) {
                  e = e.toLowerCase();
                  setOptionalText(dispatch, e);
                  handleChangeOptional(e);
                }
              }}
              placeholder="ex. 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'"
              onEnter={() => {
                setEnterPressed(dispatch, true);
              }}
            />
          </div>
          <div className="c-field">
            <label
              htmlFor="dateRange"
              className="c-field__label u-margin-top-large"
            >
              Date Range
            </label>
            <CustomDatePicker
              lang="en"
              idPrefix="tool"
              value={{
                start: startDate,
                end: endDate,
                name: 'Last 30 Days',
              }}
              drops={'down'}
              onChange={handleRangeChange}
              user={props.user}
            />
          </div>

          <div className="c-field">
            <LoaderButton
              type="submit"
              disabled={!validateForm()}
              isLoading={isLoading}
              text="Get Log"
              loadingText="Searching..."
            />
          </div>
        </form>
      </div>
      <FadeIn visible={!isLoading && showSelectTool}>
        <div>
          <h2>{tools?.tools?.length} result(s) found:</h2>
        </div>
        {tools?.tools?.map((tool: any) => {
          return (
            <div className="c-box" key={tool.toolID}>
              <div className="l-flex-between">
                <section className="c-section-alt">
                  <div className="c-section-alt__content">
                    <label className="c-section-alt__label">Tool</label>
                    <span className="c-section-alt__value">
                      {tool.toolManufacturer} {tool.toolModel}
                    </span>
                  </div>
                  <div className="c-section-alt__content">
                    <label className="c-section-alt__label">ID</label>
                    <span className="c-section-alt__value">{tool.toolID}</span>
                  </div>
                </section>
                <button
                  className="c-btn"
                  onClick={() => {
                    handleSubmit(undefined, tool.toolID, 'TOOL ID');
                  }}
                >
                  <div className="c-btn__inner">Get Log</div>
                </button>
              </div>
            </div>
          );
        })}
      </FadeIn>
      <FadeIn visible={!isLoading && showLog}>
        <div className="u-text-center">
          {queriedTool && (
            <>
              <h2 className="l-flex-center u-overflow-wrap-anywhere">
                Log Table For Tool:{' '}
                <span
                  className={`l-flex-align-center u-margin-left ${
                    toolFound ? '' : 'u-text-error'
                  }`}
                >
                  {queriedTool.toolID && (
                    <span className="u-font-mono">{queriedTool.toolID}</span>
                  )}
                  {queriedTool.toolSerial && (
                    <>
                      Serial #
                      <span className="u-font-mono">
                        {queriedTool.toolSerial}
                      </span>
                    </>
                  )}
                  {toolFound ? null : (
                    <HelpTooltip
                      onClick={() =>
                        props.handleShowAlert(
                          'Info',
                          'Tool is not assigned to a shop!',
                          false
                        )
                      }
                      label="Tool is not assigned to a shop!"
                    />
                  )}
                </span>
              </h2>
              <CopyData info={pageData}/>
              {queriedTool.shopID && (
                <>
                  <br />
                  <h2>
                    Shop ID:{' '}
                    <span className="u-font-mono">{queriedTool.shopID}</span>
                  </h2>
                </>
              )}
            </>
          )}
          <div className="c-tabset">
            <span
              className={`c-tab__item ${
                tabset === 'SINGLE' ? 'c-tab__item--active' : ''
              }`}
              onClick={() => setTabset(dispatch, 'SINGLE')}
            >
              Single Table
            </span>
            <span
              className={`c-tab__item ${
                tabset === 'MULTI' ? 'c-tab__item--active' : ''
              }`}
              onClick={() => setTabset(dispatch, 'MULTI')}
            >
              Multiple Table
            </span>
          </div>
        </div>

        {/* Multiple Table View */}
        {tabset === 'MULTI' && (
          <>
            <label
              htmlFor="selectActionCode"
              className="c-field__label u-margin-top-large"
            >
              Select Tables
            </label>
            <div className="c-field">
              <CustomReactSelect
                id="selectActionCode"
                options={Object.keys(groupedLog).map((key) => {
                  return { value: key, label: key } as OptionType;
                })}
                placeholder="Select Action Code(s)..."
                value={selectedActionCodes}
                onChange={(e) => setSelectedActionCodes(dispatch, e)}
              />
            </div>
            <div className="c-field">
              <Switch
                isChecked={showToolIDCol}
                states={{
                  active: 'Show Tool ID Column',
                  inactive: 'Show Tool ID Column',
                }}
                handleCheck={(e) => setShowToolIDCol(dispatch, e)}
              />
              <Switch
                isChecked={showActionCodeCol}
                states={{
                  active: 'Show Action Code Column',
                  inactive: 'Show Action Code Column',
                }}
                handleCheck={(e) => setShowActionCodeCol(dispatch, e)}
              />
            </div>

            {Object.keys(groupedLog).map((group) => {
              let actionCodeArr = selectedActionCodes.map(
                (action: OptionType) => {
                  return action.value;
                }
              );
              if (actionCodeArr.indexOf(group) === -1) {
                return null;
              }
              let groupStructure =
                logTableStructure[group as keyof typeof logTableStructure];
              let groupData = groupedLog[group as keyof Log];
              if (!groupStructure) return null;
              return (
                <div key={group}>
                  <br />
                  <hr />
                  <h3>{group}</h3>
                  <GenericTable
                    id={`${group}-table`}
                    data={(groupData as any[]) || []}
                    filterKeys={groupStructure.filterKeys}
                    columnDefs={groupStructure.columnDefs.filter((col) => {
                      if (col.accessor === 'toolID' && !showToolIDCol) {
                        return null;
                      }
                      if (col.accessor === 'actionCode' && !showActionCodeCol) {
                        return null;
                      }
                      return col;
                    })}
                    defaultSorted={[
                      {
                        id: 'actionDate',
                        desc: true,
                      },
                    ]}
                    defaultPageSize={5}
                    savedColumnsId={groupStructure.savedColumnsId}
                    subComponentRenderer={LogSubcomponent}
                  />
                </div>
              );
            })}
          </>
        )}

        {/* Single Table View */}
        {tabset === 'SINGLE' && (
          <>
            <GenericTable
              id="TOOL_LOG-table"
              data={log || []}
              filterKeys={allFilterKeys}
              columnDefs={baseLogColumns}
              defaultSorted={[
                {
                  id: 'actionDate',
                  desc: true,
                },
              ]}
              savedColumnsId={'fullLogTableColumns'}
              subComponentRenderer={LogSubcomponent}
            />
          </>
        )}
      </FadeIn>
    </>
  );
};

export default GetToolLog;
