import React, { useState } from 'react';
import { SupportUser } from '../types';
import { USER_TYPES } from '../CONSTANTS';
import { getUserData } from '../libs/utils-ts';

export interface UserContextInterface {
  user: SupportUser | null;
  error: { dataRetrieveError: string } | null;
  updateUser: (user: SupportUser | null) => void;
  fetchUser: () => void;
  isAdmin: boolean;
  isStandard: boolean;
  isExternal: boolean;
}

// create the language context with default selected language
export const UserContext = React.createContext<UserContextInterface>({
  user: null,
  error: null,
  updateUser: (user: SupportUser | null) => null,
  fetchUser: () => null,
  isAdmin: false,
  isStandard: false,
  isExternal: false,
});

export function useUser() {
  return React.useContext(UserContext);
}

type UserProviderType = {
  children: JSX.Element;
};

export function UserProvider({ children }: UserProviderType) {
  const [user, setUser] = useState<SupportUser | null>(null);
  const [error, setError] = useState<{ dataRetrieveError: string } | null>(
    null
  );

  const updateUser = (newUser: SupportUser | null) => {
    setUser(newUser);
  };

  const fetchUser = async () => {
    const response = await getUserData();
    if (response?.dataRetrieveError) {
      console.error('Data Retrieval Error: ', response.dataRetrieveError);
      // @ts-ignore - Types of property 'dataRetrieveError' are incompatible
      setError(response);
    } else {
      updateUser(response?.user || null);
    }
  };

  const isAdmin = user?.userType === USER_TYPES.ADMIN;
  const isStandard = user?.userType === USER_TYPES.STANDARD;
  const isExternal = user?.userType === USER_TYPES.EXTERNAL;

  const provider = {
    user,
    error,
    updateUser,
    fetchUser,
    isAdmin,
    isStandard,
    isExternal,
  };

  return (
    <UserContext.Provider value={provider}>{children}</UserContext.Provider>
  );
}
