import React, { useReducer } from 'react';
import { reducer, initialState } from './store/index';
import FadeIn from 'react-fade-in/lib/FadeIn';

import FieldWithHistory from '../../../components/FieldWithHistory';
import { Header } from '../../../components/Header';
import LoaderButton from '../../../components/LoaderButton';

import {
  getRecentSearches,
  setRecentSearches,
  storeClipboard,
} from '../../../libs/utils-ts';

import { ChildProps } from '../../../types';

import {
  setVin,
  setQueriedVin,
  setVehicleDetails,
  setShowVehicleInfo,
  setIsLoading,
  setEnterPressed,
} from './store/actions';

let state = initialState;

const VINDecoder = (props: ChildProps) => {
  const [getVinStore, dispatch] = useReducer(reducer, state);

  const {
    vin,
    queriedVin,
    vehicleDetails,
    showVehicleInfo,
    isLoading,
    enterPressed,
  } = getVinStore;

  state = { ...getVinStore, isLoading: false };

  storeClipboard(vehicleDetails);

  const handleSubmit = async (e?: React.SyntheticEvent) => {
    e?.preventDefault();

    setIsLoading(dispatch, true);
    try {
      const response = await fetch(
        `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      let data = await response.json();
      let results = data['Results'][0];
      const { Make, Model, ModelYear, Trim } = results;

      setVehicleDetails(dispatch, {
        make: Make,
        model: Model,
        year: ModelYear,
        trim: Trim,
      });
      setShowVehicleInfo(dispatch, true);
      setQueriedVin(dispatch, vin);
    } catch (e: any) {
      props.handleShowAlert(
        'Error',
        <span>
          {`Could not get vehicle details for this vin: `}
          <strong>{vin}</strong>
        </span>,
        false
      );
      console.log(e.message);
      setShowVehicleInfo(dispatch, false);
    } finally {
      setIsLoading(dispatch, false);

      let recentSearches = getRecentSearches('VIN');

      if (recentSearches) {
        if (recentSearches.length > 4) recentSearches.pop();
        if (recentSearches.indexOf(vin) === -1) recentSearches.unshift(vin);
      } else {
        recentSearches = [vin];
      }
      setRecentSearches('VIN', recentSearches);
    }
  };

  if (enterPressed) {
    handleSubmit();
    setEnterPressed(dispatch, false);
  }

  return (
    <>
      <Header context="Misc Functions" title="VIN Decoder" />

      <div className="l-container">
        <form onSubmit={handleSubmit}>
          <div className="c-field">
            <label htmlFor="vin" className="c-field__label u-margin-top-large">
              VIN (case-insensitive)
            </label>
            <FieldWithHistory
              fieldId="vin"
              searchKey="VIN"
              selected={vin}
              onInputChange={(e) => {
                if (e !== undefined) {
                  setVin(dispatch, e);
                }
              }}
              onChange={(e) => {
                if (e !== undefined) {
                  setVin(dispatch, e);
                }
              }}
              placeholder="ex. 3GNDA13D76S000000"
              onEnter={() => {
                setEnterPressed(dispatch, true);
              }}
            />
          </div>

          <div className="c-field">
            <LoaderButton
              type="submit"
              disabled={vin.length === 0}
              isLoading={isLoading}
              text="Decode"
              loadingText="Decoding..."
            />
          </div>
        </form>
      </div>
      <FadeIn visible={!isLoading && showVehicleInfo}>
        <div className="l-container-med u-margin-left-none">
          <div className="c-card">
            <h2 className="c-card__title">{`VIN Details: ${queriedVin}`}</h2>
            <div className="c-card__description">
              <table className="card-table">
                <colgroup>
                  <col className="card-table-col-labels" />
                  <col />
                </colgroup>
                <tbody>
                  {Object.keys(vehicleDetails).map((key) => {
                    return (
                      <tr className="card-tr" key={key}>
                        <th className="card-th">{key.toUpperCase()}: </th>
                        <td className="card-td">
                          {vehicleDetails[key as keyof typeof vehicleDetails]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </FadeIn>
    </>
  );
};

export default VINDecoder;
