import React, { ButtonHTMLAttributes } from 'react';

type LoaderButtonProps = {
  isLoading?: boolean;
  text: string;
  loadingText: string;
  className?: string;
  disabled?: boolean;
  icon?: string;
};

const LoaderButton: React.FC<
  LoaderButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  isLoading = false,
  text,
  loadingText,
  className = '',
  disabled = false,
  ...props
}) => (
  <button
    className={`c-btn ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    <div className="c-btn__inner">
      {isLoading ? (
        <i
          className="fal fa-spinner-third spinning u-margin-right-small"
          title={loadingText}
        />
      ) : props.icon ? (
        <i className={`${props.icon} u-margin-right-small`} title={text} />
      ) : null}

      {!isLoading ? text : loadingText}
    </div>
  </button>
);

export default LoaderButton;
