import React from 'react';
import { components } from 'react-select';

export interface OptionType {
  value: string;
  label: string;
}

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <label
          style={{
            color: '#556066',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            padding: '0 1rem',
          }}
        >
          {props.label}
        </label>
      </components.Option>
    </div>
  );
};

export default Option;
