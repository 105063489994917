import React, { Component } from 'react';
import AlertModal from '../../components/AlertModal';
import { Header } from '../../components/Header';
import { UserContextInterface, useUser } from '../../context/User';
import {
  getToolRecoveryImage,
} from '../../libs/db-lib';
import { ChildProps } from '../../types';

interface HomeProps extends ChildProps {
  history: any;
  userContext: UserContextInterface;
}

interface HomeState {
  isLoading: boolean;
  showModal: boolean;
  windowWidth: number;
  alertTitle?: string;
  alertMessage?: string | JSX.Element;
  queryType: string;
  query: string;
  imageData?: Blob;
}

class ToolRecoveryImage extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);
    this.state = {
      query: '',
      queryType: '',
      isLoading: false,
      showModal: false,
      windowWidth: window.innerWidth,
    };
  }

  componentDidUpdate(prevProps: HomeProps) {}

  async componentDidMount() {
    // Perform auto-search if needed
    let urlParams =
      window.name && window.name !== ''
        ? (JSON.parse(window.name as string) as any)
        : null;

    if (!urlParams || urlParams.type !== 'docKey') {
      return (
        <AlertModal
          title={'Login Error'}
          message={'No tool image document ID provided.'}
          showModal={true}
          handleCancel={() => { return null; }}
          size={'small'}
          BtnText={'OK'}
        />
      );
    } else {
      this.setState(
        {
          query: urlParams.content,
          queryType: urlParams.type,
        },
        () => {
          let self = this;
          window.name = '';
          setTimeout(async () => {
            await self.getImage();
          }, 500);
        }
      );
    }
  }

  getImage = async () => {

    this.setState({ isLoading: true });
    let { query } = this.state;

    const imageData = await getToolRecoveryImage(query);

    this.setState({
      isLoading: false,
      imageData: imageData
    });
  }

  render() {

    return (
      <main>
        <Header title="Tool Recovery Image" />
        {this.state.isLoading ?
          <div>Loading...</div>
          :
          <div><img src={this.state.imageData ? URL.createObjectURL(this.state.imageData) : ''} width={1000} alt={'ISSimage'}/></div>
        }
      </main>
    );
  }

}

export default (props: ChildProps) => {
  const userContext = useUser();
  return <ToolRecoveryImage userContext={userContext} {...props} />;
};
