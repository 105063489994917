import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/aa-support.svg';
import { Text, TextOnly } from '../components/Text';
import { UserState, UserType } from '../types';
import { permissions } from '../permissions';

// nav icons
import { ReactComponent as DashboardIcon } from '../assets/icons/nav-dashboard.svg';
import { ReactComponent as ManageUsersIcon } from '../assets/icons/nav-manage-users.svg';
import { ReactComponent as UserFunctionsIcon } from '../assets/icons/nav-user-functions.svg';
import { ReactComponent as ShopFunctionsIcon } from '../assets/icons/nav-shop-functions.svg';
import { ReactComponent as UserProfileIcon } from '../assets/icons/nav-user-profile.svg';
import { ReactComponent as MiscFunctionsIcon } from '../assets/icons/nav-misc-functions.svg';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import 'moment-timezone';
import { timezones } from '../libs/timezones';

type SidebarProps = {
  isAuthenticated: boolean;
  logout: () => void;
  toggleShowMobileNav: () => void;
  userName: string;
  userState: UserState;
  userType: UserType;
  userTimezone?: string;
};

export function Sidebar({
  isAuthenticated,
  logout,
  toggleShowMobileNav,
  userState,
  userType,
  userName,
  userTimezone,
}: SidebarProps) {
  const [tempTimezone, setTempTimezone] = useState('');

  if (!isAuthenticated || !userName) {
    return (
      <div className="c-sidebar">
        <div className="c-sidebar__header c-sidebar__header--empty">
          <NavLink
            className="c-primary-nav__link"
            to="/"
            activeClassName="is-active"
            onClick={toggleShowMobileNav}
          >
            <div className="c-logo">
              <img src={logo} className="c-logo__image" alt="logo" />
            </div>
          </NavLink>
          <p className="u-font-weight-500">Support Portal</p>
        </div>
      </div>
    );
  }

  const canViewUserFunctions =
    ['ADMIN', 'MANAGER', 'STANDARD', 'EXTERNAL'].indexOf(userType) !== -1;
  const canViewShopFunctions =
    ['ADMIN', 'MANAGER', 'STANDARD'].indexOf(userType) !== -1;
  const canViewMiscFunctions =
    ['ADMIN', 'MANAGER', 'STANDARD', 'EXTERNAL'].indexOf(userType) !== -1;

  return (
    <div className="c-sidebar">
      <div className="c-sidebar__header">
        <button
          className="c-btn-icon c-btn-mobile-close"
          onClick={toggleShowMobileNav}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <NavLink
          className="c-primary-nav__link"
          to="/dashboard"
          activeClassName="is-active"
          onClick={toggleShowMobileNav}
        >
          <div className="c-logo">
            <img src={logo} className="c-logo__image" alt="logo" />
          </div>
        </NavLink>
        <div className="l-container-center">
          <h2>{userType}</h2>
        </div>
      </div>
      <div className="c-sidebar__main">
        <div>
          <nav className="c-primary-nav" role="navigation">
            <ul className="c-primary-nav__list">
              <li className="c-primary-nav__item">
                <NavLink
                  className="c-primary-nav__link"
                  to="/dashboard"
                  activeClassName="is-active"
                  onClick={toggleShowMobileNav}
                >
                  <div className="c-btn__inner">
                    <DashboardIcon
                      className="c-primary-nav__icon"
                      title={'Home'}
                    />
                    Dashboard
                  </div>
                </NavLink>
              </li>

              {userType === 'ADMIN' && userState === 'ACTIVE' && (
                <li className="c-primary-nav__item">
                  <NavLink
                    className="c-primary-nav__link"
                    to="/manageUsers/currentUsers"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ManageUsersIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('manageUsers')}
                      />
                      Manage Users
                    </div>
                  </NavLink>
                </li>
              )}

              {canViewUserFunctions && userState === 'ACTIVE' && (
                <li className="c-primary-nav__item">
                  <NavLink
                    className={`c-primary-nav__link
                  }`}
                    to="/userFunctions/getUserSummary"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <UserFunctionsIcon
                        className="c-primary-nav__icon"
                        title="User Functions"
                      />
                      User Functions
                    </div>
                  </NavLink>
                  <ul className="c-primary-nav__subList">
                    {permissions[userType].map((listItem) => {
                      return (
                        listItem.parent === 'User Functions' && (
                          <li
                            className="c-primary-nav__subItem"
                            key={listItem.route}
                          >
                            <NavLink
                              className={`c-primary-nav__link`}
                              to={listItem.route}
                              activeClassName="is-active"
                              onClick={toggleShowMobileNav}
                            >
                              {listItem.title}
                            </NavLink>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </li>
              )}

              {canViewShopFunctions && userState === 'ACTIVE' && (
                <li className="c-primary-nav__item">
                  <NavLink
                    className={`c-primary-nav__link`}
                    to="/shopFunctions/getShopSummary"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ShopFunctionsIcon
                        className="c-primary-nav__icon"
                        title="Shop Functions"
                      />
                      Shop Functions
                    </div>
                  </NavLink>
                  <ul className="c-primary-nav__subList">
                    {permissions[userType].map((listItem) => {
                      return (
                        listItem.parent === 'Shop Functions' && (
                          <li
                            className="c-primary-nav__subItem"
                            key={listItem.route}
                          >
                            <NavLink
                              className={`c-primary-nav__link`}
                              to={listItem.route}
                              activeClassName="is-active"
                              onClick={toggleShowMobileNav}
                            >
                              {listItem.title}
                            </NavLink>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </li>
              )}

              {canViewMiscFunctions && userState === 'ACTIVE' && (
                <li className="c-primary-nav__item">
                  <NavLink
                    className={`c-primary-nav__link`}
                    to="/miscFunctions/vinDecoder"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <MiscFunctionsIcon
                        className="c-primary-nav__icon"
                        title="Misc Functions"
                      />
                      Misc Functions
                    </div>
                  </NavLink>
                  <ul className="c-primary-nav__subList">
                    {permissions[userType].map((listItem) => {
                      return (
                        listItem.parent === 'Misc Functions' && (
                          <li
                            className="c-primary-nav__subItem"
                            key={listItem.route}
                          >
                            <NavLink
                              className={`c-primary-nav__link`}
                              to={listItem.route}
                              activeClassName="is-active"
                              onClick={toggleShowMobileNav}
                            >
                              {listItem.title}
                            </NavLink>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </li>
              )}

              <li className="c-primary-nav__item u-text-transform-initial">
                <NavLink
                  className="c-primary-nav__link"
                  to="/userProfile"
                  activeClassName="is-active"
                  onClick={toggleShowMobileNav}
                >
                  <div className="c-btn__inner">
                    <UserProfileIcon
                      className="c-primary-nav__icon"
                      title={TextOnly('userProfile')}
                    />
                    {userName}
                  </div>
                </NavLink>
              </li>
              {JSON.parse(
                window.localStorage.getItem('convertingTimezones') as string
              ) === true &&
                userTimezone && (
                  <li className="c-primary-nav__item">
                    <Typeahead
                      clearButton
                      renderMenuItemChildren={(option, { text }, index) => (
                        <Fragment>
                          <Highlighter search={text as string}>
                            {`${option.label} ${
                              option.abbr ||
                              moment.tz(moment(), option.tzCode).zoneAbbr()
                            }`}
                          </Highlighter>
                          <div>
                            <small>{option.sub}</small>
                          </div>
                        </Fragment>
                      )}
                      id="timezone-picker"
                      labelKey={(option) =>
                        option.label && option.sub
                          ? `${option.label} ${option.sub}`
                          : ''
                      }
                      options={timezones as any[]}
                      onChange={(option: any) => {
                        if (option[0]) {
                          setTempTimezone(option[0].tzCode);
                          window.localStorage.setItem(
                            'tempTimezone',
                            option[0].tzCode
                          );
                          document.dispatchEvent(
                            new CustomEvent('renderTimezone', {
                              bubbles: true,
                              detail: { kind: 'modify' },
                            })
                          );
                          return;
                        }
                        setTempTimezone('');
                        window.localStorage.setItem('tempTimezone', '');
                        document.dispatchEvent(
                          new CustomEvent('renderTimezone', {
                            bubbles: true,
                            detail: { kind: 'reset' },
                          })
                        );
                      }}
                      selected={[
                        timezones.find((tz) => {
                          return tz.tzCode === tempTimezone;
                        }) || {},
                      ]}
                      placeholder="Apply a timezone..."
                    />
                  </li>
                )}
            </ul>
          </nav>
        </div>
        <ul className="c-sidebar__links">
          <li>
            <a
              className="c-sidebar__link c-btn-light"
              href="https://confluence.corp.ghsiss.com/pages/viewpage.action?spaceKey=AUT&title=Backend+Fields"
              target="_blank"
              rel="noopener noreferrer"
              role="button"
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-question-circle" />
                Docs
              </div>
            </a>
          </li>
          <li>
            <button className="c-sidebar__link c-btn-light" onClick={logout}>
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-sign-out" />
                <Text tid="logout" />
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
