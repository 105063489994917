import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

interface CopyLinkProps {
  content: string | JSX.Element;
  sub?: string | JSX.Element;
  type: string;
  redirect?: string;
  action?: string;
  showCopy?: boolean;
  className?: string;
  divClass?: string;
  urlParams?: string;
}

const CopyLink = (props: CopyLinkProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(props.content as string).then(function () {
      toast.success('Copied!', {
        containerId: 'standard',
      });
    });
  };

  const { content, sub, redirect, type, action, className, showCopy, divClass, urlParams } = props;
  const history = useHistory();

  return (
    <div
      className = {divClass ? divClass : ''}
      style={ divClass ?  {} : { display: 'flex', alignItems: 'center', wordBreak: 'break-word' }}
    >
      {redirect ? (
        <span
          style={{ fontSize: 'inherit', color: '#17a1e6', cursor: 'pointer' }}
          className={className || ''}
          onClick={() => {
            const url = `${redirect}${urlParams}`;
            history.push(url);
          }}
          // onAuxClick={() => {
          //   let newWindow = window.open(redirect);
          //   (newWindow as any).name = JSON.stringify({
          //     content:
          //       typeof content === 'string' ? content : content.props.children,
          //     type: type,
          //     action: action,
          //   });
          // }}
          // onContextMenu={(e) => {
          //   e.preventDefault();
          //   let newWindow = window.open(redirect);
          //   (newWindow as any).name = JSON.stringify({
          //     content:
          //       typeof content === 'string' ? content : content.props.children,
          //     type: type,
          //     action: action,
          //   });
          // }}
        >
          {sub || content}
        </span>
      ) : (
        content
      )}
      {showCopy !== false && (
        <button className="c-btn-icon" onClick={handleCopy}>
          <div className="c-btn__inner">
            <span className="c-btn__icon fa fa-clipboard" title="Copy" />
          </div>
        </button>
      )}
    </div>
  );
};

export default CopyLink;
