import React, { useState } from 'react';
import JoditEditor from 'jodit-react';
import Dialog from '@reach/dialog';

import { Header } from '../../components/Header';
import LoaderButton from '../../components/LoaderButton';
import _ from 'underscore';

import { ChildProps } from '../../types';

import {
  sendBulkEmail,
} from '../../libs/db-lib';

type JoditEditorConfig = {
  readonly: boolean;
  placeholder: string;
};

const EmailUsers = (props: ChildProps) => {
  const [usersType, setUsersType] = useState('SINGLE USER');
  const [isLoading, setLoading] = useState(false);
  const [htmlBody, setHtmlBody] = useState('');
  const [plainTextBody, setPlainTextBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [hideEmailField, setHideEmailField] = useState(false);
  const [showEmailUsersModal, setShowEmailUsersModal] = useState(false);

  const showConfirmModal = () => {
    setShowEmailUsersModal(true);
  }

  const handleCancelModal = () => {
    setLoading(false);
  }

  const handleSubmit = async (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    setLoading(true);
    if (validateFields()) {
      let result:any = await sendBulkEmail(usersType, emailAddress, emailSubject, htmlBody, plainTextBody);
      console.log("sendBulkEmail result: ", result);
      setShowEmailUsersModal(false);
      if (Object.hasOwn(result, 'error')) {
        if (result && [401, 403].indexOf(result.error?.status) !== -1) {
          return;
        } else {
          props.handleShowAlert(
            'Send Bulk Email',
            <span>
            { result.error.message }
            </span>,
            false
          );
        }
      } else {
        props.handleShowAlert(
          'Send Bulk Email',
          <span>
          Bulk Email has been queued.
          </span>,
          false
        );
      }
    }

  setLoading(false);
  };

  const handleChangeUserType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setUsersType(event.target.value);
    let newHideValue = true;
    if (event.target.value === "SINGLE USER") {
      newHideValue = false;
    }
    setHideEmailField(newHideValue);
  }

  const handleChangeTextEmailBody = (event) => {
    setPlainTextBody(event.target.value);
  };

  const handleChangeEmailAddress = (event) => {
    setEmailAddress(event.target.value);
  }

  const handleChangeEmailSubject = (event) => {
    setEmailSubject(event.target.value);
  }

  const validateFields = () => {
    return (htmlBody.length > 0 &&
            plainTextBody.length > 0 &&
            emailSubject.length > 0 &&
            (hideEmailField || emailAddress.length > 0))
  }

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/
    placeholder: 'Email HTML Body...',
    minHeight: '400px',
  };

  return (
    <>
      <Header context="Misc Functions" title="Email Users" />

      <div className="l-container">
        <div>
          <div className="c-field">
            <label htmlFor="userType" className="c-field__label">
              User Type
            </label>
            <div className="c-select">
              <select
                id="userType"
                defaultValue="SINGLE USER"
                onChange={handleChangeUserType}
              >
                {['ALL USERS', 'PLUS USERS', 'PLUS OWNERS', 'STANDARD USERS', 'STANDARD OWNERS', 'SINGLE USER'].map(
                  (type, i) => {
                    return <option key={i}>{type}</option>;
                  }
                )}
              </select>
            </div>
          </div>

          <div className={"c-field "+ (hideEmailField ? "hide" : "")}>
            <label
              htmlFor="emailAddress"
              className="c-field__label"
            >
              Email Address
            </label>
            <input
              type="text"
              id="emailAddress"
              maxLength={200}
              className="c-input"
              placeholder="Email Address"
              value={emailAddress}
              onChange={handleChangeEmailAddress}
            />
          </div>

          <div className={"c-field "}>
            <label
              htmlFor="subject"
              className="c-field__label"
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              maxLength={200}
              className="c-input"
              placeholder="Email Subject"
              value={emailSubject}
              onChange={handleChangeEmailSubject}
              required
              />
          </div>

          <div className="c-field">
            <label htmlFor="userType" className="c-field__label">
              HTML Email Body
            </label>
            <div className="c-field">
              <JoditEditor
                className="l-min-height-300"
                value={htmlBody}
                config={config}
                onBlur={newBody => setHtmlBody(newBody)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
              />
            </div>
          </div>
          <div
            className='c-field'
          >
            <label
              htmlFor="title"
              className='c-field__label'
            >
              Plain Text Email Body
            </label>
            <textarea
              className="c-textarea l-min-height-300 u-width-100-percent"
              id="body"
              name="body"
              value={plainTextBody}
              onChange={handleChangeTextEmailBody}
              placeholder={'Plain Text Email Body'}
              rows={5}
              cols={40}
              required
            />
          </div>

          <div className="c-field">
            <LoaderButton
              isLoading={isLoading}
              text="Queue Emails for Sending"
              onClick={showConfirmModal}
              disabled={!validateFields()}
              loadingText="Queueing..."
            />
          </div>
        </div>
      </div>

      <Dialog
        title="Confirm Bulk Email"
        className={"c-modal"}
        isOpen={showEmailUsersModal}
        onDismiss={handleCancelModal}
      >
        <button
          type="button"
          className="c-btn-icon c-modal__close"
          onClick={handleCancelModal}
          disabled={isLoading}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          Are you sure you want to send this bulk email?
        </h1>

        <div className="c-modal__body">
          <button
            className="c-btn-outline"
            onClick={handleCancelModal}
            disabled={isLoading}
          >
            Cancel
          </button>
          <span>{' '}</span>
          <LoaderButton
            type="button"
            isLoading={isLoading}
            text={'Queue Emails for Sending'}
            loadingText={'Queuing...'}
            onClick={handleSubmit}
          />
        </div>
      </Dialog>

    </>
  );
};

export default EmailUsers;
