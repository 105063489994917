import PapaParse from 'papaparse';
import _ from 'underscore';
import moment from 'moment';
import { timezones, abbreviations } from './timezones';
import { TextOnly } from '../components/Text';
import jsPDF from 'jspdf';
import { getOEMRegionList } from './db-lib';

// IE Polyfill
if (typeof Object.assign != 'function') {
  Object.assign = function (target) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

export function navbarSetup(state) {
  let returnState = {};

  returnState.name =
    state && state.user ? state.user.firstName + ' ' + state.user.lastName : '';
  returnState.emailVerified =
    state && state.user && state.user.emailVerified
      ? state.user.emailVerified
      : false;

  return returnState;
}

export function fieldChangeCase(
  self,
  eventTarget,
  targetCase,
  whitespace = true
) {
  let field = document.getElementById(eventTarget.id);
  let cursorLocation = eventTarget.selectionStart;
  let currentValue = field.value;
  let newValue;
  if (targetCase === 'lower') {
    newValue = currentValue.toLowerCase();
  } else {
    newValue = currentValue.toUpperCase();
  }
  if (!whitespace) {
    const reg = new RegExp(/^(\S*)$/);
    if (reg.test(newValue) === true) {
      self.setState({
        [eventTarget.id]: newValue,
      });
      field.value = newValue;
    }
  } else {
    self.setState({
      [eventTarget.id]: newValue,
    });
    field.value = newValue;
  }

  field.setSelectionRange(cursorLocation, cursorLocation);
}

export function formatUsers(users) {
  return users ? users.toString() : '';
}

export function strip(str) {
  return str.replace(/^\s+|\s+$/g, '');
}

export function idValidate(id) {
  let idRegex = new RegExp('^([a-zA-Z0-9]{0,40})$');
  const trimmedId = id.trim();
  return typeof trimmedId === 'string' && idRegex.test(trimmedId);
}

export function uuidValidate(uuid) {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(uuid);
}

export function customerIdValidate(customerId) {
  const regexExp = /^(\s{0}|\d+)$/g;
  return regexExp.test(customerId);
}

export function passwordValidate(password) {
  let strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-~_`|!@#$%^&*+.,?/(){}=:;"\'<>[\\]\\\\])(?=.{8,})'
  );
  const trimmedPass = password.trim();
  return (
    typeof trimmedPass === 'string' &&
    trimmedPass.length <= 50 &&
    strongRegex.test(trimmedPass)
  );
}

export function usernameValidate(username) {
  let usernameRegex = new RegExp('^([a-zA-Z]{1})([a-zA-Z0-9/.]{7,})$');
  const trimmedUsername = username.trim();
  return (
    typeof trimmedUsername === 'string' &&
    usernameRegex.test(trimmedUsername) &&
    trimmedUsername.length <= 50
  );
}

export function nameValidate(name) {
  let nameRegex = new RegExp("^([a-zA-Z0-9- .'`‘’]+)$");
  const trimmedName = name.trim();
  return (
    typeof trimmedName === 'string' &&
    nameRegex.test(trimmedName) &&
    trimmedName.length <= 50
  );
}

export function isValidEmail(email) {
  // the internet told me this matches 99% of email addresses
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let lowerEmail = email.toLowerCase();
  const trimmedEmail = lowerEmail.trim();
  return (
    typeof trimmedEmail === 'string' &&
    re.test(trimmedEmail) &&
    trimmedEmail.length <= 100
  );
}

export function isValidRole(role) {
  return ['ADMIN', 'MANAGER', 'STANDARD', 'EXTERNAL'].includes(role);
}

export function toolValidate(toolID) {
  let re = new RegExp('^([a-z0-9]{64})$');
  return re.test(toolID.trim());
}

export function timezoneValidate(tz) {
  return timezones.map((tz) => tz.tzCode).indexOf(tz) !== -1 || tz === '';
}

export function formatTimezone(value, timezone = '') {
  const convertingTimezones = JSON.parse(
    window.localStorage.getItem('convertingTimezones')
  );
  const userTimezone =
    window.localStorage.getItem('tempTimezone') ||
    (timezone !== '' ? timezone : window.localStorage.getItem('userTimezone'));
  const abbr =
    abbreviations[userTimezone] || moment().tz(userTimezone).zoneAbbr();

  if (convertingTimezones === true && userTimezone !== '') {
    return `${moment(value)
      .tz(userTimezone)
      .format('YYYY-MM-DD hh:mmA')} ${abbr}`;
  }
  return `${moment(value).utc().format('YYYY-MM-DD HH:mm:ss')} UTC`;
}

export function formatTimezone2Lines(value, timezone = '') {
  const convertingTimezones = JSON.parse(
    window.localStorage.getItem('convertingTimezones')
  );
  const userTimezone =
    window.localStorage.getItem('tempTimezone') ||
    (timezone !== '' ? timezone : window.localStorage.getItem('userTimezone'));
  const abbr =
    abbreviations[userTimezone] || moment().tz(userTimezone).zoneAbbr();

  if (convertingTimezones === true && userTimezone !== '') {
    return {
      date: moment(value).tz(userTimezone).format('YYYY-MM-DD'),
      time: moment(value).tz(userTimezone).format('hh:mmA'),
      abbr: abbr,
    };
  }

  return {
    date: moment(value).tz(userTimezone).format('YYYY-MM-DD'),
    time: moment(value).tz(userTimezone).format('HH:mm:ss'),
    abbr: 'UTC',
  };
}

// Convert string from csv file to array of objects
export function arrToObject(csvStr) {
  let data = PapaParse.parse(csvStr);
  let columns = _.first(data.data);
  let lines = _.rest(data.data);
  let returnArr = [];
  lines.forEach((line) => {
    let nextRec = {};
    for (let i = 0; i < line.length; i++) {
      nextRec[columns[i]] = line[i];
    }
    returnArr.push(nextRec);
  });
  return returnArr;
}

// Detect Windows OS
function detectWindowsOS(osStr) {
  switch (osStr) {
    case 'Windows 95':
    case 'Win95':
    case 'Windows_95':
      return 'Windows 95';

    case 'Windows 98':
    case 'Win98':
      return 'Windows 98';

    case 'Windows NT 5.0':
    case 'Windows 2000':
      return 'Windows 2000';

    case 'Windows NT 5.1':
    case 'Windows XP':
      return 'Windows XP';

    case 'Windows NT 5.2':
      return 'Windows Server 2003';

    case 'Windows NT 6.0':
      return 'Windows Vista';

    case 'Windows NT 6.1':
      return 'Windows 7';

    case 'Windows NT 6.2':
    case 'Windows NT 6.3':
    case 'WOW64':
      return 'Windows 8';

    case 'Windows 10.0':
    case 'Windows NT 10.0':
      return 'Windows 10';

    case 'Windows NT 4.0':
    case 'WinNT4.0':
    case 'WinNT':
    case 'Windows NT':
      return 'Windows NT 4.0';

    case 'Windows ME':
      return 'Windows ME';

    case 'OpenBSD':
      return 'Open BSD';

    case 'SunOS':
      return 'Sun OS';

    case 'Linux':
    case 'X11':
      return 'Linux';

    default:
      return osStr;
  }
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
  let ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  let msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return {
      name: 'Internet Explorer',
      version: parseFloat(ua.substring(msie + 5)),
    };
  }

  let trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return {
      name: 'Internet Explorer',
      version: parseFloat(ua.substring(rv + 3)),
    };
  }

  let edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return {
      name: 'Edge',
      version: parseFloat(ua.substring(edge + 5)),
    };
  }

  // other browser
  return false;
}

export function systemInfo() {
  let objappVersion = navigator.appVersion;
  let objAgent = navigator.userAgent;
  let objbrowserName = navigator.appName;
  let objfullVersion = '' + parseFloat(objappVersion);
  let objBrMajorVersion = parseInt(objappVersion, 10);
  let objOffsetName, objOffsetVersion, ix;
  let hostOSStart = objAgent.indexOf('(') + 1;
  let hostOSEnd = objAgent.indexOf(')');
  let tmpOSStr = objAgent.substring(hostOSStart, hostOSEnd);

  let objHostOS;
  if (tmpOSStr.indexOf('Mac OS') > 0) {
    objHostOS = tmpOSStr
      .substring(tmpOSStr.indexOf(';') + 1)
      .replace(/_/g, '.');
  } else {
    objHostOS = detectWindowsOS(tmpOSStr.substring(0, tmpOSStr.indexOf(';')));
  }

  let browser = detectIE();

  if (browser) {
    objbrowserName = browser.name;
    objfullVersion = browser.version;
  } else {
    // In Chrome
    if ((objOffsetVersion = objAgent.indexOf('Chrome')) !== -1) {
      objbrowserName = 'Chrome';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
    }
    // In Firefox
    else if ((objOffsetVersion = objAgent.indexOf('Firefox')) !== -1) {
      objbrowserName = 'Firefox';
      objfullVersion = objAgent.substring(objOffsetVersion + 8);
      if (tmpOSStr.indexOf('Mac OS') > 0) {
        objHostOS = navigator.oscpu;
      } else {
        objHostOS = detectWindowsOS(
          navigator.oscpu.substring(0, navigator.oscpu.indexOf(';'))
        );
      }
    }
    // In Safari
    else if ((objOffsetVersion = objAgent.indexOf('Safari')) !== -1) {
      objbrowserName = 'Safari';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf('Version')) !== -1)
        objfullVersion = objAgent.substring(objOffsetVersion + 8);
    }
    // For other browser "name/version" is at the end of userAgent
    else if (
      (objOffsetName = objAgent.lastIndexOf(' ') + 1) <
      (objOffsetVersion = objAgent.lastIndexOf('/'))
    ) {
      objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion);
      objfullVersion = objAgent.substring(objOffsetVersion + 1);
      if (objbrowserName.toLowerCase() === objbrowserName.toUpperCase()) {
        objbrowserName = navigator.appName;
      }
    }
    // trimming the fullVersion string at semicolon/space if present
    if ((ix = objfullVersion.indexOf(';')) !== -1)
      objfullVersion = objfullVersion.substring(0, ix);
    if ((ix = objfullVersion.indexOf(' ')) !== -1)
      objfullVersion = objfullVersion.substring(0, ix);

    objBrMajorVersion = parseInt('' + objfullVersion, 10);
    if (isNaN(objBrMajorVersion)) {
      objfullVersion = '' + parseFloat(navigator.appVersion);
    }
  }

  return {
    hostOS: objHostOS,
    browserName: objbrowserName,
    browserVersion: objfullVersion,
  };
}

export function generateFilterRegex(filterStr) {
  const filterRegexStr = escapeRegExp(filterStr.trim());
  const filterArr = filterRegexStr.split(' ').filter((f) => f !== '');
  const filter = filterArr.reduce((f1, f2) => f1 + '|' + f2);
  const regexStr = new RegExp('(?:' + filter + ')', 'i');
  return regexStr;
}

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function formatDateTime(time, dateOptions) {
  let date = new Date(time);
  let options = { hour12: false };
  if (dateOptions?.utc) {
    options.timeZone = 'UTC';
    options.timeZoneName = 'short';
  }
  if (dateOptions?.windowWidth && dateOptions?.windowWidth < 600) {
    if (!dateOptions?.utc) {
      options.dateStyle = 'short';
      options.timeStyle = 'short';
    }
    return date.toLocaleString(TextOnly('hl'), options);
  } else if (dateOptions?.windowWidth && dateOptions?.windowWidth < 1400) {
    if (!dateOptions?.utc) {
      options.dateStyle = 'medium';
      options.timeStyle = 'medium';
    }
    return date.toLocaleString(TextOnly('hl'), options);
  } else {
    if (!dateOptions?.utc) {
      options.dateStyle = 'long';
      options.timeStyle = 'long';
    }
    return date.toLocaleString(TextOnly('hl'), options);
  }
}

export function formatDateTime2Lines(time, dateOptions) {
  let date = new Date(time);
  let options = { hour12: false };
  if (dateOptions?.utc) {
    options.timeZone = 'UTC';
  }
  if (dateOptions?.windowWidth && dateOptions?.windowWidth < 600) {
    if (!dateOptions?.utc) {
      options.dateStyle = 'short';
      options.timeStyle = 'short';
    }
  } else if (dateOptions?.windowWidth && dateOptions?.windowWidth < 1400) {
    if (!dateOptions?.utc) {
      options.dateStyle = 'medium';
      options.timeStyle = 'medium';
    }
  }
  const dateStr = date.toLocaleDateString(TextOnly('hl'), options);
  options.timeZoneName = 'short';
  const timeStr = date.toLocaleTimeString(TextOnly('hl'), options);
  return { date: dateStr, time: timeStr };
}

export function sortUsers(a, b) {
  let aName = a.firstName + a.lastName;
  let bName = b.firstName + b.lastName;

  return aName.toLowerCase() < bName.toLowerCase() ? -1 : 1;
}

export function sortTools(a, b) {
  let aName = a.toolManufacturer + a.toolModel + a.toolSerial;
  let bName = b.toolManufacturer + b.toolModel + b.toolSerial;

  return aName.toLowerCase() < bName.toLowerCase() ? -1 : 1;
}

export function getDev() {
  const isDev = process.env.REACT_APP_IS_DEVELOPER;
  const isDevTodo = isDev === 'true' ? 'aa-todo' : null;
  return {
    isDev,
    isDevTodo,
  };
}

export const getTransactionTypeString = (type) => {
  switch (type) {
    case 'upgradeProration':
      return 'User Capacity Upgrade (prorated)';
    case 'upgradePlusProration':
      return 'Shop Upgrade to AutoAuth PLUS (prorated)';
    case 'subscriptionRenew':
      return 'Yearly Subscription Renewal';
    case 'initialSubscriptionCharge':
      return 'Initial Yearly Subscription Charge';
    case 'paymentRefund':
      return 'Payment Refund';
    case 'upgradeOEMRegionProration':
      return 'Manufacturers/Regions Upgrade (prorated)';
    case 'disputedPaymentRepay':
      return 'Disputed Charge Repayment';
    case 'upgradeTechCertProration':
      return 'Tech Certification Capacity Upgrade (prorated)';
    default:
      return type;
  }
};

export const checkFilter = (queryStr, strToTest) => {
  const strToTestLower = strToTest.toLowerCase();

  if (queryStr.includes('||') ||
    queryStr.includes('&&')) {
    return processAndOr(queryStr, strToTestLower);
  } else {
    return singleCompare(queryStr, strToTestLower);
  }
}

const processAndOr = (queryStr, strToTest) => {
  if (queryStr.includes('||')) {
    const words = queryStr.split('||');
    return words.find((word) => {
      return singleCompare(word, strToTest);
    });
  } else if (queryStr.includes('&&')) {
    const words = queryStr.split('&&');
    return words.every((word) => {
      return singleCompare(word, strToTest);
    });
  }
}

const singleCompare = (queryStr, strToTest) => {
  if (queryStr.includes('NOT')) {
    const justQueryStr = queryStr.replace('NOT', '').trim().toLowerCase();
    return !strToTest.includes(justQueryStr);
  } else {
    const lowerWord = queryStr.trim().toLowerCase();
    return strToTest.includes(lowerWord);
  }
}

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);

export const updateLogData = async (logData, oemInfo) => {
  let oemNameInfo = oemInfo ?? {};
  if (!oemNameInfo) {
    const oemRegionList = await getOEMRegionList();
    oemRegionList.forEach((oem) => {
      oemNameInfo[oem.oemID] = oem.oemName;
    });
  }

  logData.forEach((entry) => {
    // Correct the action message if they reinstated/added OEMs
    if (entry.actionMessage === "User reinstated shop's OEM/Regions marked for removal at next renewal date.") {
      if (entry.addedOems?.length && entry.reinstatedOems?.length) {
        entry.actionMessage = "User added new OEMs to shop and reinstated OEMs marked for removal at next renewal date";
      } else if (entry.addedOems?.length) {
        entry.actionMessage = "User added new OEMs to shop";
      } else if (entry.reinstatedOems?.length) {
        entry.actionMessage = "User reinstated OEMs marked for removal at next renewal date";
      } else {
        entry.actionMessage = "User added new OEMs to shop and/or reinstated OEMs marked for removal at next renewal date";
      }
    }
    // Then convert all OEM IDs to names
    if (entry.addedOems?.length || entry.reinstatedOems?.length) {
      if (entry.addedOems?.length) {
        const addedNames = entry.addedOems.map((oemID) => oemNameInfo[oemID]);
        entry.addedOemNames = addedNames;
      }
      if (entry.reinstatedOems?.length) {
        const reinstatedNames = entry.reinstatedOems.map((oemID) => oemNameInfo[oemID]);
        entry.reinstatedOemNames = reinstatedNames;
      }
    } else if (entry.removedOemIDs?.length || entry.oemIDs?.length) {
      // If OEMs were removed, we don't need the 'oemIDs' field
      const field = entry.removedOemIDs?.length ? 'removedOemIDs' : 'oemIDs';
      const oemIDs = entry[field];
      const oemNames = oemIDs.map((oemID) => oemNameInfo[oemID]);
      entry[`${field.slice(0, -3)}Names`] = oemNames;
    }
  });
}


/******************************************************************************
* Function:    buildReceiptPDF
* Programmers: Sami Zuno
* Date:        03/07/2024
*
* Purpose:     This function holds the same code that builds a receipt for
*              shop transactions in auto-auth-client.
*
*      ** ANY CHANGES MADE HERE MUST ALSO BE CHANGED IN auto-auth-client! **
******************************************************************************/

export const buildReceiptPdf = (receipt, shop, user, oemRegionsList) => {
  const doc = new jsPDF();
  let fontName = 'helvetica';

  const { amount,
          time,
          id,
          type,
          shopType,
          userAddons,
          numUsersAdded,
          oemIDs,
          addedOems,
          baseSubPrice,
          baseOemPrice,
          baseUserPrice,
          oemUserUpcharge,
          techCertAddons,
          numTechCertsAdded,
          techCertPrice } = receipt;
  const cardType =
    receipt.cardType || (shop ? shop.shopPaymentInfo.cardType : '');
  const last4 = receipt.last4 || (shop ? shop.shopPaymentInfo.last4 : '');
  const company = shop?.shopName ?? '';
  const owner = user ? `${user.firstName} ${user.lastName}` : '';
  const username = user ? user.userName : '';
  const email = user ? user.email : '';

  const shopAddressInfo = {
    streetAddress1: shop.addressInfo.streetAddress1,
    streetAddress2: shop.addressInfo.streetAddress2,
    city: shop.addressInfo.city,
    state: shop.addressInfo.state,
    zip: shop.addressInfo.zip,
    country: shop.addressInfo.country
  };

  const shouldIncludeBillingAddress = Object.values(shopAddressInfo).some(element => element !== '' && element !== undefined);

  doc.setFontSize(8);
  doc.text('AutoAuth.com - Order ID', 6, 8, { align: 'left' });

  doc.text(`${new Date().toLocaleString()}`, 200, 8, { align: 'right' });
  doc.addImage(
    '/img/autoauth_no_tag.png',
    'PNG',
    10,
    16,
    50,
    50 * (196 / 2207)
  );



  const finalDetailsForOrderId = TextOnly('finalDetailsForOrderId', {id: id});
  const printPageForYourRecords = TextOnly('printPageForYourRecords');
  const orderPlaced = TextOnly('orderPlaced');
  const autoAuthOrderNumber = TextOnly('autoAuthOrderNumber');
  const orderTotal = TextOnly('orderTotal');
  const transactionInfo = TextOnly('transactionInfo');
  const desc = TextOnly('description');
  const rate = TextOnly('rate');
  const amt = TextOnly('amount');
  const subDesc = `AutoAuth ${shopType} ${TextOnly('subscription')}`;
  const subPrice = `$${Number(baseSubPrice).toFixed(2)}`;
  const oemDesc = TextOnly('addedOem');
  const oemPrice = `$${(baseOemPrice + (oemUserUpcharge * userAddons)).toFixed(2)}`;
  const oemTotal = `$${((baseOemPrice + (oemUserUpcharge * userAddons)) * addedOems?.length).toFixed(2)}`;
  const userDesc = TextOnly('addedUser');
  const userPrice = `$${(baseUserPrice + ((oemIDs?.length - 1) * oemUserUpcharge)).toFixed(2)}`;
  const userTotal = type === 'upgradeProration'
    ? `$${((baseUserPrice + ((oemIDs?.length - 1) * oemUserUpcharge)) * numUsersAdded).toFixed(2)}`
    : `$${((baseUserPrice + ((oemIDs?.length - 1) * oemUserUpcharge)) * userAddons).toFixed(2)}`;
  const oemUpchargeExplain = TextOnly('oemUpchargeExplain',
    { base: baseOemPrice, upcharge: oemUserUpcharge });
  const userUpchargeExplain = TextOnly('userUpchargeExplain',
    { base: baseUserPrice, upcharge: oemUserUpcharge });
  const renewalUpchargeExplain = TextOnly('renewalUpchargeExplain',
    { base: baseUserPrice, upcharge: oemUserUpcharge });
  const certDesc = TextOnly('addedTechCert');
  const certPrice = `$${techCertPrice?.toFixed(2)}`;
  const certTotal = `$${(techCertPrice * numTechCertsAdded)?.toFixed(2)}`;
  const customerInfo = TextOnly('customerInfo');
  const companyLabel = TextOnly('company');
  const ownerLabel = TextOnly('owner');
  const usernameLabel = TextOnly('username');
  const emailLabel = TextOnly('email');
  const paymentInfo = TextOnly('paymentInfo');
  const paymentMethod = TextOnly('paymentMethod');
  const lastDigits = TextOnly('lastDigits');
  const billingAddress = TextOnly('billingAddress');
  const subtotal = TextOnly('subtotal');
  const taxToCollect = TextOnly('taxToCollect');
  const grandTotal = TextOnly('grandTotal');
  const orderID = id || 'N/A';
  let refundAmount;
  let linePush = 0;

  const itemQty = (qty) => `${qty} x`;

  doc.setFontSize(14);
  doc.setFont(fontName, 'bold');
  doc.setTextColor('#c2000c');
  doc.text(finalDetailsForOrderId, 100, 30, { align: 'center' });

  doc.setFontSize(10);
  doc.setFont(fontName, 'italic');
  doc.setTextColor('black');
  doc.text(printPageForYourRecords, 100, 35, { align: 'center' });

  doc.setFontSize(12);
  doc.setFont(fontName, 'bold');
  doc.text(`${orderPlaced}: `, 20, 45, { align: 'left' });
  doc.text(`${autoAuthOrderNumber}: `, 20, 51, { align: 'left' });
  doc.text(`${orderTotal}: `, 20, 57, { align: 'left' });

  doc.setFont(fontName, 'normal');
  doc.text(`${new Date(time).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`, 190, 45, { align: 'right' });
  doc.text(`${orderID}`, 190, 51, { align: 'right' });

  doc.setFont(fontName, 'bold');
  if (type === 'paymentRefund') {
    refundAmount = amount[0] === '-'
      ? amount.substring(1)
      : amount;
    doc.text(`-$${refundAmount}`, 190, 57, { align: 'right' });
  } else {
    doc.text(`$${amount}`, 190, 57, { align: 'right' });
  }

  doc.setFontSize(14);
  doc.text(transactionInfo, 100, 72, { align: 'center' });

  doc.setFontSize(12);
  doc.text(desc, 20, 86, { align: 'left' });
  if ((type !== 'upgradePlusProration'
      && receipt.hasOwnProperty('userAddons')
      && receipt.hasOwnProperty('oemIDs')
      && oemUserUpcharge)
      || type === 'upgradeTechCertProration') {
    doc.text(rate, 160, 86, { align: 'right' });
  }
  doc.text(amt, 190, 86, { align: 'right' });

  if ((type === 'subscriptionRenew' || type === 'initialSubscriptionCharge')
      && receipt.hasOwnProperty('userAddons')
      && receipt.hasOwnProperty('oemIDs')
      && baseSubPrice
      && baseOemPrice
      && baseUserPrice
      && oemUserUpcharge) {
    doc.setFont(fontName, 'normal');
    doc.text(itemQty(1), 30, 93 + linePush, { align: 'right' });
    doc.setFont(fontName, 'italic');
    doc.text(subDesc, 34, 93 + linePush, { align: 'left' });
    doc.setFont(fontName, 'normal');
    doc.text(subPrice, 160, 93 + linePush, { align: 'right' });
    doc.text(subPrice, 190, 93 + linePush, { align: 'right' });
    linePush += 6;

    if (techCertAddons) {
      doc.text(itemQty(techCertAddons), 30, 93 + linePush, { align: 'right' });
      doc.setFont(fontName, 'italic');
      doc.text(certDesc, 34, 93 + linePush, { align: 'left' });
      doc.setFont(fontName, 'normal');
      doc.text(certPrice, 160, 93 + linePush, { align: 'right' });
      doc.text(`$${(techCertPrice * techCertAddons).toFixed(2)}`, 190, 93 + linePush, { align: 'right' });
      linePush += 6;
    }

    if (oemIDs.length > 1) {
      doc.text(itemQty(oemIDs.length - 1), 30, 93 + linePush, { align: 'right' });
      doc.setFont(fontName, 'italic');
      doc.text(oemDesc, 34, 93 + linePush, { align: 'left' });
      doc.setFont(fontName, 'normal');
      doc.text(`$${baseOemPrice.toFixed(2)}`, 160, 93 + linePush, { align: 'right' });
      doc.text(`$${(baseOemPrice * (oemIDs.length - 1)).toFixed(2)}`, 190, 93 + linePush, { align: 'right' });
      linePush += 6;
    }

    if (userAddons) {
      doc.text(itemQty(userAddons), 30, 93 + linePush, { align: 'right' });
      doc.setFont(fontName, 'italic');
      doc.text(userDesc, 34, 93 + linePush, { align: 'left' });
      doc.setFont(fontName, 'normal');
      doc.text(userPrice, 160, 93 + linePush, { align: 'right' });
      doc.text(userTotal, 190, 93 + linePush, { align: 'right' });
      linePush += 6;

      if (oemIDs.length > 1) {
        doc.setFontSize(9);
        doc.setTextColor('#c2000c');
        doc.text(renewalUpchargeExplain, 20, 93 + linePush, { align: 'left' });
        doc.setFontSize(12);
        doc.setTextColor('black');
        linePush += 6;
      }
    }

    doc.text('------', 20, 93 + linePush, { align: 'left' });
    doc.text('------', 190, 93 + linePush, { align: 'right' });
    linePush += 6;
  }

  if (type === 'upgradeProration'
      && numUsersAdded
      && baseUserPrice
      && oemUserUpcharge) {
    doc.setFont(fontName, 'normal');
    doc.text(itemQty(numUsersAdded), 30, 93 + linePush, { align: 'right' });
    doc.setFont(fontName, 'italic');
    doc.text(userDesc, 34, 93 + linePush, { align: 'left' });
    doc.setFont(fontName, 'normal');
    doc.text(userPrice, 160, 93 + linePush, { align: 'right' });
    doc.text(userTotal, 190, 93 + linePush, { align: 'right' });
    linePush += 6;

    if (oemIDs.length > 1) {
    doc.setFontSize(9);
    doc.setTextColor('#c2000c');
    doc.text(userUpchargeExplain, 20, 93 + linePush, { align: 'left' });
    doc.setFontSize(12);
    doc.setTextColor('black');
    linePush += 6;
  }

    doc.text('------', 20, 93 + linePush, { align: 'left' });
    doc.text('------', 190, 93 + linePush, { align: 'right' });
    linePush += 6;
  }

  if (type === 'upgradeOEMRegionProration'
      && addedOems
      && baseOemPrice
      && oemUserUpcharge) {
    const oemNames = (addedOems.map((oemID) => {
      const addedOem = oemRegionsList.find((oem) => {
        return oemID === oem['oemID'];
      });
      return addedOem['oemName'];
    })).join(', ');

    doc.setFont(fontName, 'normal');
    doc.text(itemQty(addedOems.length), 30, 93 + linePush, { align: 'right' });
    doc.setFont(fontName, 'italic');
    doc.text(oemDesc, 34, 93 + linePush, { align: 'left' });
    doc.setFont(fontName, 'normal');
    doc.text(oemPrice, 160, 93 + linePush, { align: 'right' });
    doc.text(oemTotal, 190, 93 + linePush, { align: 'right' });
    linePush += 6;

    doc.setFontSize(10);
    doc.text(oemNames, 34, 93 + linePush, { align: 'left' });
    doc.setFontSize(12);
    linePush += 6;

    if (userAddons) {
      doc.setFontSize(9);
      doc.setTextColor('#c2000c');
      doc.text(oemUpchargeExplain, 20, 93 + linePush, { align: 'left' });
      doc.setFontSize(12);
      doc.setTextColor('black');
      linePush += 6;
    }

    doc.text('------', 20, 93 + linePush, { align: 'left' });
    doc.text('------', 190, 93 + linePush, { align: 'right' });
    linePush += 6;
  }

  if (type === 'upgradeTechCertProration'
      && numTechCertsAdded
      && techCertPrice) {
    doc.setFont(fontName, 'normal');
    doc.text(itemQty(numTechCertsAdded), 30, 93 + linePush, { align: 'right' });
    doc.setFont(fontName, 'italic');
    doc.text(certDesc, 34, 93 + linePush, { align: 'left' });
    doc.setFont(fontName, 'normal');
    doc.text(certPrice, 160, 93 + linePush, { align: 'right' });
    doc.text(certTotal, 190, 93 + linePush, { align: 'right' });
    linePush += 6;

    doc.text('------', 20, 93 + linePush, { align: 'left' });
    doc.text('------', 190, 93 + linePush, { align: 'right' });
    linePush += 6;
  }

  doc.setFont(fontName, 'italic');
  doc.text(`${getTransactionTypeString(type)}`, 20, 93 + linePush, { align: 'left' });

  doc.setFont(fontName, 'normal');

  if (type === 'paymentRefund') {
    doc.text(`-$${refundAmount}`, 190, 93 + linePush, { align: 'right' });
    linePush -= 18;

  } else {
    doc.text(`${subtotal}:`, 165, 93 + linePush, { align: 'right' });
    doc.text(`$${amount}`, 190, 93 + linePush, { align: 'right' });
    doc.text(`${taxToCollect}:`, 165, 99 + linePush, { align: 'right' });
    doc.text(`$0.00`, 190, 99 + linePush, { align: 'right' });
    doc.text('------', 190, 105 + linePush, { align: 'right' });

    doc.setFont(fontName, 'bold');
    doc.text(`${grandTotal}:`, 165, 111 + linePush, { align: 'right' });
    doc.text(`$${amount}`, 190, 111 + linePush, { align: 'right' });
  }

  doc.setTextColor('black');
  doc.setFontSize(14);
  doc.setFont(fontName, 'bold');

  doc.text(customerInfo, 100, 126 + linePush, { align: 'center' });

  doc.setFontSize(12);
  doc.text(`${companyLabel}: `, 20, 141 + linePush, { align: 'left' });
  doc.text(`${ownerLabel}: `, 20, 147 + linePush, { align: 'left' });
  doc.text(`${usernameLabel}: `, 20, 153 + linePush, { align: 'left' });
  doc.text(`${emailLabel}: `, 20, 159 + linePush, { align: 'left' });

  doc.setFont(fontName, 'normal');

  doc.text(`${company}`, 190, 141 + linePush, { align: 'right' });
  doc.text(`${owner}`, 190, 147 + linePush, { align: 'right' });
  doc.text(`${username}`, 190, 153 + linePush, { align: 'right' });
  doc.text(`${email}`, 190, 159 + linePush, { align: 'right' });

  doc.setFontSize(14);
  doc.setFont(fontName, 'bold');
  doc.text(paymentInfo, 100, 174 + linePush, { align: 'center' });

  doc.setFontSize(12);
  doc.text(paymentMethod, 20, 184 + linePush, { align: 'left' });
  doc.setFont(fontName, 'normal');
  doc.text(`${cardType} | ${lastDigits}: ${last4}`, 20, 190 + linePush, { align: 'left' });

  if (shouldIncludeBillingAddress) {
    doc.setFont(fontName, 'bold');
    doc.text(billingAddress, 20, 202 + linePush, { align: 'left' });
    doc.setFont(fontName, 'normal');
    doc.text(`${owner}`, 20, 208 + linePush, { align: 'left' });

    const {streetAddress1, streetAddress2, city, zip, state, country } = shopAddressInfo;

    let streetAddress = '';
    if (streetAddress1) {
      streetAddress += streetAddress1.toUpperCase();
    }

    if (streetAddress2) {
      streetAddress += ` ${streetAddress2}`;
    }

    if (streetAddress !== '') {
      doc.text(`${streetAddress}`, 20, 214 + linePush, { align: 'left'});
    }

    let cityStateZip = '';
    if (city) {
      cityStateZip += city.toUpperCase();
    }

    if (state) {
      cityStateZip += `, ${state.toUpperCase()}`
    }

    if (zip) {
      cityStateZip += ` ${zip}`
    }

    if (cityStateZip !== '') {
      doc.text(`${cityStateZip}`, 20, 220 + linePush, { align: 'left'});
    }

    if (country) {
      doc.text(`${country}`, 20, 226 + linePush, { align: 'left'});
    }
  }

  doc.setFont(fontName, 'normal');
  doc.setFontSize(9);
  doc.setTextColor('black');
  doc.text(`© 2018-2024, AutoAuth`, 200, 290, { align: 'right'});

  // Convert to base64
  const file = doc.output('dataurlstring');

  return { doc: doc, data: file };
};

export function scalePdf(width) {
  if (width > 1600 || width < 753) {
    return (width / 660) < 1.35 ? (width / 660) : 1.35
  }

  return (width / 1200);
}