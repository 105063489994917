import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'moment-timezone';

import AlertModal from '../components/AlertModal';
import Chip from '../components/Chip';
import { Header } from '../components/Header';
import LoaderButton from '../components/LoaderButton';
import { Loading } from '../components/Loading';
import Switch from '../components/Switch';
import { Text, TextOnly } from '../components/Text';
import HelpTooltip from '../components/Tooltips/HelpTooltip';

import { updateSupportUser } from '../libs/db-lib';
import { timezones } from '../libs/timezones';

import { fieldChangeCase, timezoneValidate } from '../libs/utils';
import { passwordValidate, nameValidate, isValidEmail } from '../libs/utils';

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: this.props.user.firstName || '',
      lastName: this.props.user.lastName || '',
      originalEmail: this.props.user.email || '',
      email: this.props.user.email || '',
      emailVerified: this.props.user.emailVerified || false,
      userName: this.props.user.userName || '',
      userTimezone: '',
      emailChanged: false,
      alertMessage: '',
      alertTitle: '',
      showModal: false,
      oldPassword: '',
      newPassword: '',
      verifyPassword: '',
      emailVerificationCodeSent: false,
      confirmationCode: '',
      user: props.user,
      isLoading: false,
      isChangePasswordLoading: false,
    };
  }

  async componentDidMount() {
    this.setState({
      emailVerified: this.props.user.emailVerified || false,
      email: this.props.user.email || '',
      userTimezone: this.props.user.userTimezone,
    });

    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    let namesValid =
      nameValidate(this.state.firstName) && nameValidate(this.state.lastName);
    let emailValid = isValidEmail(this.state.email);
    let timezoneValid = timezoneValidate(this.state.userTimezone);

    return (
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.email.length > 0 &&
      namesValid &&
      emailValid &&
      timezoneValid &&
      (this.state.user.firstName !== this.state.firstName ||
        this.state.user.lastName !== this.state.lastName ||
        this.state.user.email !== this.state.email.toLowerCase() ||
        this.state.user.userTimezone !== this.state.userTimezone)
    );
  }

  validatePasswordForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.newPassword.length > 0 &&
      passwordValidate(this.state.newPassword) &&
      this.state.newPassword === this.state.verifyPassword
    );
  }

  handleChange = (actionType, event) => {
    event = actionType;
    let newValue = event.target.value;
    if (event.target.id === 'email') {
      fieldChangeCase(this, event.target, 'lower', false);
      newValue = newValue.toLowerCase();
      if (newValue !== this.state.originalEmail) {
        this.setState({ emailChanged: true });
      } else {
        this.setState({ emailChanged: false });
      }
    } else if (event.target.id === 'confirmationCode') {
      const reg = new RegExp(/^(\s{0}|\d+)$/);
      if (
        event.target.value.length < 11 &&
        reg.test(event.target.value) === true
      ) {
        this.setState({
          confirmationCode: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleValidateEmail = () => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        this.setState({ emailVerificationCodeSent: true });
      })
      .catch((e) => {
        this.setState({
          alertTitle: TextOnly('error'),
          alertMessage: TextOnly('errorSendingVerifyCode') + ': ' + e.message,
          showModal: true,
          isLoading: false,
        });
      });
  };

  handleSubmitConfirmationCode = () => {
    Auth.verifyCurrentUserAttributeSubmit('email', this.state.confirmationCode)
      .then(() => {
        let newUser = this.state.user;
        newUser.emailVerified = true;
        this.setState({
          emailVerificationCodeSent: false,
          emailVerified: true,
          user: newUser,
          alertTitle: TextOnly('success'),
          alertMessage: TextOnly('emailAddressVerified'),
          showModal: true,
          isLoading: false,
        });
        this.props.fetchUser();
      })
      .catch((e) => {
        this.setState({
          alertTitle: TextOnly('error'),
          alertMessage: TextOnly('invalidVerifyCode'),
          showModal: true,
          isLoading: false,
        });
      });
  };

  handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    } else {
      return;
    }

    this.setState({ isLoading: true, email: this.state.email });

    let result = await updateSupportUser(
      this.state.firstName,
      this.state.lastName,
      this.state.email,
      this.state.userTimezone
    );
    if (Object.hasOwn(result, 'error')) {
      if ([401, 403].indexOf(result.error.status) === -1) {
        this.setState({
          alertMessage: result.error,
          showModal: true,
          isLoading: false,
          email: this.state.originalEmail,
          emailChanged: false,
        });
      }
      return;
    } else {
      let newUser = this.state.user;
      newUser.firstName = this.state.firstName;
      newUser.lastName = this.state.lastName;
      newUser.email = this.state.email;
      newUser.userTimezone = this.state.userTimezone;
      toast.success(TextOnly('profileUpdated'), { containerId: 'standard' });

      if (this.state.emailChanged) {
        this.setState({
          emailVerificationCodeSent: true,
          emailVerified: false,
          emailChanged: false,
          originalEmail: newUser.email,
          alertTitle: TextOnly('verify'),
          alertMessage: TextOnly('toCompleteEmailAddressChange'),
          showModal: true,
        });
      }
      this.props.fetchUser();
      this.setState({ user: newUser, isLoading: false });
    }
  };

  handlePasswordSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isChangePasswordLoading: true });

    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        this.state.oldPassword,
        this.state.newPassword
      );
      this.setState({
        isChangePasswordLoading: false,
        oldPassword: '',
        newPassword: '',
        verifyPassword: '',
      });
      toast.success(TextOnly('passwordUpdated'), { containerId: 'standard' });
    } catch (e) {
      let msg = TextOnly('oldPasswordNotCorrect');
      if (e.message !== 'Incorrect username or password.') {
        msg = e.message;
      }
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: msg,
        showModal: true,
        isChangePasswordLoading: false,
      });
    }
  };

  render() {
    if (!this.props.user || !this.state.userName) return <Loading />;

    let tz = timezones.find((tz) => {
      return tz.tzCode === this.state.userTimezone;
    });

    let userTimezoneString = tz ? `${tz.label} ${tz.sub}` : '';

    return (
      <>
        <Header title={TextOnly('myProfile')} />
        <div className="l-view-layout--user-profile">
          <div className="user-profile-form-column">
            <form onSubmit={this.handleSubmit}>
              <div>
                <h2>
                  <Text tid="userDetails" />
                </h2>
                <label className="u-margin-bottom-large">
                  <Text tid="username" />: {this.state.userName}
                </label>
              </div>
              <div className="c-field">
                <label htmlFor="firstName" className="c-field__label">
                  <Text tid="firstName" />:
                </label>
                <input
                  id="firstName"
                  type="text"
                  maxLength="50"
                  className={`c-input ${
                    !nameValidate(this.state.firstName) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('firstName')}
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
                {!nameValidate(this.state.firstName) ? (
                  <div className="c-field__error">
                    <Text tid="firstNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="lastName" className="c-field__label">
                  <Text tid="lastName" />:
                </label>
                <input
                  id="lastName"
                  type="text"
                  maxLength="50"
                  className={`c-input ${
                    !nameValidate(this.state.lastName) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('lastName')}
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
                {!nameValidate(this.state.lastName) ? (
                  <div className="c-field__error">
                    <Text tid="lastNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="email" className="c-field__label">
                  <Text tid="emailAddress" />:
                </label>
                <input
                  id="email"
                  type="text"
                  maxLength="50"
                  className={`c-input ${
                    !isValidEmail(this.state.email) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('email')}
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                {!isValidEmail(this.state.email) ? (
                  <div className="c-field__error">
                    <Text tid="emailHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label
                  htmlFor="timezone-picker"
                  className="c-field__label u-margin-bottom-small"
                >
                  Timezone (Automatically detected):
                </label>
                <Chip
                  fontWeight="bold"
                  backgroundColor="white"
                  color="#0088cc"
                  border="1px solid #0088cc"
                >
                  {userTimezoneString}
                </Chip>
              </div>
              {this.state.userTimezone && (
                <div className="u-margin-top u-margin-bottom l-flex">
                  <Switch
                    isChecked={
                      JSON.parse(
                        window.localStorage.getItem('convertingTimezones')
                      ) || false
                    }
                    states={{
                      active: 'Enable Timezone Conversion',
                      inactive: 'Enable Timezone Conversion',
                    }}
                    handleCheck={(e) => {
                      window.localStorage.setItem('convertingTimezones', e);
                      this.props.showTimezoneSelect(e);
                    }}
                  />
                  <HelpTooltip
                    onClick={() =>
                      this.props.handleShowAlert(
                        'Info',
                        <div>
                          Enabling timezone conversion will convert all dates on
                          the portal to your detected timezone{' '}
                          <strong>{`(${userTimezoneString})`}</strong>. You will
                          also be able to swap timezones temporarily via the
                          sidebar menu.
                        </div>,
                        false
                      )
                    }
                    label={
                      <div>
                        Enabling timezone conversion will convert all dates on
                        the portal to your detected timezone{' '}
                        <strong>{`(${userTimezoneString})`}</strong>. You will
                        also be able to swap timezones temporarily via the
                        sidebar menu.
                      </div>
                    }
                  />
                </div>
              )}
              <div className="u-margin-top u-margin-bottom l-flex">
                <Switch
                  isChecked={
                    JSON.parse(
                      window.localStorage.getItem('expandAllTableRows')
                    ) || false
                  }
                  states={{
                    active: 'Expand All Table Rows',
                    inactive: 'Expand All Table Rows',
                  }}
                  handleCheck={(e) => {
                    window.localStorage.setItem('expandAllTableRows', e);
                  }}
                />
                <HelpTooltip
                  onClick={() =>
                    this.props.handleShowAlert(
                      'Info',
                      <div>
                        Forces all table rows to expand, displaying all
                        pertinent information.
                      </div>,
                      false
                    )
                  }
                  label={
                    <div>
                      Forces all table rows to expand, displaying all pertinent
                      information.
                    </div>
                  }
                />
              </div>

              <div className="u-margin-bottom">
                {this.state.emailVerified ||
                this.state.emailChanged ||
                this.state.emailVerificationCodeSent ? null : (
                  <button
                    type="button"
                    id="validate-email"
                    className="c-btn"
                    disabled={
                      this.state.emailVerificationCodeSent ||
                      this.state.emailChanged
                    }
                    onClick={this.handleValidateEmail}
                  >
                    <Text tid="validateEmail" />
                  </button>
                )}
              </div>
              {this.state.emailVerificationCodeSent &&
              !this.state.emailChanged ? (
                <>
                  <div className="c-field">
                    <label
                      className="c-field__label"
                      htmlFor="emailVerification"
                    >
                      <Text tid="emailSentMsg" />
                    </label>
                    <input
                      type="text"
                      id="confirmationCode"
                      maxLength="10"
                      value={this.state.confirmationCode}
                      placeholder={TextOnly('confirmationCode')}
                      onChange={this.handleChange}
                    />
                  </div>
                  {this.state.emailVerified ? null : (
                    <button
                      type="button"
                      id="validate-email"
                      className="c-btn u-margin-bottom"
                      disabled={
                        !this.state.emailVerificationCodeSent ||
                        this.state.confirmationCode?.length < 6
                      }
                      onClick={this.handleSubmitConfirmationCode}
                    >
                      <Text tid="submitConfirmationCode" />
                    </button>
                  )}
                </>
              ) : null}
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
            </form>
          </div>
        </div>
        <AlertModal
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size={'small'}
          handleCancel={this.handleCancel.bind(this)}
        />
      </>
    );
  }
}

export default (props) => {
  return <UserProfile {...props} />;
};
