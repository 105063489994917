import {
  SET_SHOW_VEHICLE_INFO,
  SET_VIN,
  SET_QUERIED_VIN,
  SET_VEHICLE_DETAILS,
  SET_IS_LOADING,
  SET_ENTER_PRESSED,
} from './actions';

export const initialState = {
  isLoading: false,
  showVehicleInfo: false,
  vin: '',
  queriedVin: '',
  enterPressed: false,
  vehicleDetails: {
    make: '',
    model: '',
    year: '',
    trim: '',
  },
};

export const reducer = (
  state: typeof initialState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_VIN:
      return { ...state, vin: payload };
    case SET_QUERIED_VIN:
      return { ...state, queriedVn: payload };
    case SET_VEHICLE_DETAILS:
      return { ...state, vehicleDetails: payload };
    case SET_SHOW_VEHICLE_INFO:
      return { ...state, showVehicleInfo: payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case SET_ENTER_PRESSED:
      return { ...state, enterPressed: payload };
    default:
      return state;
  }
};
