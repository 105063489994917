import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { requestToken } from '../libs/db-lib';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      isLoading: true,
      emailVerificationCodeSent: false,
    };
  }

  async componentDidMount() {
    if (this.props.code) {
      this.props.userHasAuthenticated(true);
    }
    try {
      if (!this.props.isAuthenticated) {
        const result = await requestToken(
          this.props.code || window.localStorage.getItem('code')
        );
        if (result) {
          this.setState({
            alertMessage: result.message ? result.message : result,
            showModal: true,
            isLoading: false,
          });
        } else {
          let lastVisitedPage = localStorage.getItem('lastVisitedPage');
          this.props.history.push({
            pathname: lastVisitedPage || '/dashboard',
          });
        }
      } else {
        this.props.history.push({ pathname: '/dashboard' });
      }
    } catch (e) {
      console.log('Error getting User Info: ', e);
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleValidateEmail = () => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        this.setState({ emailVerificationCodeSent: true });
      })
      .catch((e) => {
        this.setState({
          alertMessage: e.message,
          showModal: true,
          isLoading: false,
        });
      });
  };

  handleSubmitConfirmationCode = () => {
    Auth.verifyCurrentUserAttributeSubmit('email', this.state.confirmationCode)
      .then(() => {
        let newUser = this.state.user;
        newUser.emailVerified = true;
        this.setState({
          emailVerificationCodeSent: false,
          user: newUser,
          alertMessage: 'Email Address has been verified',
          showModal: true,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          alertMessage: 'Invalid Verification Code',
          showModal: true,
          isLoading: false,
        });
      });
  };

  renderLander() {
    return <div></div>;
  }

  renderNotAuthenticated() {
    return (
      <div className="home">
        <div className="echo-test">There was a problem authenticating</div>
      </div>
    );
  }

  render() {
    return <div className="Home">Authenticated</div>;
  }
}
