import React from 'react';

interface FloatingButtonProps {
  onClick: () => void;
}

const FloatingButton = ({ onClick }: FloatingButtonProps) => {
  return (
    <button onClick={onClick} className="material-icons floating-btn">
      arrow_upward
    </button>
  );
};

export default FloatingButton;
