import React, { useState, useRef, useEffect } from 'react';

interface AccordionProps {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  children: React.ReactNode;
  shopID?: string;
  collapse?: string;
  icon?: JSX.Element;
  maxHeightOverride?: string;
  accordionToggled?: () => void;
}

const Accordion = (props: AccordionProps) => {
  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0px');
  const [rotation, setRotation] = useState('accordion__icon');

  const content = useRef<HTMLDivElement>(null);

  const {
    collapse,
    shopID,
    title,
    subtitle,
    children,
    icon,
    maxHeightOverride,
    accordionToggled,
  } = props;

  useEffect(() => {
    if (collapse !== shopID) {
      setActive('');
      setRotation('accordion__icon');
      setHeight('0px');
    }
  }, [collapse, shopID]);

  function toggleAccordion() {
    if (active) {
      if (accordionToggled) accordionToggled();
    }
    setActive(active === '' ? 'accordion-active' : '');
    setRotation(
      active === 'accordion-active'
        ? 'accordion__icon'
        : 'accordion__icon rotate'
    );
    if (content.current) {
      setHeight(
        active === 'accordion-active'
          ? '0px'
          : maxHeightOverride || `${content?.current.scrollHeight}px`
      );
    }
  }

  return (
    <div className="accordion__section u-margin-bottom-large">
      <button
        className={`accordion ${active} c-card`}
        onClick={toggleAccordion}
      >
        <div>
          <p
            className="accordion__title u-font-mono l-flex-align-center u-cursor-text u-text-select"
            style={{ marginBottom: subtitle && 0 }}
          >
            {title}
            <span className="u-margin-left l-inline-flex">{icon || null}</span>
          </p>
          {subtitle && (
            <div style={{ fontSize: '12px', width: 'fit-content' }}>
              {subtitle}
            </div>
          )}
        </div>

        <i className={`fa fa-chevron-right ${rotation}`} aria-hidden="true"></i>
      </button>
      <div
        id={shopID}
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        <div className="accordion__text">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
