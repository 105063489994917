import React from 'react';
import { QueriedUserState } from '../types';
import { TextOnly } from './Text';

interface LedProps {
  status: QueriedUserState;
}

export const Led = ({ status }: LedProps) => {
  const state = getLedState(status);
  return (
    // @ts-ignore
    <span className="c-status-led" title={`${TextOnly(status) || ''}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`c-status-led__light c-status-led__light--${state}`}
      >
        <circle cx="8" cy="8" r="8" />
      </svg>
      <span className="c-status-led__label">{status}</span>
    </span>
  );
};

const LED_STATES = {
  active: 'active',
  error: 'error',
  inactive: 'inactive',
  warning: 'warning',
};

function getLedState(status: QueriedUserState) {
  switch (status) {
    case 'ACTIVE':
      return LED_STATES.active;
    case 'DEACTIVATED':
      return LED_STATES.error;
    case 'PENDING':
      return LED_STATES.warning;
  }
}
