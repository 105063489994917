export const SET_LOG = 'SET_LOG';
export const SET_GROUPED_LOG = 'SET_GROUPED_LOG';
export const SET_QUERY = 'SET_QUERY';
export const SET_QUERY_TEXT = 'SET_QUERY_TEXT';
export const SET_QUERY_TYPE = 'SET_QUERY_TYPE';
export const SET_QUERY_ERROR = 'SET_QUERY_ERROR';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_SELECTED_ACTION_CODES = 'SET_SELECTED_ACTION_CODES';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_QUERIED_USER = 'SET_QUERIED_USER';
export const SET_TABSET = 'SET_TABSET';
export const SET_SHOW_LOG = 'SET_SHOW_LOG';
export const SET_SHOW_USERID_COL = 'SET_SHOW_USERID_COL';
export const SET_SHOW_ACTION_CODE_COL = 'SET_SHOW_ACTION_CODE_COL';
export const SET_ENTER_PRESSED = 'SET_ENTER_PRESSED';

export const setLog = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_LOG, payload });
export const setGroupedLog = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_GROUPED_LOG, payload });
export const setQuery = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_QUERY, payload });
export const setQueryText = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_QUERY_TEXT, payload });
export const setQueryType = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_QUERY_TYPE, payload });
export const setQueryError = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_QUERY_ERROR, payload });
export const setStartDate = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_START_DATE, payload });
export const setEndDate = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_END_DATE, payload });
export const setSelectedActionCodes = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_SELECTED_ACTION_CODES, payload });
export const setIsLoading = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_IS_LOADING, payload });
export const setQueriedUser = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_QUERIED_USER, payload });
export const setTabset = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_TABSET, payload });
export const setShowLog = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_SHOW_LOG, payload });
export const setShowUserIDCol = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_SHOW_USERID_COL, payload });
export const setShowActionCodeCol = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_SHOW_ACTION_CODE_COL, payload });
export const setEnterPressed = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_ENTER_PRESSED, payload });
