import React, { Component } from 'react';
import { Text, TextOnly } from '../components/Text';

import countries from 'i18n-iso-countries';
import EnglishJSON from 'i18n-iso-countries/langs/en.json';
import SpanishJSON from 'i18n-iso-countries/langs/es.json';
import FrenchJSON from 'i18n-iso-countries/langs/fr.json';
import ItalianJSON from 'i18n-iso-countries/langs/it.json';
import GermanJSON from 'i18n-iso-countries/langs/de.json';
import JapaneseJSON from 'i18n-iso-countries/langs/ja.json';
import KoreanJSON from 'i18n-iso-countries/langs/ko.json';
import ChineseJSON from 'i18n-iso-countries/langs/zh.json';

countries.registerLocale(EnglishJSON);
countries.registerLocale(SpanishJSON);
countries.registerLocale(FrenchJSON);
countries.registerLocale(ItalianJSON);
countries.registerLocale(GermanJSON);
countries.registerLocale(JapaneseJSON);
countries.registerLocale(KoreanJSON);
countries.registerLocale(ChineseJSON);

interface CountryPickerProps {
  country: string;
  countryVerify?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default class CountryPicker extends Component<CountryPickerProps> {
  render() {
    let currentLang = window.localStorage.getItem('rcml-lang') || '';

    // Fallback to English if not found
    let englishCountryNames = countries.getNames('en', { select: 'official' });
    let countryNames = countries.getNames(currentLang, { select: 'official' });

    if (Object.keys(countryNames).length === 0) {
      countryNames = englishCountryNames;
    }

    let countryOptions = Object.keys(countryNames || englishCountryNames).map(
      (key) => {
        return (
          <option key={key} value={key}>
            {countryNames[key]}
          </option>
        );
      }
    );

    let isValidCountry = Object.keys(countryNames).includes(this.props.country);

    return (
      <>
        <label htmlFor="country" className="c-field__label">
          <span className="u-text-error">*</span> <Text tid="country" />
        </label>
        <div
          className={
            this.props.countryVerify && this.props.country?.length === 0
              ? 'c-select c-input__error'
              : 'c-select'
          }
        >
          <select
            id="country"
            className={
              !this.props.countryVerify || isValidCountry ? '' : 'invalid'
            }
            onChange={this.props.handleChange}
            value={this.props.country || ''}
          >
            <option value="" disabled>
              {TextOnly('country')}
            </option>
            {countryOptions}
          </select>
        </div>
        {this.props.countryVerify && this.props.country?.length === 0 ? (
          <p className="u-text-error">
            <Text tid="fieldCannotBeBlank" />
          </p>
        ) : null}
      </>
    );
  }
}
