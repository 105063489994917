// Does not include admin privileges (for now, Manage Users)
const basePermissions = [
  {
    route: '/userFunctions/getUserSummary',
    title: 'Get User Summary',
    parent: 'User Functions',
  },
  {
    route: '/userFunctions/getUserLog',
    title: 'Get User Log',
    parent: 'User Functions',
  },
  {
    route: '/userFunctions/getEmailLog',
    title: 'Get Email Log',
    parent: 'User Functions',
  },
  {
    route: '/supportui/getLoginActivityForUsername',
    title: 'Get Login Activity Log',
    parent: 'User Functions',
  },
  {
    route: '/shopFunctions/getShopSummary',
    title: 'Get Shop Summary',
    parent: 'Shop Functions',
  },
  {
    route: '/shopFunctions/getShopLog',
    title: 'Get Shop Log',
    parent: 'Shop Functions',
  },
  {
    route: '/shopFunctions/getToolLog',
    title: 'Get Tool Log',
    parent: 'Shop Functions',
  },
  {
    route: '/shopFunctions/toolLookup',
    title: 'Tool Lookup',
    parent: 'Shop Functions',
  },
  {
    route: '/miscFunctions/vinDecoder',
    title: 'VIN Decoder',
    parent: 'Misc Functions',
  },
  {
    route: '/miscFunctions/emailUsers',
    title: 'Email Users',
    parent: 'Misc Functions',
  },
];

const adminOnlyPermissions = [
  {
    route: '/miscFunctions/addBanner',
    title: 'Add Banner',
    parent: 'Misc Functions',
  },
];

export const permissions = {
  ADMIN: basePermissions.concat(adminOnlyPermissions),
  MANAGER: basePermissions,
  STANDARD: basePermissions,
  EXTERNAL: [
    {
      route: '/userFunctions/getUserSummary',
      title: 'Get User Summary',
      parent: 'User Functions',
    },
  ],
};
