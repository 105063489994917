import React, { useEffect, useState } from 'react';
import {
  Popover,
  ArrowContainer,
  PopoverAlign,
  PopoverPosition,
} from 'react-tiny-popover';
import FadeIn from 'react-fade-in/lib/FadeIn';

interface GenericPopoverProps {
  anchorElement: React.ReactElement;
  body: React.ReactElement;
  primaryBtnElement?: React.ReactElement;
  secondaryBtnElement?: React.ReactElement;
  color: string;
  position: string;
  align: string;
  arrowSize: number;
  closeOnClick: boolean;
  closeBtn?: boolean;
  timeout?: number;

  primaryAction?: () => any;
  secondaryAction?: () => any;
}

const GenericPopover = ({
  anchorElement,
  body,
  primaryBtnElement,
  secondaryBtnElement,
  color,
  position,
  align,
  arrowSize,
  closeOnClick,
  closeBtn,

  primaryAction,
  secondaryAction,
}: GenericPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [secondaryDisabled, setSecondaryDisabled] = useState(false);
  const windowWidth = window.innerWidth;

  let anchor = React.cloneElement(anchorElement, {
    onClick: () => {
      if (anchorElement.props.onClick) {
        anchorElement.props.onClick();
      }
      document.dispatchEvent(new Event('closeOtherPopovers'));
      setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    },
  });

  let primaryBtn = primaryBtnElement
    ? React.cloneElement(primaryBtnElement, {
        onClick: async () => {
          if (primaryAction !== undefined) {
            setIsLoading(true);
            setSecondaryDisabled(true);
            await primaryAction();
            setIsLoading(false);
            setSecondaryDisabled(false);
            setIsPopoverOpen(false);
          }
        },
        isLoading: isLoading,
      })
    : null;

  let secondaryBtn = secondaryBtnElement
    ? React.cloneElement(secondaryBtnElement, {
        onClick: async () => {
          if (secondaryAction !== undefined) {
            await secondaryAction();
          }
          // if no secondary action, we assume it's a cancel button. Or if secondary action exists, close popover after.
          setIsPopoverOpen(false);
        },
        disabled: secondaryDisabled,
      })
    : null;


    useEffect(()=>{
      const handleEvent = () => {
        setIsPopoverOpen(false);
      };

      // Adding the event listener when the component mounts
      document.addEventListener('closeOtherPopovers', handleEvent);

      // Cleaning up the event listener when the component unmounts
      return ()=>{
        document.removeEventListener('closeOtherPopovers', handleEvent);
      }

    }, []);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={[position as PopoverPosition]}
      padding={10}
      onClickOutside={() => {
        if (!isLoading) setIsPopoverOpen(false);
      }}
      align={(windowWidth <= 640 ? 'center' : align) as PopoverAlign}
      reposition={false}
      content={({ position, childRect, popoverRect }) => {
        return (
          <FadeIn visible={isPopoverOpen}>
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={color}
              arrowSize={arrowSize}
              arrowStyle={{ opacity: 1 }}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <div
                className="popover-container l-flex-column"
                style={{ border: `1px solid ${color}` }}
                onClick={() => {
                  if (closeOnClick) setIsPopoverOpen(false);
                }}
              >
                {closeBtn && (
                  <i
                    className="c-btn__icon fa fa-times"
                    style={{
                      marginLeft: 'auto',
                      paddingRight: '0rem',
                      fontSize: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsPopoverOpen(false)}
                  />
                )}
                <div>{body}</div>

                <div className="l-flex-center">
                  {secondaryBtn}
                  {primaryBtn}
                </div>
              </div>
            </ArrowContainer>
          </FadeIn>
        );
      }}
    >
      {anchor as JSX.Element & React.ReactNode}
    </Popover>
  );
};

export default GenericPopover;
