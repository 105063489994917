import { Sidebar } from './Sidebar';
import React from 'react';
import { Dialog } from '@reach/dialog';
import { useMenu } from '../context/Menu';
import { UserState, UserType } from '../types';

type MobileSidebarProps = {
  isAuthenticated: boolean;
  logout: () => void;
  userName: string;
  userState: UserState;
  userType: UserType;
  userTimezone?: string;
};

export function MobileSidebar({
  isAuthenticated,
  logout,
  userName,
  userState,
  userType,
  userTimezone,
}: MobileSidebarProps) {
  const { showMobileNav, toggleShowMobileNav } = useMenu();
  return (
    <Dialog
      isOpen={showMobileNav}
      onDismiss={toggleShowMobileNav}
      className="c-modal-menu c-modal-menu-slider"
      aria-label="mobile menu"
    >
      <Sidebar
        userName={userName}
        userState={userState}
        userType={userType}
        userTimezone={userTimezone}
        isAuthenticated={isAuthenticated}
        logout={() => {
          logout();
          toggleShowMobileNav();
        }}
        toggleShowMobileNav={toggleShowMobileNav}
      />
    </Dialog>
  );
}
