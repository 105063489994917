import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import config from './config';
import Routes from './Routes';

import AlertModal from './components/AlertModal';
import { Loading } from './components/Loading';
import { Text } from './components/Text';

import { MobileSidebar } from './containers/MobileSidebar';
import { Sidebar } from './containers/Sidebar';

import { useUser } from './context/User';

import { useLanguage } from './libs/Language';

import logo from './assets/images/aa-support.svg';

class App extends Component {
  constructor(props) {
    super(props);

    window.addEventListener(
      'orientationchange',
      this.handleChangeOrientation.bind(this)
    );

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      alertMessage: '',
      showModal: false,
      showUnauthModal: false,
      showTimezoneSelect: false,
      revision: 0,
    };
  }

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      if (
        e !== 'No current user' &&
        e.message !== 'Refresh Token has expired'
      ) {
        console.log('Error getting a current session: ', e);
        this.setState({
          alertMessage: e.message,
          showModal: true,
        });
      }
    }
    window.localStorage.setItem('tempTimezone', '');
    window.addEventListener('renderTimezone', (e) => {
      this.forceUpdate();
    });

    window.addEventListener('scroll', () => {
      if (!document.querySelector('.floating-btn')) return;

      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll > 2000) {
        document
          .querySelector('.floating-btn')
          .classList.add('floating-btn-show');
      } else {
        document
          .querySelector('.floating-btn')
          .classList.remove('floating-btn-show');
      }
    });

    this.setState({ isAuthenticating: false });

    window.addEventListener('unauthUser', () => {
      this.setState({
        showUnauthModal: true,
      });
    });

    window.localStorage.setItem('pageData', '');
  }

  userHasAuthenticated = (authenticated, newUser) => {
    if (newUser) {
      this.setState({ creatingUser: true });
    } else {
      this.setState({ creatingUser: false });
    }
    this.setState({ isAuthenticated: authenticated });
  };

  handleCancel = () => {
    this.setState({ showModal: false, showUnauthModal: false });
  };

  // TODO: move to context - Chance Smith 9/30/21
  updateUserInfo = (userInfo) => {
    this.setState({
      isLoading: false,
    });
    this.props.userContext.updateUser({ ...userInfo });
  };

  addEventListenerOnce(target, type, listener, addOptions, removeOptions) {
    target.addEventListener(
      type,
      function fn(event) {
        target.removeEventListener(type, fn, removeOptions);
        listener.apply(this, arguments);
      },
      addOptions
    );
  }

  handleChangeOrientation = () => {
    this.addEventListenerOnce(window, 'resize', this.handleResize.bind(this));
  };

  handleResize = () => {
    this.setState({
      revision: this.state.revision < 100 ? this.state.revision + 1 : 0,
    });
  };

  handleLogout = async () => {
    const redirectStr =
      config.AUTH_DIAG_ENVIRONMENT === 'DEV'
        ? 'http://localhost:3000/Home'
        : 'https://' + window.location.hostname + '/Home';

    const paramStr =
      'response_type=code&' +
      'scope=aws.cognito.signin.user.admin+email+openid+profile&' +
      'client_id=' +
      config.cognito.APP_CLIENT_ID +
      '&' +
      'redirect_uri=' +
      redirectStr;

    window.location.assign(config.cognito.DOMAIN + '/logout?' + paramStr);
    // await Auth.signOut();

    // this.userHasAuthenticated(false);
  };

  handleShowAlert = (title, message, stringify = true) => {
    this.setState({
      alertTitle: title,
      alertMessage: !stringify ? message : JSON.stringify(message),
      showModal: true,
    });
  };

  handleShowTimezoneSelect = (e) => {
    this.setState({
      showTimezoneSelect: e,
    });
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      updateUserInfo: this.updateUserInfo,
      fetchUser: this.props.userContext.fetchUser,
      pathname: this.props.location.pathname,
      user: this.props.userContext.user,
      revision: this.state.revision,
      logout: this.handleLogout,
      handleShowAlert: this.handleShowAlert,
      showTimezoneSelect: this.handleShowTimezoneSelect,
    };

    if (this.props.history.location.search !== '') {
      // Parse the url for values
      let searchStr = this.props.history.location.search;
      let values = searchStr.substring(searchStr.indexOf('?') + 1);
      values.split('&').forEach((elem) => {
        let nameValue = elem.split('=');
        if (nameValue[0] !== 'redirect') {
          childProps[nameValue[0]] = nameValue[1];
        } else {
          let searchStr = elem.substring(elem.indexOf('=')+1);
          let values = searchStr.substring(searchStr.indexOf('?') + 1);
          values.split('&').forEach((elem) => {
            let nameValue = elem.split('=');
            childProps[nameValue[0]] = nameValue[1];
          });
        }
      });
    } else if (childProps.pathname.indexOf('?') !== -1) {
      let pathname = childProps.pathname;
      let pathnameValues = pathname.substring(pathname.indexOf('?')+1);
      pathnameValues.split('&').forEach((elem) => {
        let nameValue = elem.split('=');
        childProps[nameValue[0]] = nameValue[1];
      });
      childProps.pathname = pathname.substring(0, pathname.indexOf('?'));
    }

    if (this.state.creatingUser) {
      childProps.creatingUser = true;
    }

    return (
      !this.state.isAuthenticating && (
        <>
          <div className="l-container">
            <div
              className={`l-view-layout l-view-layout--sidebar ${
                childProps.pathname === '/' || childProps.pathname === '/login'
                  ? 'l-view--login'
                  : ''
              }`}
            >
              <aside className="l-view-layout__sidebar">
                <Sidebar
                  userName={this.props.userContext.user?.userName}
                  isAuthenticated={this.state.isAuthenticated}
                  logout={this.handleLogout}
                  userState={this.props.userContext.user?.userState}
                  userType={this.props.userContext.user?.userType}
                  userTimezone={this.props.userContext.user?.userTimezone}
                />
              </aside>



              <div className="l-view-layout__main l-view-layout--two-column">
                <NavLink
                  className="c-primary-nav__link u-margin-top-small u-sidebar-hidden"
                  to="/"
                >
                  <div className="c-logo">
                    <img src={logo} className="c-logo__image" alt="logo" />
                  </div>
                </NavLink>

                <Routes childProps={childProps} />
              </div>
              <AlertModal
                title={this.state.alertTitle}
                message={<Text tid={this.state.alertMessage} />}
                showModal={this.state.showModal}
                size="small"
                handleCancel={this.handleCancel.bind(this)}
              />
              <AlertModal
                title={'Error'}
                message="Your token may have expired. Please log back in."
                showModal={this.state.showUnauthModal}
                size="small"
                BtnText="Log Out"
                handleCancel={() => {
                  this.handleCancel();
                  this.handleLogout();
                }}
              />
            </div>
            <MobileSidebar
              userName={this.props.userContext.user?.userName}
              isAuthenticated={this.state.isAuthenticated}
              logout={this.handleLogout}
              userState={this.props.userContext.user?.userState}
              userType={this.props.userContext.user?.userType}
              userTimezone={this.props.userContext.user?.userTimezone}
            />
          </div>
          <Loading className="spinner-blur u-is-hidden" />
        </>
      )
    );
  }
}

const AppWithRouter = withRouter(App);

export default (props) => {
  const languageContext = useLanguage();
  const userContext = useUser();
  return (
    <AppWithRouter
      languageContext={languageContext}
      userContext={userContext}
      {...props}
    />
  );
};
