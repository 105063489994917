import moment from 'moment';
import { Log, Shop } from '../../../../types';
import {
  SET_LOG,
  SET_GROUPED_LOG,
  SET_QUERY,
  SET_QUERY_TEXT,
  SET_QUERY_TYPE,
  SET_QUERY_ERROR,
  SET_HELP_TEXT,
  SET_START_DATE,
  SET_END_DATE,
  SET_SELECTED_ACTION_CODES,
  SET_IS_LOADING,
  SET_QUERIED_SHOP,
  SET_TABSET,
  SET_SHOW_LOG,
  SET_SHOW_SELECT_SHOP,
  SET_SHOPS,
  SET_SHOW_SHOPID_COL,
  SET_SHOW_ACTION_CODE_COL,
  SET_ENTER_PRESSED,
} from './actions';

export const initialState = {
  log: [],
  groupedLog: {} as Log,
  query: '',
  queryText: '',
  queryType: 'SHOP NAME',
  queryError: '',
  helpText:
    'SHOP NAME is a case-sensitive "contains" query, and can be partially matched (i.e., "T", "Te", "Tes" or "Test" will match "Test Auto Shop").',
  startDate: moment.utc().subtract(30, 'days').startOf('day'),
  endDate: moment.utc().endOf('day'),
  selectedActionCodes: [],
  isLoading: false,
  queriedShop: {} as Shop,
  tabset: 'SINGLE',
  showLog: false,
  showSelectShop: false,
  shops: [] as Shop[],
  showShopIDCol: true,
  showActionCodeCol: true,
  enterPressed: false,
};

export const reducer = (
  state: typeof initialState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOG:
      return { ...state, log: payload };
    case SET_GROUPED_LOG:
      return { ...state, groupedLog: payload };
    case SET_QUERY:
      return { ...state, query: payload };
    case SET_QUERY_TEXT:
      return { ...state, queryText: payload };
    case SET_QUERY_TYPE:
      return { ...state, queryType: payload };
    case SET_QUERY_ERROR:
      return { ...state, queryError: payload };
    case SET_HELP_TEXT:
      return { ...state, helpText: payload };
    case SET_START_DATE:
      return { ...state, startDate: payload };
    case SET_END_DATE:
      return { ...state, endDate: payload };
    case SET_SELECTED_ACTION_CODES:
      return { ...state, selectedActionCodes: payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case SET_QUERIED_SHOP:
      return { ...state, queriedShop: payload };
    case SET_TABSET:
      return { ...state, tabset: payload };
    case SET_SHOW_LOG:
      return { ...state, showLog: payload };
    case SET_SHOW_SELECT_SHOP:
      return { ...state, showSelectShop: payload };
    case SET_SHOPS:
      return { ...state, shops: payload };
    case SET_SHOW_SHOPID_COL:
      return { ...state, showShopIDCol: payload };
    case SET_SHOW_ACTION_CODE_COL:
      return { ...state, showActionCodeCol: payload };
    case SET_ENTER_PRESSED:
      return { ...state, enterPressed: payload };
    default:
      return state;
  }
};
