import { API } from 'aws-amplify';
import { systemInfo } from '../libs/utils';
import { trimObjFields } from '../libs/utils-ts';
import { RateLimiter } from 'limiter';
import config from '../config';

// API Limit is 10 calls per 10s
const limiter = new RateLimiter({ tokensPerInterval: 10, interval: 10000 });
const API_NAME = 'authdiag-backend-support-dev';
const PATH_PREFIX = '/supportui/';

const APIWrap = {
  ...API,
  /**
   * Make a GET request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  get: async (apiName, path, init) => {
    await limiter.removeTokens(1);
    return API.get(apiName, path, trimObjFields(init));
  },
  /**
   * Make a POST request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  post: async (apiName, path, init) => {
    await limiter.removeTokens(1);
    return API.post(apiName, path, trimObjFields(init));
  },
};


/**
 * Make am API Request
 * @param {string} method - HTTP method of request (GET, POST)
 * @param {json} body - The body of the request
 * @param {json} pathSuffix - The suffix of the route's path (ex. For "/supportui/getUserSummary", pathSuffix is "getUserSummary")
 * @return {json} - JSON data of the API request, if successful
 */
const apiReq = async (method, body, pathSuffix, response = undefined) => {
  const apiFunc = method === 'GET' ? APIWrap.get : APIWrap.post;

  return await apiFunc(API_NAME, `${PATH_PREFIX}${pathSuffix}`, {
    body: body,
    response: response,
    headers: {
      Authorization: window.localStorage.getItem('Id-Token'),
    },
  })
  .then((result) => {
    return result;
  })
  .catch(async (error) => {
    if (
      error.response &&
      [401, 403].indexOf(error.response.status) !== -1
    ) {
      const result = await requestToken(
        window.localStorage.getItem('Refresh-Token'),
        true
      );
      if (result) {
        document.dispatchEvent(
          new CustomEvent('unauthUser', { bubbles: true })
        );
      } else {
        return await apiFunc(
          API_NAME,
          `${PATH_PREFIX}${pathSuffix}`,
          {
            body: body,
            response: response,
            headers: {
              Authorization: window.localStorage.getItem('Id-Token'),
            },
          }
        )
          .then((result) => {
            return result;
          })
          .catch((error) => {
            if (
              error.response &&
              [401, 403].indexOf(error.response.status) !== -1
            ) {
              document.dispatchEvent(
                new CustomEvent('unauthUser', { bubbles: true })
              );
            } else {
              if (error.response) {
                if (error.response.data.errorDesc) {
                  return { error: error.response.data.errorDesc };
                }
                return { error: error.response };
              } else {
                return { error: error };
              }
            }
          });
      }
    } else if (error.response) {
      if (error.response.data.errorDesc) {
        return { error: error.response.data.errorDesc };
      }
      return { error: error.response };
    } else {
      return { error: error };
    }
  });
};

//**** This code caches backend queried data.  Uncomment if we want to go back to caching ***/
// let cache = {};
// const apiReq = async (method, body, pathSuffix, response = undefined) => {
//   const apiFunc = method === 'GET' ? APIWrap.get : APIWrap.post;
//
//   let cacheIndex = `${method}&${JSON.stringify(body)}&${pathSuffix}`;
//
//   if (cache[cacheIndex] === undefined) {
//     cache[cacheIndex] = await apiFunc(API_NAME, `${PATH_PREFIX}${pathSuffix}`, {
//       body: body,
//       response: response,
//       headers: {
//         Authorization: window.localStorage.getItem('Id-Token'),
//       },
//     })
//       .then((result) => {
//         return result;
//       })
//       .catch(async (error) => {
//         if (
//           error.response &&
//           [401, 403].indexOf(error.response.status) !== -1
//         ) {
//           const result = await requestToken(
//             window.localStorage.getItem('Refresh-Token'),
//             true
//           );
//           if (result) {
//             document.dispatchEvent(
//               new CustomEvent('unauthUser', { bubbles: true })
//             );
//           } else {
//             cache[cacheIndex] = await apiFunc(
//               API_NAME,
//               `${PATH_PREFIX}${pathSuffix}`,
//               {
//                 body: body,
//                 response: response,
//                 headers: {
//                   Authorization: window.localStorage.getItem('Id-Token'),
//                 },
//               }
//             )
//               .then((result) => {
//                 return result;
//               })
//               .catch((error) => {
//                 if (
//                   error.response &&
//                   [401, 403].indexOf(error.response.status) !== -1
//                 ) {
//                   document.dispatchEvent(
//                     new CustomEvent('unauthUser', { bubbles: true })
//                   );
//                 } else {
//                   if (error.response) {
//                     if (error.response.data.errorDesc) {
//                       return { error: error.response.data.errorDesc };
//                     }
//                     return { error: error.response };
//                   } else {
//                     return { error: error };
//                   }
//                 }
//               });
//             return cache[cacheIndex];
//           }
//         } else if (error.response) {
//           if (error.response.data.errorDesc) {
//             return { error: error.response.data.errorDesc };
//           }
//           return { error: error.response };
//         } else {
//           return { error: error };
//         }
//       });
//   }
//
//   let delayReturn = new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve(cache[cacheIndex]);
//     }, 250);
//   });
//
//   return await delayReturn;
// };

const SUPPORT_ADMIN_CREATE_USER = 1;

export async function requestToken(code, refresh) {
  const redirectStr =
    config.AUTH_DIAG_ENVIRONMENT === 'DEV'
      ? 'http://localhost:3000/Home'
      : 'https://' + window.location.hostname + '/Home';

  const grantType = refresh ? 'refresh_token' : 'authorization_code';
  let bodyStr =
    'grant_type=' +
    grantType +
    '&' +
    'client_id=' +
    config.cognito.APP_CLIENT_ID;
  if (refresh) {
    bodyStr += '&refresh_token=' + code;
  } else {
    bodyStr +=
      '&code=' + code + '&redirect_uri=' + redirectStr + '&scope=openid';
  }

  return await fetch(config.cognito.DOMAIN + '/oauth2/token', {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Length': bodyStr.length,
    },
    body: bodyStr,
  })
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response.message) {
        return response.message;
      } else {
        localStorage.setItem('Id-Token', response.id_token);
        if (!refresh) {
          localStorage.setItem('Refresh-Token', response.refresh_token);
        }
        const currentTimeInMilliseconds = new Date().getTime();
        const expireTime =
          response.expires_in * 1000 + currentTimeInMilliseconds;
        localStorage.setItem('Expire-Time', expireTime);
        return null;
      }
    })
    .catch((err) => {
      console.log('err: ', err);
      return err;
    });
}

export async function createSupportUser(
  userName,
  password,
  firstName,
  lastName,
  email,
  userType,
  authProvider
) {
  let body = {
    userName: userName,
    password: password || 'Password1234!',
    firstName: firstName,
    lastName: lastName,
    email: email,
    userType: userType,
    authProvider: authProvider,
    functionID: SUPPORT_ADMIN_CREATE_USER,
  };

  if (!password) {
    delete body.password;
  }

//   cache = [];
  return await apiReq('POST', body, 'execSupportAdminFunction');
}

export async function getUserRecord() {
  return await apiReq('GET', {}, 'getSupportUserInfo', true);
}

export async function updateSupportUser(firstName, lastName, email) {
  let body = {
    firstName: firstName,
    lastName: lastName,
    email: email,
  };

//   cache = [];
  return await apiReq('POST', body, 'updateSupportUserInfo');
}

export async function updateSupportUserState(userID, userState) {
  let body = {
    userID: userID,
    userState: userState,
  };

//   cache = [];
  return await apiReq('POST', body, 'updateSupportUserState');
}

export async function updateSupportUserType(userID, userType) {
  let body = {
    userID: userID,
    userType: userType,
  };

//   cache = [];
  return await apiReq('POST', body, 'updateSupportUserType');
}

export async function getSupportUserList() {
  return await apiReq('GET', {}, 'getSupportUserList');
}

export async function getUserSummary(queryType, queryValue) {
  if (queryType === 'EMAIL') queryValue = queryValue.toLowerCase();
  let body = {
    queryType: queryType.split(' ').join(''),
    queryValue: queryValue,
  };

  return await apiReq('POST', body, 'getUserSummary');
}

export async function getUserSummaryByProperty(queryType, queryValue) {
  let body = {
    queryType: queryType,
    queryValue: queryValue,
  };

  return await apiReq('POST', body, 'getUserSummaryByProperty');
}

export async function getShopSummary(shopID) {
  let body = {
    shopID: shopID,
  };

  return await apiReq('POST', body, 'getShopSummary');
}

export async function getShopNames(shopIDs) {
  let body = {
    shopIDs: shopIDs,
  };

  return await apiReq('POST', body, 'getShopNames');
}

export async function getShops(shopIDs) {
  let body = {
    shopIDs: shopIDs,
  };

  return await apiReq('POST', body, 'getShops');
}

export async function getAllShopNames() {
  let body = {};

  let results = await apiReq('POST', body, 'getAllShopNames');
  let shops = results.shops;
  while (results.lastEvaluatedKey) {
    body.lastEvaluatedKey = results.lastEvaluatedKey;
    results = await apiReq('POST', body, 'getAllShopNames');
    shops = shops.concat(results.shops);
  }
  return shops;
}

export async function getShopSummaryByProperty(queryType, queryValue) {
  let body = {
    queryType: queryType,
    queryValue: queryValue,
  };

  let results = await apiReq('POST', body, 'getShopSummaryByProperty');
  let shopList = results.shopList;
  while (results.lastEvaluatedKey) {
    body.lastEvaluatedKey = results.lastEvaluatedKey;
    results = await apiReq('POST', body, 'getShopSummaryByProperty');
    shopList = shopList.concat(results.shopList);
  }
  return shopList;
}

export async function getUserLog(userID, startDate, endDate) {
  let body = {
    userID: userID,
    startDate: startDate,
    endDate: endDate,
  };

  let results =  await apiReq('POST', body, 'getUserLog');
  let logs = results.logEntries;
  while (results.LastEvaluatedKey) {
    body.LastEvaluatedKey = results.LastEvaluatedKey;
    results = await apiReq('POST', body, 'getUserLog');
    logs = logs.concat(results.logEntries);
  }
  return logs;
}

export async function getShopLog(shopID, startDate, endDate) {
  let body = {
    shopID: shopID,
    startDate: startDate,
    endDate: endDate,
  };

  return await apiReq('POST', body, 'getShopLog');
}

export async function getToolLog(
  queryType,
  queryValue,
  shopID,
  startDate,
  endDate
) {
  let body = {
    queryType: queryType,
    queryValue: queryValue,
    shopID: shopID,
    startDate: startDate,
    endDate: endDate,
  };

  let result = await apiReq('POST', body, 'getToolLog');

  if (result.error) return result;

  let filteredEntries = result.logEntries.filter(
    (entry) => entry.actionCode !== 'SUPPORT_GET_TOOL_LOG'
  );
  return {
    logEntries: filteredEntries,
  };
}

export async function getEmailLog(queryType, queryValue, startDate, endDate) {
  if (queryType === 'EMAIL') queryValue = queryValue.toLowerCase();
  let body = {
    queryType: queryType,
    queryValue: queryValue,
    startDate: startDate,
    endDate: endDate,
  };

  return await apiReq('POST', body, 'getEmailLog');
}

export async function getLoginActivityLog(queryValue, startDate, endDate) {
  let body = {
    userName: queryValue,
    startDate: startDate || null,
    endDate: endDate || null,
  };
  return await apiReq('POST', body, 'getLoginActivityForUsername');
}

export async function getSupportAuditLog(userID, startDate, endDate) {
  let body = {
    userID: userID,
    startDate: startDate,
    endDate: endDate,
  };

  return await apiReq('POST', body, 'getSupportAuditLog');
}

export async function removeToolFromShop(shopID, toolID) {
  let body = {
    shopID: shopID,
    toolID: toolID,
  };

//   cache = [];
  return await apiReq('POST', body, 'removeToolFromShop');
}

export async function getToolRecoveryRequests(showClosed, startDate, endDate) {
  const body = {
    showClosed,
    startDate,
    endDate,
  };
  return await apiReq('POST', body, 'getToolRecoveryRequests');
}

export async function getToolRecoveryImage(docKey) {
  let body = {
    docKey: docKey,
  };

  return await apiReq('POST', body, 'getToolRecoveryImageURL')
    .then(async (response) => {
      console.log(response);
      return await fetch(response)
    })
    .then(async (response) => {
      const blob = await response.blob();
      return blob;
    })
    .catch((error) => {
      if (error.response) {
        return {error: error.response.data.errorDesc};
      } else if (error?.errorDesc) {
        return { error: error.errorDesc };
      } else if (error?.message) {
        return { error: error.message };
      } else {
        return { error: error.toString() };
      }
    });
}

export async function contactToolRecoveryOwner(toolID) {
  let body = {
    toolID: toolID,
  };

//   cache = [];
  return await apiReq('POST', body, 'contactToolRecoveryOwner');
}

export async function processToolRecoveryRequest(
  toolID,
  status,
) {
  let body = {
    toolID: toolID,
    status: status,
  };

//   cache = [];
  return await apiReq('POST', body, 'processToolRecoveryRequest');
}

export async function closeToolRecoveryRequest(
  toolID,
  reason
) {
  let body = {
    toolID: toolID,
    reason: reason,
  };

//   cache = [];
  return await apiReq('POST', body, 'closeToolRecoveryRequest');
}

export async function refundTransaction(transactionID, shopID, amount, refundDetails) {
  let body = {
    transactionID: transactionID,
    shopID: shopID,
    amount: amount,
    refundDetails: refundDetails,
  };

//   cache = [];
  return await apiReq('POST', body, 'refundTransaction');
}

export async function terminateShop(shopID) {
  let body = {
    shopID: shopID,
  };

//   cache = [];
  return await apiReq('POST', body, 'terminateShop');
}


export async function downgradeShop(shopID) {
  let body = {
    shopID: shopID,
  };

//   cache = [];
  return await apiReq('POST', body, 'downgradeShop');
}

export async function removeOEMFromShop(shopID, oemID) {
  let body = {
    shopID: shopID,
    oemID: oemID,
  };

//   cache = [];
  return await apiReq('POST', body, 'removeOEMFromShop');
}

export async function updateShopState(shopID, state) {
  let body = {
    shopID: shopID,
    state: state,
  };

//   cache = [];
  return await apiReq('POST', body, 'updateShopState');
}

export async function getExtendedToolInfo(query, queryType) {
  let body = {
    query: query,
    queryType: queryType,
  };

  return await apiReq('POST', body, 'getExtendedToolInfo');
}

export async function getVinInfo(vins) {
  let body = {
    vins: vins,
  };

  return await apiReq('POST', body, 'getVinInfo');
}

export async function getSystemConfig() {
  return await apiReq('GET', {}, 'getUIConfigs');
}

export async function recordSupportUserSession() {
  let sysInfo = systemInfo();
  let body = {
    browserName: sysInfo.browserName,
    browserVersion: String(sysInfo.browserVersion),
    hostOS: sysInfo.hostOS,
  };

  let res = await apiReq('POST', body, 'recordSupportUserSession');
  if (res.error) {
    console.log('Error trying to record user session: ', res.error);
  }
}

export async function sendBulkEmail(usersType, emailAddress, emailSubject, emailBodyHtml, emailBodyText) {
  let body = {
    usersType,
    emailAddress,
    emailBodyHtml,
    emailBodyText,
    emailSubject,
  };

  return await apiReq('POST', body, 'sendBulkEmail');
}

export async function searchShopsElastic(query) {
  let body = {
    query: query,
  };
  let res = await apiReq('POST', body, 'searchShopsElastic');

  return res;
}

export function refreshCache() {
//   cache = [];
}

export async function getOEMRegionList() {
  return await apiReq('GET', {}, 'getOEMRegionList');
}

export async function addBanner(messageBanner, pathBanner, severityBanner) {
  let body = {
    messageBanner,
    pathBanner,
    severity: severityBanner,
  };
  return await apiReq('POST', body, 'addBanner');
}

export async function getBanner() {
  return await apiReq('GET', {}, 'getBanner');
}

export async function getBannerActivity(startDate, endDate) {
  const body = {
    startDate,
    endDate,
  };
  return await apiReq('POST', body, 'getBannerActivity');
}