import React, { useEffect, useState, useRef } from 'react';
import ReactTable from 'react-table';
import { addBanner, getBanner, getBannerActivity } from '../../libs/db-lib';

import { Header } from '../../components/Header';
import LoaderButton from '../../components/LoaderButton';

import moment from 'moment';
import { Loading } from '../../components/Loading';
import { TextOnly } from '../../components/Text';

import { toast } from 'react-toastify';
import { ChildProps } from '../../types';
import { formatDateTime2Lines } from '../../libs/utils';

import CustomDatePicker from '../../components/CustomDatePicker';
import CopyLink from '../../components/CopyLink';

const bannerSeverity = [
  { value: "ERROR", label: "ERROR" },
  { value: "WARNING", label: "WARNING" },
  { value: "GOOD", label: "GOOD" },
];

interface SeverityOptions {
  severity: 'ERROR' | 'WARNING' | 'GOOD';
}


const AddBanner = (props: ChildProps) => {

  const { user } = props;
  const [filter, setFilter] = useState<string>('');
  const [arrayBannerActivity, setArrayBannerActivity] = useState<any>([]);
  const [arrayFilteredAct, setArrayFilteredAct] = useState<any>([]);
  const bannerActivity = useRef<any>([]);
  const [messageBanner, setMessageBanner] = useState('');
  const [pathBanner, setPathBanner] = useState('');
  const [severityBanner, setSeverityBanner] = useState<SeverityOptions['severity']>('ERROR');
  const [loading, setLoading] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [currentBanner, setCurrentBanner] = useState<any>();

  const [startDate, setStartDate] = useState(
    moment.utc().subtract(30, 'days').startOf('day')
  );
  const [endDate, setEndDate] = useState(moment.utc().endOf('day'));

  const bannerActCol = [
    {
      Header: 'User Name',
      accessor: 'userID',
    },
    {
      Header: 'Action Message',
      accessor: 'actionMessage',
    },
    {
      Header: 'Action Date',
      accessor: 'actionDate',
      Cell: (row: any) => {
        const dateObj = formatDateTime2Lines(row.original.actionDate);
        return (
          <div>
            {dateObj.date}
            <br />
            {dateObj.time}
          </div>
        );
      },
    },
    {
      Header: 'Severity',
      accessor: 'level',
      Cell: (row: any) => {
        return row.original.level ?? row.original.severity
      },
    },
    {
      Header: 'Message',
      accessor: 'message',
      Cell: (row: any) => {
        return row.original.message ?? row.original.messageBanner
      },
    },
    {
      Header: 'Link',
      accessor: 'link',
      Cell: (row: any) => {
        return row.original.link ?? row.original.pathBanner
      },
    },
    {
      Header: 'Shop ID',
      accessor: 'shopID',
      Cell: (row: any) => {
        return (
          row?.original?.shopID ?
          <CopyLink
            content={row.original.shopID}
            className="u-font-mono"
            type={'SHOP ID'}
            redirect={'/shopFunctions/getShopSummary'}
            urlParams={`?query=${row.original.shopID}&queryType=SHOP+ID`}
            showCopy={true}
          />
          : null
        );
      },
    },
  ]

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setFilter(inputValue);
  };

  const validateSubmit = () =>{
    if(
      messageBanner.length === 0 ||
      !['ERROR', 'WARNING', 'GOOD'].includes(severityBanner)
    ) {
      return false;
    } else if(
      messageBanner  === currentBanner?.message &&
      pathBanner     === currentBanner?.link &&
      severityBanner === currentBanner?.severity
    ) {
      return false
    } else {
      return true;
    }
  }
  const handleChangeSeverity = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSeverityBanner(event.target.value as SeverityOptions['severity']);
  };
  const handleChangeMessageBanner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageBanner(event.target.value);
  };
  const handleChangePathBanner = (event: React.ChangeEvent<HTMLInputElement>) => {
    const path = event.target.value;
    setPathBanner(path);
  };
  const handleClearForm = () => {
    setMessageBanner('');
    setPathBanner('');
    setSeverityBanner('ERROR');
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    if(!validateSubmit()) return;
    else {
      const result = await addBanner(
        messageBanner,
        pathBanner.length > 0 && pathBanner[0] !== '\/'
          ? '\/' + pathBanner
          : pathBanner,
        severityBanner);
      if(!result.error) {
        setCurrentBanner({
          link: pathBanner,
          message: messageBanner,
          severity: severityBanner,
        });
        handleClearForm();
      } else {
        toast.error(
          'Error: ' + result.error,
          {
            containerId: 'standard',
          }
        );
      }
    }
    setLoading(false);
  };
  const handleRemove = async () => {
    setLoadingRemove(true)
    const result = await addBanner('','','');
    if(!result.error) setCurrentBanner(null)
    else {
      toast.error(
        'Error: ' + result.error,
        {
          containerId: 'standard',
        }
      );
    }
    setLoadingRemove(false);
  };

  const handleRangeChange = (value: any) => {
    setStartDate(value.start);
    setEndDate(value.end);
  };

  useEffect( () =>{
    const getCurrentBanner = async () => {
      const result = await getBanner();
      if(!result.error) setCurrentBanner(result);
      else {
        toast.error(
          'Error: ' + result.error,
          {
            containerId: 'standard',
          }
        );
      }
    };
    getCurrentBanner();
  },[]);

  const getActivity = async () => {
    setLoadingActivity(true);
    setShowTable(true);
    bannerActivity.current = [];
    const response = await getBannerActivity(startDate.toISOString(), endDate.toISOString());
    if(!response?.error) bannerActivity.current = response;
    else {
      toast.error(
        'Error ' + response.error,
        {
          containerId: 'standard',
        }
      );
    }
    setFilter('');
    setArrayBannerActivity(bannerActivity.current);
    setLoadingActivity(false);
  }

  useEffect( () => {
    const filtered = arrayBannerActivity.filter((banner: any) =>
    Object.values(banner)
      .join(' ')
      .toLowerCase()
      .includes(filter.toLowerCase())
    );
    setArrayFilteredAct(filtered);
  },[filter]);

  useEffect( () => {
    setArrayFilteredAct(arrayBannerActivity);
  },[arrayBannerActivity]);


  if(currentBanner === undefined){
    return (
    <>
      <Header context="Misc Functions" title="Add Banner" />
      <Loading />
    </>
    )
  } else {
    return (
      <>
        <Header context="Misc Functions" title="Add Banner" />
        <div className="l-container">
          { currentBanner?.message?.length > 0 &&
            <div className="c-field u-margin-top-large">
              <label htmlFor="vin" className="c-field__label u-margin-top-large">
                Current Banner{currentBanner.link ? ` (PATH: '${currentBanner.link}' ) ` : ''}:
              </label>
              <div
                className={`c-select banner-${currentBanner.severity} cursor-auto`}
              >
                <select className={`cursor-auto  banner-${currentBanner.severity}`}>
                  <option value='' className={`zindex-1`}>
                    {currentBanner?.message}
                  </option>
                </select>
              </div>
              <LoaderButton
                disabled={!currentBanner?.message}
                isLoading={loadingRemove}
                text="Remove"
                loadingText="Removing..."
                className="u-margin-top-small"
                onClick={()=>handleRemove()}
              />
            </div>
          }
          <form onSubmit={handleSubmit}>
            <div className="c-field u-margin-top-large">
              <label htmlFor="vin" className="c-field__label u-margin-top-large">
                Banner Message:
              </label>
              <input
                className='banner-input'
                placeholder="Banner message here..."
                onChange={handleChangeMessageBanner}
                value={messageBanner}
              />
            </div>
            <div className="c-field">
              <label htmlFor="vin" className="c-field__label">
                Path (optional):
              </label>
              <input
                className='banner-input'
                placeholder="Path here. E.G. ' /myActivity '"
                onChange={handleChangePathBanner}
                value={pathBanner}
              />
            </div>
            <div className="c-field">
              <label htmlFor="vin" className="c-field__label">
                Banner Severity:
              </label>
              <div
                className={`c-select banner-${severityBanner}`}
              >
                <select value={severityBanner} onChange={handleChangeSeverity} className={`zindex-1 banner-${severityBanner}`}>
                  {bannerSeverity.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="c-field u-margin-top-large">
              <LoaderButton
                type="submit"
                disabled={!validateSubmit()}
                isLoading={loading}
                text={currentBanner?.message ? 'Update' : 'Add'}
                loadingText="Posting..."
              />
            </div>
          </form>
          <div>
            <div className="c-field">
              <label htmlFor="query" className="c-field__label u-margin-top-large">
                Date Range
              </label>
              <CustomDatePicker
                lang="en"
                idPrefix="tool"
                value={{
                  start: startDate,
                  end: endDate,
                  name: 'Last 30 Days',
                }}
                drops={'down'}
                onChange={handleRangeChange}
                user={user}
              />
            </div>
            <LoaderButton
              onClick={() => getActivity()}
              type="button"
              isLoading={loadingActivity}
              text="Get Banner History"
              loadingText="Getting Banner History..."
            />
          </div>
          { showTable && !loadingActivity &&
            <div className="u-margin-top-xlarge u-margin-bottom-large">
              <input
                type="text"
                className='u-margin-bottom-small'
                placeholder="Filter by name, description, etc."
                value={filter}
                onChange={handleFilter}
              />
              <ReactTable
                columns={bannerActCol}
                data={arrayFilteredAct}
                previousText={TextOnly('previous')}
                nextText={TextOnly('next')}
                pageText={TextOnly('page')}
                ofText={TextOnly('of')}
                rowsText={TextOnly('rows')}
                noDataText={TextOnly('noDirectoryServices')}
                defaultPageSize={10}
                defaultSorted={[
                  {
                    id: 'actionDate',
                    desc: true,
                  },
                ]}
              />
            </div>
          }
        </div>
      </>
    );
  }
};

export default AddBanner;
