import React, { Component } from 'react';
import ReactTable, { Column } from 'react-table';
import 'react-table/react-table.css';
import { Moment } from 'moment';
import { toast } from 'react-toastify';
import _ from 'underscore';
import moment from 'moment';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { Text, TextOnly } from '../../components/Text';
import { Loading } from '../../components/Loading';
import { Header } from '../../components/Header';
import Switch from '../../components/Switch';

import { getSavedColumns } from '../../libs/utils-ts';
import { formatTimezone, generateFilterRegex } from '../../libs/utils';
import {
  getSupportUserList,
  updateSupportUserState,
  updateSupportUserType,
  getSystemConfig,
  getSupportAuditLog,
} from '../../libs/db-lib';

import { ChildProps, SupportUser, UserState, UserType } from '../../types';

import AddUser from './AddUser';
import DisplayDialog from '../../components/DisplayDialog';
import GenericTable from '../../components/GenericTable';
import { baseLogColumns } from '../../libs/tables/columnDefinitions';
import { SupportLogSubcomponent } from '../../libs/tables/subComponents';
import CustomDatePicker from '../../components/CustomDatePicker';

interface ManageUsersState {
  userName: string;
  newUserId?: string;
  newUserFirstName: string;
  newUserLastName: string;
  supportUsers: SupportUser[];
  supportUsersFiltered?: SupportUser[];
  userList?: SupportUser[];
  selectedUser?: any;
  userLogActions?: any[];
  userLogActionsFiltered?: any[];
  showModal: boolean;
  showAddUserModal: boolean;
  showUserLogModal: boolean;
  filter: string;
  filterLinks: string;
  filterActions: string;
  activeKey: number;
  previousText: string;
  nextText: string;
  pageText: string;
  ofText: string;
  rowsText: string;
  isLoading: boolean;
  isLoadingUsers: boolean;
  loadingActions?: string | null;
  loadingRoles?: string | null;
  baseURL: string;
  windowWidth: number;
  userToDelete?: SupportUser;
  startDate?: Moment;
  endDate?: Moment;
}
export default class ManageUsers extends Component<
  ChildProps,
  ManageUsersState
> {
  constructor(props: ChildProps) {
    super(props);

    this.state = {
      userName: '',
      newUserFirstName: '',
      newUserLastName: '',
      supportUsers: [],
      supportUsersFiltered: [],
      selectedUser: undefined,
      showModal: false,
      isLoading: false,
      isLoadingUsers: true,
      showAddUserModal: false,
      showUserLogModal: false,
      filter: '',
      filterLinks: '',
      filterActions: '',
      activeKey: 1,
      previousText: TextOnly('previous'),
      nextText: TextOnly('next'),
      pageText: TextOnly('page'),
      ofText: TextOnly('of'),
      rowsText: TextOnly('rows'),
      baseURL: '',
      windowWidth: 0,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated || this.props.user.userType !== 'ADMIN') {
      this.props.history.push('/dashboard');
    }
    try {
      let config = await getSystemConfig();
      this.setState({ baseURL: config.baseUrl });

      let supportUsers = await getSupportUserList();

      this.setState({
        isLoadingUsers: false,
        supportUsers: supportUsers ? supportUsers.userList : [],
        supportUsersFiltered: supportUsers ? supportUsers.userList : [],
      });
    } catch (e) {
      this.setState({
        isLoadingUsers: false,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  activateField = (event: React.FocusEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };

  disableField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      // @ts-ignore
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  handleAddUser = (newUser: SupportUser) => {
    let supportUsers = this.state.supportUsers;
    supportUsers.push(newUser);
    toast.success(TextOnly('requestHasBeenSent'), { containerId: 'standard' });
    this.setState({
      supportUsers: supportUsers,
      supportUsersFiltered: supportUsers,
    });
  };

  handleRemoveUser(userID: string) {
    // Update the user table
    let supportUsers = this.state.supportUsers;
    let newSupportUsers = supportUsers.filter((user) => {
      return user.userID !== userID;
    });
    this.setState({ supportUsers: newSupportUsers });
    if (this.state.filter) {
      this.updateFilteredList(this.state.filter);
    } else {
      this.setState({
        supportUsersFiltered: newSupportUsers,
      });
    }
  }

  handleChangeSupportUserState(userID: string, newState: UserState) {
    // Update the user table
    let supportUsers = this.state.supportUsers;
    let index = -1;
    for (let i = 0; i < supportUsers.length; ++i) {
      if (supportUsers[i].userID === userID) {
        index = i;
        break;
      }
    }
    supportUsers[index].userState = newState;
    this.setState({ supportUsers: supportUsers });
  }

  handleChangeSupportUserType(userID: string, newType: UserType) {
    // Update the user table
    let supportUsers = this.state.supportUsers;
    let index = -1;
    for (let i = 0; i < supportUsers.length; ++i) {
      if (supportUsers[i].userID === userID) {
        index = i;
        break;
      }
    }

    supportUsers[index].userType = newType;

    this.setState({ supportUsers: supportUsers });
  }

  updateFilter = _.debounce((f: string) => {
    this.updateFilteredList(f);
  }, 300);

  updateFilteredList = (filter: string) => {
    let userList = this.state.supportUsers;

    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);
      userList = this.state.supportUsers.filter((u) => {
        if (
          regexStr.test(u.firstName) ||
          regexStr.test(u.lastName) ||
          regexStr.test(u.userType) ||
          regexStr.test(u.userState) ||
          regexStr.test(u.userName)
        ) {
          return true;
        }
        return false;
      });
    }
    this.setState({
      supportUsersFiltered: userList || [],
    });
  };

  handleLogButton(selectedUser: any) {
    const endDate = moment();
    const startDate = moment().subtract(1, 'month');
    selectedUser.userName = selectedUser.username;
    this.setState({
      selectedUser: selectedUser,
      loadingActions: selectedUser.userID,
      isLoading: true,
    });
    this.getSupportAuditLog(selectedUser as SupportUser, startDate, endDate);
  }

  async getSupportAuditLog(
    selectedUser: SupportUser,
    startDate: Moment,
    endDate: Moment
  ) {
    const endDateStr = endDate.utc().endOf('day').toISOString();
    const startDateStr = startDate.utc().startOf('day').toISOString();
    let results = await getSupportAuditLog(
      selectedUser.userID,
      startDateStr,
      endDateStr
    );

    if (Object.hasOwn(results, 'error')) {
      if ([401, 403].indexOf(results.error.status) === -1) {
        this.props.handleShowAlert(TextOnly('error'), results.error);
      }
      return;
    }

    this.setState({
      userLogActions: results.logEntries,
      userLogActionsFiltered: results.logEntries,
      filterActions: '',
      startDate: startDate,
      endDate: endDate,
      loadingActions: null,
      isLoading: false,
      showUserLogModal: true,
    });
  }

  updateActionFilter = _.debounce((f: string) => {
    this.updateActionFilteredList(f);
  }, 300);

  updateActionFilteredList = (filter: string) => {
    let actionList = this.state.userLogActions;
    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);
      actionList = this.state.userLogActions?.filter((a) => {
        const addedOnStr = formatTimezone(
          a.actionDate,
          this.props.user.userTimezone
        );
        return (
          regexStr.test(a.actionMessage) ||
          regexStr.test(a.actionCode) ||
          regexStr.test(addedOnStr)
        );
      });
    }
    this.setState({
      userLogActionsFiltered: actionList || [],
    });
  };

  handleRangeChange = (value: { start: Moment; end: Moment }) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
      isLoading: true,
    });
    // @ts-ignore - actionTool is not defined
    this.getSupportAuditLog(this.state.selectedUser, value.start, value.end);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'filter') {
      this.updateFilter(event.target.value);
      this.setState({
        filter: event.target.value,
      });
    } else if (event.target.id === 'filterActions') {
      this.updateActionFilter(event.target.value);
      this.setState({
        filterActions: event.target.value,
      });
    }
  };

  handleChangeState = async (userID: string, newState: UserState) => {
    // Handle change state of user
    try {
      await updateSupportUserState(userID, newState);
      this.handleChangeSupportUserState(userID, newState);
    } catch (e) {
      if (Object.hasOwn(e, 'error')) {
        if ([401, 403].indexOf(e.error.status) === -1) {
          this.props.handleShowAlert(
            'Error',
            'Could not update support user state: ' + e
          );
        }
        return;
      }
    }
  };

  handleChangeType = async (userID: string, newType: UserType) => {
    this.setState({
      loadingRoles: userID,
    });
    try {
      await updateSupportUserType(userID, newType);
      this.handleChangeSupportUserType(userID, newType);
    } catch (e) {
      if (Object.hasOwn(e, 'error')) {
        if ([401, 403].indexOf(e.error.status) === -1) {
          this.props.handleShowAlert(
            'Error',
            'Could not update support user state: ' + e
          );
        }
        return;
      }
    } finally {
      this.setState({
        loadingRoles: null,
      });
    }
  };

  handleGridSizeChanged(sizeInfo: { api: { sizeColumnsToFit: () => void } }) {
    sizeInfo.api.sizeColumnsToFit();
  }

  // For alerts only
  handleCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  // Handler to cancel all other modals
  handleCancelModal = () => {
    const self = this;
    document.querySelector('.c-modal-slider-75')?.classList.add('closed');
    setTimeout(() => {
      self.setState({
        showAddUserModal: false,
        showUserLogModal: false,
      });
    }, 350);
  };

  handleShowAddUser = () => {
    this.setState({ showAddUserModal: true });
  };

  render() {
    const currentUser = this.props.user.userID;

    const savedColumnSizes = getSavedColumns('manageUsersColumns');

    const windowWidth = this.state.windowWidth;

    const columnDefs: Column[] = [];

    if (this.state.windowWidth > 768) {
      columnDefs.push({
        Header: <Text tid="username" />,
        accessor: 'username',
        id: 'username',
        minWidth: savedColumnSizes.username || 150,
        sortMethod: (a, b) => {
          if (a === this.props.user.userID) {
            return -1;
          } else if (b === this.props.user.userID) {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        },
      });
    }
    let nameCol: Column = {
      Header: <Text tid="name" />,
      accessor: 'name',
      className: 'cell-wrap',
      minWidth: savedColumnSizes.name || 150,
    };
    if (windowWidth < 1200) {
      nameCol.maxWidth = 100;
    }
    columnDefs.push(nameCol);

    let emailCol: Column = {
      Header: <Text tid="email" />,
      accessor: 'email',
      className: 'cell-wrap',
      minWidth: savedColumnSizes.name || 200,
    };

    columnDefs.push(emailCol);

    if (this.state.windowWidth > 450) {
      columnDefs.push({
        Header: <Text tid="role" />,
        id: 'role',
        accessor: (u: SupportUser) => {
          return u.userType;
        },
        minWidth: savedColumnSizes.role || 100,
        Cell: (row: { original: SupportUser }) => {
          return (
            <div className="l-inline-flex">
              {currentUser !== row.original.userID ? (
                this.state.loadingRoles !== row.original.userID ? (
                  <div className="c-select">
                    <select
                      onChange={(e) =>
                        this.handleChangeType(
                          row.original.userID,
                          e.target.value as UserType
                        )
                      }
                      id="change-type"
                      value={row.original.userType}
                    >
                      {['ADMIN', 'MANAGER', 'STANDARD', 'EXTERNAL'].map(
                        (type, i) => {
                          return <option key={i}>{type}</option>;
                        }
                      )}
                    </select>
                  </div>
                ) : (
                  <button className="c-btn-icon">
                    <div className="c-btn__inner">
                      <i className="c-btn__icon fal fa-spinner-third spinning" />
                    </div>
                  </button>
                )
              ) : (
                <div className="c-chip">{row.original.userType}</div>
              )}
            </div>
          );
        },
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
      });
    }

    let stateCol: Column = {
      Header: <Text tid="state" />,
      id: 'state',
      accessor: (u: SupportUser) => {
        return u.userState;
      },
      minWidth: savedColumnSizes.state || 80,
      Cell: (row) => {
        if (row.original.userID === this.props.user.userID) {
          return <div className="c-chip">{row.original.userState}</div>;
        } else if (row.original.userState === 'PENDING') {
          return <div className="c-chip-warning">{row.original.userState}</div>;
        } else {
          return (
            <Switch
              isChecked={row.original.userState === 'ACTIVE'}
              states={{ active: 'ACTIVE', inactive: 'INACTIVE' }}
              handleCheck={(e) =>
                this.handleChangeState(
                  row.original.userID,
                  e ? 'ACTIVE' : 'INACTIVE'
                )
              }
            />
          );
        }
      },
      sortMethod: (a, b) => {
        return a.localeCompare(b);
      },
    };

    if (windowWidth < 1200) {
      stateCol.maxWidth = 80;
    }
    columnDefs.push(stateCol);

    let actionsCol: Column = {
      Header: <Text tid="actions" />,
      accessor: 'userID',
      minWidth: savedColumnSizes.userID || 80,
      maxWidth: 80,
      Cell: (row: { original: any }) => {
        return (
          <div className="l-inline-flex">
            {this.state.loadingActions !== row.original.userID ? (
              <button
                className="c-btn-icon"
                onClick={this.handleLogButton.bind(this, row.original)}
              >
                <div className="c-btn__inner">
                  <span className="c-btn__icon fa fa-book" title="User Log" />
                </div>
              </button>
            ) : (
              <button className="c-btn-icon">
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-spinner-third spinning" />
                </div>
              </button>
            )}
          </div>
        );
      },
    };
    columnDefs.push(actionsCol);

    let supportUsers = this.state.supportUsersFiltered
      ? this.state.supportUsersFiltered.map((user: SupportUser) => {
          return {
            userID: user.userID,
            username: user.userName,
            name: user.firstName + ' ' + user.lastName,
            userType: user.userType,
            userState: user.userState,
            addedOn: user.addedOn,
            addedBy: user.addedBy,
            email: user.email,
          };
        })
      : [];

    if (this.state.isLoadingUsers) return <Loading />;

    const mobileColumns = ['Name', 'Email', 'Role', 'State', 'Actions'];
    const mobileCellRender = columnDefs.map((col) => {
      let accessor = typeof col.accessor === 'string' ? col.accessor : col.id;
      if (col.Cell !== undefined) {
        return { accessor: accessor, func: col.Cell };
      }
      return accessor;
    });

    return (
      <>
        <Header
          context={TextOnly('manageUsers')}
          title={TextOnly('currentUsers')}
        />

        <div className="l-container">
          {this.state.supportUsers ? (
            <>
              <div className="l-flex-wrap">
                <div className="l-flex-wrap">
                  <div className="u-margin-right">
                    <button
                      className="c-btn"
                      onClick={() => {
                        this.setState({
                          showAddUserModal: true,
                        });
                      }}
                    >
                      <div className="c-btn__inner">
                        <i className="c-btn__icon fal fa-plus" />
                        <Text tid="addUsers" />
                      </div>
                    </button>
                  </div>
                </div>
                <div className="c-field u-margin-top-small u-margin-right-small">
                  <label
                    htmlFor="filter"
                    className="c-field__label u-is-vishidden"
                  >
                    <Text tid="filter" />
                  </label>
                  <input
                    type="text"
                    id="filter"
                    maxLength={50}
                    className="c-input u-font-mono"
                    placeholder={TextOnly('filter')}
                    value={this.state.filter}
                    onChange={this.handleChange}
                  />
                  <i className="c-field__input-icon fal fa-search" />
                </div>
              </div>

              <div className="u-margin-top-xlarge">
                <ReactTable
                  columns={columnDefs}
                  data={supportUsers}
                  className="-highlight"
                  previousText={TextOnly('previous')}
                  nextText={TextOnly('next')}
                  pageText={TextOnly('page')}
                  ofText={TextOnly('of')}
                  rowsText={TextOnly('rows')}
                  noDataText={TextOnly('noUsersAssociated')}
                  defaultPageSize={10}
                  // @ts-ignore
                  SubComponent={
                    windowWidth < 1200
                      ? (row) => {
                          return (
                            <div>
                              <div>
                                <span>
                                  <Text tid="name" />:
                                </span>{' '}
                                {row.original.name}
                              </div>
                              <div>
                                <span>
                                  <Text tid="username" />:
                                </span>{' '}
                                {row.original.username}
                              </div>
                              <div>
                                <span>
                                  <Text tid="role" />:
                                </span>{' '}
                                {<Text tid={row.original.userType} />}
                              </div>
                            </div>
                          );
                        }
                      : null
                  }
                  defaultSorted={[
                    {
                      id: 'username',
                      desc: false,
                    },
                  ]}
                  onResizedChange={(a) =>
                    localStorage.setItem(
                      'manageUsersColumns',
                      JSON.stringify(a)
                    )
                  }
                />

                <Table>
                  <Thead>
                    <Tr>
                      {mobileColumns.map((col) => {
                        return <Th key={col}>{col}</Th>;
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {supportUsers.map((item) => {
                      return (
                        <Tr key={item.userID}>
                          {mobileCellRender.map((col: any) => {
                            if (col !== undefined && typeof col !== 'string') {
                              return (
                                <Td key={col}>
                                  {col.func({ original: item })}
                                </Td>
                              );
                            }
                            return (
                              <Td key={col}>
                                {item[col as keyof typeof item]}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            </>
          ) : (
            <i
              className="fal fa-spinner-third spinning c-btn-icon"
              title={TextOnly('loading')}
            />
          )}
        </div>

        {this.state.showAddUserModal && (
          <AddUser
            user={this.props.user}
            supportUsers={this.state.supportUsers}
            windowWidth={this.state.windowWidth}
            baseURL={this.state.baseURL}
            onDismiss={this.handleCancelModal}
            handleShowAlert={this.props.handleShowAlert}
            handleAddUser={this.handleAddUser}
          />
        )}

        <DisplayDialog
          title={
            `Audit Log For ${this.state.selectedUser?.username}` || 'Audit Log'
          }
          isOpen={this.state.showUserLogModal}
          onDismiss={this.handleCancelModal}
        >
          <div className="c-field u-margin-bottom-none">
            <CustomDatePicker
              className="u-margin-bottom-none"
              value={{
                start: this.state.startDate,
                end: this.state.endDate,
                name: 'Last 30 Days',
              }}
              onChange={this.handleRangeChange.bind(this)}
              user={this.props.user}
            />
          </div>

          <GenericTable
            id="MANAGE_USERS-table"
            data={this.state.userLogActions as any[]}
            filterKeys={[
              'userID',
              'actionDate',
              'actionCode',
              'actionMessage',
              'newUserID',
              'queryType',
              'queryValue',
              'shopID',
            ]}
            columnDefs={baseLogColumns.slice(1)}
            defaultSorted={[
              {
                id: 'actionDate',
                desc: true,
              },
            ]}
            savedColumnsId="supportLogTableColumns"
            loadingText={
              <div className="l-container-center l-flex-column">
                <strong>Getting Log Data...</strong>{' '}
                <i
                  className="fal fa-spinner-third spinning c-btn-icon"
                  title={TextOnly('loading')}
                />
              </div>
            }
            loading={this.state.isLoading}
            subComponentRenderer={SupportLogSubcomponent}
            // @ Below may be necessary in the future, if we have support audit log actions
            // @ that do not need to be expanded (i.e., only contain the four core log attributes)

            // getTrProps={(state: any, rowInfo: any, column: any) => {
            //   if (rowInfo) {
            //     if (rowInfo.original.actionCode === 'UPDATE_USER_INFO') {
            //       return {
            //         className: 'hide-expander',
            //       };
            //     }
            //   }
            //   return {};
            // }}
          />
        </DisplayDialog>
      </>
    );
  }
}
