export const en = {
  // Info page
  login: 'Login',
  loggingIn: 'Logging in…',
  aboutUs: 'About Us',
  contactUs: 'Contact Us',
  privacyPolicy: 'Privacy Policy',
  cookiesPolicy: 'Cookies Policy',
  refundCancelPolicy: 'Refund/Cancelation Policy',
  registerNow: 'REGISTER NOW',
  tagLine:
    'Providing an Independent Trust Authority for OEMs, Tools Vendors & Service Shops to securely service modern vehicles',

  // Signup page
  validationSentTo: 'Account Validation link has been sent to',
  loginInstructions:
    'After validating your account you can log in by clicking the Login button on the top right of the page',
  userSignup: 'User Signup',
  username: 'Username',
  usernameHint:
    'Username must be at least 8 alphanumeric lowercase characters starting with a letter',
  firstName: 'First Name',
  firstNameHint:
    'First name cannot be blank and cannot contain special characters',
  lastName: 'Last Name',
  lastNameHint:
    'Last name cannot be blank and cannot contain special characters',
  email: 'Email',
  emailHint: 'Enter a valid email address',
  confirmEmail: 'Confirm Email',
  confirmEmailHint: 'Email and Confirm Email do not match',
  passwordHint:
    'Password must be at least 8 characters with at least one number, one special character, one uppercase letter and one lowercase letter',
  confirmPassword: 'Confirm Password',
  confirmPasswordHint: 'Password and Confirm Password do not match',
  inviteCode: 'Invite Code',
  inviteCodeHint: 'Invite Code cannot be blank',
  needInviteCode: 'Need Invite Code?',
  termsConditions: 'Terms & Conditions',
  acceptTermsConditions: 'Accept Terms & Conditions',
  signup: 'Signup',
  signingUp: 'Signing up...',
  pleaseProveHuman: 'Please prove you are human!',

  // Shop signup page
  userNotActiveMsg:
    'You must verify your email to activate your account either by clicking on the email sent to you or logging in and having a new email sent.',
  serviceCenterIndependentTechSignup:
    'Service Center or Independent Technician Signup',
  serviceCenterName: 'Service Center Name',
  shopNameHint:
    'Shop name cannot be blank and cannot contain special characters',
  primaryContact: 'Primary Contact',
  enterAutoAuthUserCredentials: 'Enter AutoAuth User Credentials',
  enterValidUsernameEmailAddress: 'Enter a valid username or email address',
  passwordDoesNotConform: 'Password does not conform to the password rules',
  enterPaymentMethod: 'Enter Payment Method',
  locale: 'en_US',
  hl: 'en',

  // Login page
  usernameOrEmail: 'Username or Email Address',
  password: 'Password',
  forgotPassword: 'Forgot Password?',
  resendConfirmLink: 'Resend Confirm Link',

  // Navbar
  home: 'Home',
  userProfile: 'User Profile',
  manageTools: 'Manage Tools',
  manageUsers: 'Manage Users',
  reports: 'Reports',
  logout: 'Logout',
  faq: 'FAQ',
  systemInfo: 'System Information',
  support: 'Support',

  // Home page
  welcome: 'Welcome to AutoAuth!',
  yourShops: 'Your Shops Affiliations',
  name: 'Name',
  shopState: 'Shop Status',
  myRole: 'My Role',
  myState: 'My Status',
  page: 'Page',
  of: 'of',
  rows: 'rows',
  previousPage: 'Previous Page',
  nextPage: 'Next Page',
  withdrawalFromShop: 'Withdrawal from Shop',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  USER: 'USER',
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  youHaveBeenInvited: 'You have been invited to become a USER for',
  NOTICE: 'NOTICE',
  PENDING: 'PENDING',
  toAccept: 'To accept',
  toReject: 'To reject',

  // User Profile Page
  profile: 'Profile',
  shopInfo: 'Shop Info',
  paymentHistory: 'Payment History',
  submittedForSettlement: 'Submitted for Settlement',
  settled: 'Settled',
  failedCharge: 'Failed Charge',
  errorSendingVerifyCode: 'Error sending Verification Code',
  emailAddressVerified: 'Email Address has been verified',
  invalidVerifyCode: 'Invalid Verification Code',
  profileUpdated: 'Profile Updated',
  paymentMethodRemoved: 'Payment Method Removed',
  paymentMethodUpdated: 'Payment Method Updated',
  passwordUpdated: 'Password Updated',
  oldPasswordNotCorrect: 'Old password is not correct',
  shopUpdated: 'Shop Updated',
  userCapacityUpgrade: 'User Capacity Upgrade (prorated)',
  yearlySubscriptionRenewal: 'Yearly Subscription Renewal',
  initialSubscriptionCharge: 'Initial Yearly Subscription Charge',
  viewReceipt: 'View Receipt',
  shopAccountSuspended:
    'Shop Account has been SUSPENDED.  Please update payment information to resume unlocking and servicing vehicles.',
  shopAccountPastDue:
    'Shop Account is PAST DUE.  Please update payment information to ensure no disruption in service.',
  emailAddress: 'Email Address',
  validateEmail: 'Validate Email',
  emailSentMsg:
    'An email has been sent to your new email address. Copy the code found the email into the field below.',
  confirmationCode: 'Confirmation Code',
  submitConfirmationCode: 'Submit Confirmation Code',
  update: 'Update',
  updating: 'Updating…',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  verifyNewPassword: 'Verify New Password',
  changePassword: 'Change Password',
  changing: 'Changing…',
  shop: 'Shop',
  shopName: 'Shop Name',
  changeShopName: 'Change Shop Name',
  savingName: 'Saving name…',
  maximumShopUsers: 'Maximum Shop Users',
  year: 'year',
  increaseMaxUsers: 'Increase Max Users',
  increasingUsers: 'Increasing Users…',
  subscriptionInfo: 'Subscription Info',
  on: 'on',
  nextPayment: 'Next Payment',
  savedPaymentInfo: 'Saved Payment Info',
  paidThru: 'Paid Thru',
  exp: 'Exp',
  removePayment: 'Remove Payment',
  removingPayment: 'Removing Payment…',
  noPaymentMethodAvailable: 'No Payment Method Available.',
  yourSubscriptionEnded: 'Your Subscription ended on',
  yourSubscriptionWillEnd: 'Your Subscription will end on',
  changePaymentInfo: 'Change Payment Info',
  changePayment: 'Change Payment',
  addPayment: 'Add Payment',
  savingPayment: 'Saving Payment…',
  history: 'History',
  date: 'Date',
  amount: 'Amount',
  chargeDescription: 'Charge Description',
  receipt: 'Receipt',
  confirmAddUsers: 'Confirm Add User(s) to Shop Capacity',
  areYouSureYouWantToAdd: 'Are you sure you want to add',
  usersToShopCapacity: 'user(s) to the Shop Capacity',
  youWillBeCharged: 'You will be charged',
  proratedPaymentExplain:
    'prorated for the amount of time left in your subscription. Then the full amount each additional year the subscription is active.',
  confirm: 'Confirm',
  cancel: 'Cancel',
  usersAddedToShop: 'User(s) Added to Shop Capacity',
  youHaveBeenCharged: 'You have been charged',
  today: 'today',
  Today: 'Today',
  dismiss: 'Dismiss',
  autoAuthReceipt: 'AutoAuth Receipt',
  description: 'Description',
  transactionDate: 'Transaction Date',
  taxAmount: 'Tax Amount',
  taxExempt: 'Tax Exempt',
  yes: 'yes',
  orderId: 'Order ID',
  status: 'Status',
  paymentType: 'Payment Type',
  creditCardLast4: 'Credit Card Last 4',
  customerInfo: 'Customer Information',
  company: 'Company',
  owner: 'Owner',
  print: 'Print',

  // Manage Tools Page
  unknown: 'Unknown',
  toolOwnedByYourShop: 'This tool is already owned by your shop',
  toolAdded: 'Tool Added',
  toolsAdded: 'Tools Added',
  toolAssignedToShop: 'Tool is already assigned to a shop',
  toolsFailed: 'The following tools failed',
  manufacturer: 'Manufacturer',
  model: 'Model',
  serialNo: 'Serial No.',
  addedOn: 'Added On',
  addedBy: 'Added By',
  actions: 'Actions',
  actionCode: 'Action Code',
  viewToolActions: 'View Tool Actions',
  vin: 'VIN',
  performedBy: 'Performed By',
  uploadFormat:
    'Drag File or Click and Browse to your File. File must be text file containing a single Serial Number on each line.',
  shopTools: 'Shop Tools',
  filter: 'Filter',
  noToolsThisShop: 'No Tools Associated with Shop',
  toolActions: 'Tool Actions',
  actionsPerformedBy: 'Actions performed by',
  from: 'from',
  addTool: 'Add Tool',
  pickToolType: 'Pick the Type of Tool',
  serialNumber: 'Serial Number',
  serialNumberHint:
    "Serial Number cannot be blank and can only contain alphanumberic plus '-_/.' characters",
  add: 'Add',
  addingTool: 'Adding tool…',
  addingTools: 'Adding tools…',
  confirmToolFromShop: 'Confirm Remove Tool from Shop',
  areYouSureYouRemoveTool: 'Are you sure you want to remove this tool?',
  noneSelected: 'None Selected',
  toolModel: 'Tool Model',
  uploadFileOfSerialNo: 'Upload File of Serial Numbers',

  // Manage Users Page
  couldntFindUsername: "Couldn't find user with username",
  userAssociatedWithShop: 'This user is already associated with this shop',
  requestHasBeenSent: "Request has been sent.  User status is 'Pending'",
  usersWereInvited: 'user(s) were invited.',
  userInviteFailed: 'user(s) invite failed',
  clickDownloadFailed: 'Click to download list of failed',
  role: 'Role',
  state: 'State',
  changeRoleToAdmin: 'Change Role to Admin',
  changeRoleToUser: 'Change Role to User',
  changeToAdmin: 'Change to Admin',
  changeToUser: 'Change to User',
  removeFromShop: 'Remove from Shop',
  suspendUser: 'Suspend User',
  reactivateUser: 'Reactivate User',
  viewUserActions: 'View User Actions',
  removeFromList: 'Remove from List',
  usersOf: 'Users of',
  maxShopUsers: 'Maximum Shop Users',
  userBulkUploadFormat:
    "Drag File or Click and Browse to your File. File must be csv file containing a 'firstName', 'lastName', 'userRole' and 'email' columns.",
  dateAdded: 'Date Added',
  userActions: 'User Actions',
  addUser: 'Add User',
  enterUsernameToAdd: 'Enter Username of User to Add',
  maximumShopUsersReached: 'Maximum Shop Users Reached',
  searchForUser: 'Search for User',
  searchingUser: 'Searching User…',
  addUserCapcityHere: 'Add User Capacity Here',
  sendRequestToUser: 'Send Request to User',
  sendingRequest: 'Sending Request…',
  bulkImport: 'Bulk Import',
  uploadFileUsersToInvite: 'Upload File of Users to Invite to Join',
  downloadTemplateCSVFile: 'Download Template CSV file',
  downloadLastBulkInviteErrors: 'Download Last Bulk Invite Errors',
  clickToDownload: 'Click to download',
  noUsersAssociated: 'No Users Associated with Shop',
  addingUsers: 'Adding Users…',
  confirmRemoveUserShop: 'Confirm Remove User from Shop',
  areYouSureRemove: 'Are you sure you want to remove this user?',

  // Error Messages
  invalidUserRole: 'Invalid user role',
  missingParams: 'Missing parameters!',
  missingShopInfoParams: 'Missing shop info parameters!',
  missingUserInfoParams: 'Missing user info parameters!',
  ownerCannotRemoveSelfFromShop:
    'Shop owner can not remove themselves from a shop',
  onlyOwnerAdminCanRemoveUser:
    'Only a OWNER or ADMIN can remove another user from a shop',
  invalidResponse: 'Invalid response',
  userCannotModifyRoleState:
    'User can not modify their role and state at a shop',
  invalidShopUserState: 'Invalid shop user state',
  invalidShopUserRole: 'Invalid shop user role',
  userAccountNotActive: 'User account is not ACTIVE',
  shopNotActive: 'The shop is not ACTIVE',
  userNotAffiliatedWithShop: 'The user is not affiliated with this shop',
  couldNotGetShopUserCounts:
    'Could not retrieve shop user counts for this shop!',
  couldNotGetShopToolCount: 'Could not retrieve shop tool count for this shop!',
  usernameNotFound: 'Username not found!',
  unexpectedUserInfoError: 'Unexpected userInfo error',
  cannotUpdateAnotherUserInfo: 'Can not update another users information!',
  cannotUpdateUserNotActive: 'Can not update a user that is not ACTIVE!',
  emailAlreadyInUse: 'The provided email address is already in use!',
  invalidUserState: 'Invalid user state!',
  invalidToolModelForManufacturer: 'Invalid tool model for this manufacturer!',
  invalidToolManufacturer: 'Invalid tool manufacturer!',
  requestRefused: 'Request Refused',
  usernameAlreadyInUse: 'The provided username is already in use!',
  userEmailInvalid: 'User email has an invalid format!',
  invalidParams: 'Wrong format for one or more Parameters',

  paymentRefund: 'Payment Refund',
  shopUsers: 'Shop Users',
  shopSuspendedMessage:
    'Shop Account has been SUSPENDED or CANCELED.  Please update payment information to resume unlocking and servicing vehicles.',
  nextPaymentOf: 'Next Payment of',
  toBeChargedOn: 'to be charged on',
  reinstateShop: 'Reinstate Shop',
  link: 'Link',
  cancelSubscription: 'Cancel Subscription',
  transactionInfo: 'Transaction Information',
  browser: 'Browser',
  hostOS: 'Host OS',
  uploadSerialNoFileInstructions:
    'Drag File or Click and Browse to your File. File must be text file containing a single Serial Number on each line.',
  manageSignupLinks: 'Manage Signup Links',
  edit: 'Edit',
  remove: 'Remove',
  signupLinks: 'Signup Links',
  addSignupLink: 'Add Signup Link',
  editSignupLink: 'Edit Signup Link',
  validityCriteria: 'Validity Criteria',
  additionalFieldsToCollect: 'Additional Fields to Collect',
  noSignupLinks: 'No Signup Links',
  linkName: 'Link Name',
  standard: 'STANDARD',
  time: 'TIME',
  addCustomField: 'Add Custom Field',
  fieldName: 'Field Name',
  customFieldsFromUsers: 'Custom Fields to Collect from Users',
  fieldNameCannotBeBlank: 'Field Name cannot be blank',
  removeCustomField: 'Remove Custom Field',
  startEndTime: 'Start/End Time',
  yesterday: 'Yesterday',
  last30Days: 'Last 30 Days',
  thisMonth: 'This Month',
  lastMonth: 'Last Month',
  shopActions: 'Shop Actions',
  search: 'Search',
  searching: 'Searching...',
  pickUser: 'Pick User',
  pickTool: 'Pick Tool',

  noShopActions: 'No Shop Actions',
  noUserActions: 'No User Actions',
  noToolActions: 'No Tool Actions',
  additionalFields: 'Additional Fields',
  language: 'Language',
  selectLanguage: 'Select a language…',
  incorrectUserAndPass: 'Incorrect username or password',

  confirmCancelSubscription: 'Confirm Cancel AutoAuth Subscription',
  confirmCancelSubscriptionMessage:
    'Are you sure you want to cancel your AutoAuth subscription? Doing so you will still be able to securely unlock vehicles and perform Authenticated Diagnostics until your current year subscription expires. However, after that date you will no longer be able to do so.',
  confirmRemoveUserSignupLinkFromShop:
    'Confirm Remove User Signup Link From Shop',
  confirmRemovalFromShop: 'Confirm Removal From Shop',
  areYouSureYouWantToRemoveYourselfFrom:
    'Are you sure you want to remove yourself from',
  problemAuthenticating: 'There was a problem authenticating',
  clickHere: 'Click Here',
  toRegisterOwnShop: 'to register your own shop',
  yourUserStateIs: 'Your user state is',
  mustValidateEmailMessage:
    'You must validate the email address <email> before using this portal or change your email address in the User Profile tab.',
  emailValidationSentTo: 'Email Address Validation code has been sent to:',
  enterCodeToConfirmEmail: 'Enter code to confirm email.',
  submitConfirmCode: 'Submit Confirmation Code',
  shopDoesNotHaveFormOfPayment: 'Your shop does not have a form of payment.',
  mustAddPaymentShopExpired:
    'You must add a payment method to ensure no disruption of service. Your shop subscription has expired on <date>',
  mustAddPaymentShopWillExpire:
    'You must add a payment method to ensure no disruption of service. Your shop subscription will expire on <date>',
  addPaymentMethodHere: '(Add Payment Method Here)',
  click: 'Click',
  toRegisterTools:
    'to register tools for your shop so you can start servicing vehicles.',
  userStatePending: 'Your user state is Pending.',
  affiliateWithShopToUseToolsMessage:
    'Now that you have become an AutoAuth user your next step is to become affiliated with a Service Center Account to start using AutoAuth Certified Diagnostic tools.',
  serviceCenterSignupMessage:
    'If you are a Service Center owner/manager or you are an Independent Technician with your own AutoAuth Certified Diagnostic tools, click the link below to register yourself or your Service Center.',
  serviceCenterSignupLink:
    'Service Center Signup/Independent Technician Signup',
  employeeSignupMessage:
    'If you are an employee of a Service Center that is already registered in the AutoAuth system, inform your Service Center owner/manager of your user account username (found on the <userProfile> page). With your username, your Service Center owner/manager can invite you to join the Service Center. Once you are invited you will see a link on this page to join the Service Center.',
  employeeSignupMessage2:
    'Once these steps are completed you will be ready to start using all AutoAuth Certified Diagnostic tools registered in the Service Center with which you are associated.',
  paymentInfo: 'Payment Information',

  addingDirectory: 'Adding directory…',
  updatingDirectory: 'Updating directory…',
  directoryName: 'Directory Name',
  directoryId: 'Directory ID',
  directoryType: 'Directory Type',
  directoryState: 'Directory State',
  tokenIssuerName: 'Token Issuer Name',
  type: 'Type',
  clientId: 'Client ID',
  clientSecret: 'Client Secret',
  serverUrl: 'Server URL',
  adfsDomain: 'ADFS Domain',
  adfsAdditionalFields: 'ADFS Additional Fields',
  redirectUrl: 'Redirect URL',
  authorizationPath: 'Authorization Path',
  tokenPath: 'Token Path',
  caCert: 'CA Certificate',
  textInput: 'Text Input',
  fileUpload: 'File Upload',
  file: 'File: ',

  manageDirectoryServices: 'Manage Directory Services',
  directoryServices: 'Directory Services',
  addDirectory: 'Add Directory',
  noDirectoryServices: 'No Directory Services',
  noRowsFound: 'No Rows Found',
  selectAUser: 'Select a user…',
  selectATool: 'Select a tool…',
  streetAddress1: 'Street Address 1',
  streetAddress2: 'Street Address 2',
  city: 'City',
  zipCode: 'Zip Code',
  phoneNumber: 'Phone Number',

  addingLink: 'Adding link…',
  requestInviteCode: 'Request Invite Code',
  requestingCode: 'Requesting code…',
  sendingLink: 'Sending link…',
  accountValidationLinkSent:
    'Account Validation link has been sent to the email address associated with:',
  pleaseCheckEmailForCode: 'Please check your email for the code.',
  verify: 'Verify',
  verifying: 'Verifying…',
  confirmNewPassword: 'Confirm New Password',
  resetPassword: 'Reset Password',
  usernameEmailNotFound: 'Username or Email Address not found',
  passwordHasBeenReset: 'Your password has been reset',
  requestReset: 'Request Reset',
  toolVendorSignup: 'Tool Vendor Signup',
  info: 'Info',
  custom: 'Custom',
  addServiceCenter: 'Add Service Center',
  user: 'User',
  confirmRemoveDirectoryFromShop: 'Confirm Remove Directory from Shop',
  areYouSureRemoveDirectory: 'Are you sure you want to remove this directory? ',
  canceling: 'Canceling…',
  customRange: 'Custom Range',
  apply: 'Apply',
  signupLinkAdded: 'Signup Link Added!',
  userChangedUserStateAtShop: 'User changed a user’s state at a shop',
  alreadyRegistered: 'Already registered?',
  enterNewPassword: 'Enter New Password',
  loginNow: 'LOGIN NOW',
  signupLink: 'Signup Link',
  LINK: 'LINK',
  userNotConfirmed:
    'User is not confirmed.  Please check your email for your account validation link.',
  pageNotFound: 'Sorry, page not found!',
  stepsForAARegistration: 'Steps for AutoAuth Registration',
  requestYourInviteCode: 'Request your Invite Code',
  useCodeToCreateAccount:
    'Use the Code received via email to create your User Account',
  createServiceCenterAccount:
    'If you own a repair shop or just own diagnostic tools, create a Service Center Account to register your tools ($50 annual fee paid upon registration)',
  loginToRegisterTools:
    'Log in to register your tools to begin unlocking vehicles',
  everyoneShouldFirstCreateAccount:
    'Everyone should first create a User Account',
  createServiceCenterAccountAndLogin:
    'Then if you own a repair shop or just own diagnostic tools, create a Service Center Account to register your tools ($50 annual fee paid upon registration). Log in to register your tools to begin unlocking vehicles',
  inviteCodeSubmitted:
    'Your Invite Code Request has been submitted.  You will receive an email with your invite code when approved.',
  goToLogin: 'Go To Login Page',
  fieldCannotBeBlank: 'Field cannot be blank',
  here: 'here',
  toGetStarted: 'to get started',
  toCompleteEmailAddressChange:
    'To complete your email address change, please enter the verification code we just sent to the new address.',
  shopSubscriptionCanceled: 'Shop Subscription has been canceled',

  directoryAdded: 'Directory Added!',
  directoryUpdated: 'Directory Updated!',
  signupLinkUpdated: 'Signup Link Updated!',
  success: 'Success',
  error: 'Error',
  usernameClientIdNotFound: 'Username/client id combination not found.',
  close: 'Close',
  directoryIdHint:
    'ID must be less than 30 characters and contain only letters and numbers.',
  tenantId: 'Tenant ID',

  // Months and Days
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  Sun: 'Sun',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',

  // v6
  currentTools: 'Current Tools',
  removedTools: 'Removed Tools',
  previousImports: 'Previous Imports',
  currentUsers: 'Current Users',
  removedUsers: 'Removed Users',
  editDirectory: 'Edit Directory',
  needAnAccount: 'Need an account?',
  register: 'register',
  shopProfile: 'Shop Profile',
  myProfile: 'My Profile',
  userLogin: 'User Login',
  cityTown: 'City/Town',
  country: 'Country',
  saveChanges: 'Save Changes',
  savingChanges: 'Saving Changes',
  about: 'About',
  contact: 'Contact',
  loginSteps: 'Your registration has been confirmed. Login now',
  forServiceCenters: 'For Service Centers & Independent Technicians',
  forTechnicians: 'For Technicians',
  addTools: 'Add Tools',
  addUsers: 'Add Users',
  bulkAddUsers: 'Bulk Add Users',
  currentPlan: 'Current Plan',
  subscription: 'Subscription',
  loginError: 'Login Error',
  getStarted: 'Get Started',
  shopAffiliations: 'Shop Affiliations',
  clickAccept: 'Accept',
  clickReject: 'Reject',
  loading: 'Loading',
  toolDeletedConfirmation: 'Tool has been removed.',
  removedUser: 'Removed User',
  location: 'Location',
  value: 'Value',
  viewPaymentHistory: 'View Payment History',
  userInformation: 'User Information',
  noUserOrToolActions: 'No User or Tool Actions',
  toolInformation: 'Tool Information',
  toolAction: 'Tool Action',
  tech: 'Tech',
  toolSerial: 'Tool Serial Number',
  toolManufacturer: 'Tool Manufacturer',
  upgradeToStandardPlusDescription:
    'Upgrade now and get Stats, Trends and Reports for your shop that will give you valuable insights into your business.',
  upgradeToStandardPlus: 'Upgrade To Standard Plus',
  upgradeNow: 'Upgrade Now',
  comparePlans: 'Compare Plans',
  upgrade: 'Upgrade',
  bulkAddTools: 'Bulk Add Tools',
  bulkToolImport: 'Bulk Tool Import',
  bulkTechnicianImport: 'Bulk Technician Import',
  unlimited: 'Unlimited*',
  authToolIndependentofUser: 'Auth. Tool, Independent of User',
  shopHistoryReports: 'Shop History Reports',
  technicianHistoryReports: 'Technician History Reports',
  toolHistoryReports: 'Tool History Reports',
  addlUsers: 'Add’l Users',
  extraToolForEachAddlUser: 'Extra tool for each add’l user',
  maxUsers: 'Max Users -',
  maxTools: 'Max Tools -',
  registeredTool: 'Registered Tool',
  standardCurrentPlan: 'Standard (Current Plan)',
  selectedPlan: 'Selected Plan',
  creditCardOnly: 'Credit Card Only',
  registrationFee: 'Registration Fee',
  chooseStandard: 'Choose Standard',
  chooseStandardPlus: 'Choose Standard Plus',
  creditCardOrPurchaseOrder: 'Credit Card or Purchase Order',
  upgradeForStatsAndTrends: 'Upgrade now and get access to your Stats & Trends',
  givesYouValuableInsights:
    'This gives you valuable insights into your business, allowing you to see',
  toolAndUserTotalsAtAGlance: 'your tool and user totals at a glance',
  totalUnlocksOverTime: 'total unlocks over time',
  vehiclesComingIntoYourShopInAGivenTimeFrame:
    'which vehicles are coming into your shop in a given time frame',
  whichTechAreDoingMostAndLeastUnlocks:
    'which Techs are doing the most the least unlocks',
  whichToolsAreBeingUsedTheMost: 'which tools are being used the most',
  whichToolsAreHavingTheMostErrors: 'which tools are having the most errors',
  upgradeNowAndGetAccessToShopReport:
    'Upgrade now and get access to your Shop Report.',
  shopReportIsSearchableAndFilterable:
    'The Shop Report is a searchable and filterable history log of ALL administrative actions in your shop.',
  youCanFindAnyAction: 'You can find any action to see who, when, and what.',
  seeWhichUsersHaveRespondedToAnInvite:
    'See which users have responded to an invite.',
  upgradeNowAndGetAccessToToolReport:
    'Upgrade now and get access to your Tool Report.',
  toolReportIsSearchableAndFilterable:
    'The Tool Report is a searchable and filterable log of ALL tool actions in your shop.',
  seeWhichToolsAndTechsAreHavingErrors:
    'See which tools and techs are having errors.',
  seeTheVehiclesAndCodesRead: 'See the vehicles and the codes read.',
  seeWhichTechsAreUsingToolsAfterHours:
    'See which Techs are using your tools after hours.',
  userDetails: 'User Details',
  goToShopProfile: 'Go To Shop Profile',
  selectAAction: 'Select an action',
  pickAction: 'Pick Action',
  resetFilters: 'Reset Filters',
  applyFilters: 'Apply Filters',
  thisWeek: 'This Week',
  lastWeek: 'Last Week',
  copiedLink: 'Copied Link',
  upgradeToEnterpriseToView: 'Upgrade Now to Enterprise Pro to view that page',
  pickVin: 'Pick Vin',
  selectAVin: 'Select a vin',
  upgrading: 'Upgrading...',
  autoauthStandard: 'AutoAuth Standard',
  autoauthStandardPlus: 'AutoAuth Standard Plus',
  autoauthEnterprisePro: 'AutoAuth Enterprise Pro',
  toolsPerShop: 'Tools Per Shop',
  usersPerShop: 'Users Per Shop',
  adminsPerShop: 'Admins Per Shop',
  shopOrGroupRegistration: 'Shop or Group Registration',
  onlineToolRegistration: 'Online Tool Registration',
  onlineTechnicianRegistration: 'Online Technician Registration',
  unlimitedUsers: 'Unlimited*',
  unlimitedTools: 'Unlimited*',
  wantToUpgradeToPlus:
    'Are you sure you want to upgrade to AutoAuth Standard Plus?',
  willBeChargedUpgradingToPlus:
    'You will be charged $<amount>/year prorated for the amount of time left in your subscription. Then the full amount each additional year the subscription is active.',
  action: 'action',
  selectARole: 'Select a role…',
  TERMINATED: 'TERMINATED',
  PAST_DUE: 'PAST_DUE',
  leaveShop: 'Leave Shop',
  upgradeToStandardPlusDescriptionAlt:
    'Standard Plus will give you, the Owner, a log of all your past shop actions.',
  techUpgradeToStandardPlus: 'Ask your Shop Owner to upgrade to Standard Plus',
  techUpgradeToStandardPlusDescription:
    'Standard Plus will give you, the Technician, a log of all your past tool actions.',
  addToolsToYourShop:
    'Add tools to your shop so you can start servicing vehicles.',
  thisYear: 'This Year',
  lastYear: 'Last Year',
  acceptedInviteSuccess: 'Successfully accepted shop invite',
  rejectedInviteSuccess: 'Rejected shop invite',
  addAnExistingUser:
    'Add an existing AutoAuth user to your shop by using their exact username to search for them.',
  ifYouWantToAddUser:
    'If you want to add a user who doesnt have a free AutoAuth account yet, you can send them to this link to sign up:',
  enterUsername: 'Enter Username',
  noPaymentMethodForIncreasingUsers:
    'Shop Account has no payment method. Please add a payment method to increase number of users for your shop.',
  next: 'Next',
  previous: 'Previous',
  viewReports: 'View Reports',
  statsAndTrends: 'Stats & Trends',
  filters: 'Filters',
  technician: 'Technician',
  selectAnAction: 'Select an Action',
  myStatus: 'My Status',
  receivedToolError: 'Received Tool Error',
  addShopUsers: 'Add Shop Users',
  nextPaymentAmount: 'Next Payment Amount',
  signupConfirm: 'An Account Validation link has been sent to <email>',
  backToRegister: 'Back To Register',
  toolSerialNumber: 'Tool Serial Number',
  myToolActivity: 'My Tool Activity',
  myShopActivity: 'My Shop Activity',
  afterValidatingLogin: 'After validating your account, you can log in.',
  noDataYet: 'No Data Yet',
  reachedMaxUsers:
    'You have used <userCount> of the maximum <maxUserCount> users available. <contactLink> for more information.',
  dontHavePermission: 'You do not have permission to access this page',
  singleUser: 'Single User',
  bulkUsers: 'Bulk Users',
  numUsersInvited: '<number> users invited',
  addToolsAndUsers:
    'Add tools and users to your shop so you can start servicing vehicles.',
  clearAllAndReupload: 'Clear All and Reupload',
  plus: 'plus',
  toolLimitMessage:
    'Only up to 400 tools can be imported at one time. Please upload a file with fewer tools.',
  errorAddingTool: 'Error adding tool to shop',
  errorAddingTools: 'Error adding tools to shop',
  chargebackDisputed:
    'Your most recent payment was canceled or questioned by the credit card holder. To avoid loss of functionality you must correct this issue. The quickest way to resolve this and ensure no interruption of service is for the cardholder to contact the bank and let them know the charge was valid. If you have any questions, please contact us at <link>',
  comingSoon: 'Coming Soon',
  INVITED: 'INVITED',
  myActivity: 'My Activity',
  last7Days: 'Last 7 Days',
  validity: 'Validity',
  createdBy: 'Created By',
  createdOn: 'Created On <date>',
  copyLink: 'Copy Link',
  users: 'Users Per Shop',
  VALID: 'VALID',
  autoAuthRepresents:
    'AutoAuth represents a family of authentication services, reports, and capabilities that allow our Independent Service Shops, using their existing Scan Tools, to access and unlock vehicles that are protected by a secure gateway.',
  autoAuthStandardOption:
    '<AutoAuthStandard> (our budget entry-level authentication service) is targeted at individual service shops and is strictly licensed by service shop street address. Payment is provided with an annual service subscription via credit card.',
  autoAuthPlusOption:
    '<AutoAuthStandardPlus> provides the same authentication and unlock services as AutoAuth Standard, with the addition of a wide variety of insightful reports and dashboards.',
  autoAuthPlusOptionLicensing: 'Licensing is by shop street address.',
  autoAuthEnterpriseOption:
    '<AutoAuthEnterprise> is our premium authentication service and includes all the capabilities and reports of AutoAuth Standard Plus while additionally integrating directly into a customers Identity Management System for central account control making it the ideal solution for Enterprise Shops with more than 500 shops or 1000 tools. Payment for AutoAuth Enterprise is via Purchase Order.',
  plusUpgrade: 'Shop Upgrade to Plus (prorated)',
  dashboardControls: 'Dashboard Controls',
  howToResolveChargeback:
    'The quickest way to resolve this and ensure no interruption of service is for the cardholder to contact the bank and let them know the charge was valid. If you have any questions, please contact us.',
  removing: 'Removing...',
  accept: 'Accept',
  recommended: 'Recommended',
  toolIsRegistered: 'Tool is Registered?',
  toolsOfMax: '<tools> of <maxTools> Maximum Shop Tools',
  unableToFindAddress: 'Unable to find address. Please check and try again.',

  // Audit Log Actions
  userCreatedTheirAcc: 'User created their account',
  userConfirmedTheirAcc: 'User confirmed their account',
  userImportedNewUsers: 'User imported a set of new users',
  userBulkInvitedUsers:
    'User performed a bulk invite for a set of users to join a shop',
  userRevokedInvite: 'User revoked an invite for a user to join a shop',
  userUpdatedInfo: 'User updated their info',
  userCreatedShop: 'User created a shop',
  userUpdatedShop: 'User updated a shops info',
  userUpdatedPaymentInfo: 'User updated a shops payment info',
  userRemovedPaymentInfo: 'User removed a shops payment info',
  userUpdatedMaxCap: 'User updated a shops max user capacity',
  userAddedUser: 'User added a user to a shop',
  userRemovedUser: 'User removed a user from a shop',
  userRespondedToInvite: 'User responded to a shop invitation',
  userChangedUserState: 'User changed a users state at a shop',
  userRegisteredTool: 'User registered a tool for a shop',
  userRemovedTool: 'User removed a tool from a shop',
  userImportedTools: 'User imported a set of tools into a shop',
  userLoggedInTool: 'User logged in via a tool',
  userADInitiateTool: 'User performed an ADInitiate via a tool',
  userADRequestTool: 'User performed an ADRequest via a tool',
  userADExecuteTool: 'User performed an ADExecute via a tool',
  userCreatedSignupLink: 'User created a signup link for users to join shop',
  userUpdatedSignupLink: 'User updated a signup link',
  userErrorADInitiateTool: 'User got an error on ADInitiate via a tool',
  userErrorADRequestTool: 'User got an error on ADRequest via a tool',
  userErrorADExecuteTool: 'User got an error on ADExecute via a tool',
  shopSubscriptionStateChanged:
    'Shop subscription state was changed by an automated process',

  // Stats & Trends
  userTotalOfMax: 'Total utilisateurs de <amount> Max',
  toolTotalOfMax: 'Total outils de <amount> Max',
  vehicleUnlocksMost: 'Déverrouillages de véhicules - Max',
  toolTotalsVendorModel: 'Totaux outils par fournisseur [Model]',
  toolUnlocksByTechMost: 'Déverrouillages doutils par technicien - Max',
  toolUnlocksByToolMost: 'Déverrouillages doutils par outil - Max',
  toolUnlocksForDay: 'Déverrouillages doutils pour la journée',
  toolUnlocksByDay: 'Déverrouillages doutils par jour',
  toolUnlocksByMonth: 'Déverrouillages doutils par mois',
  forTheDayOf: 'Pour la journée du <date>',
  forTheMonthOf: 'Pour le mois de <mois>',
  forTheYearOf: 'Pour lannée <année>',

  // PDF Receipts **must match translations in auto-auth-client**
  finalDetailsForOrderId: 'Final Details For Order #<id>',
  printPageForYourRecords: 'Print this page for your records',
  orderPlaced: 'Order Placed',
  autoAuthOrderNumber: 'AutoAuth.com Order Number',
  orderTotal: 'Order Total',
  transactionInfo: 'Transaction Information',
  description: 'Description',
  rate: 'Rate',
  amount: 'Amount',
  subscription: 'Subscription',
  addedOem: 'Added Manufacturer',
  addedUser: 'Added User/Tool',
  oemUpchargeExplain: 'Added Manufacturer rate is $<base> base price plus $<upcharge> per added user/tool above base capacity.',
  userUpchargeExplain: 'Added User/Tool rate is $<base> base price plus $<upcharge> per added manufacturer above base capacity.',
  renewalUpchargeExplain: 'Added User/Tool price is $<base> base price plus $<upcharge> per added manufacturer above base capacity.',
  customerInfo: 'Customer Information',
  company: 'Company',
  owner: 'Owner',
  username: 'Username',
  email: 'Email',
  paymentInfo: 'Payment Information',
  paymentMethod: 'Payment Method',
  lastDigits: 'Last Digits',
  billingAddress: 'Billing Address',
  subtotal: 'Subtotal',
  taxToCollect: 'Tax to be collected',
  grandTotal: 'Grand total',
  upgradeOEMRegionProration: 'Manufacturers/Regions Upgrade (prorated)',
  downgradeOEMRegionProrationCredit: 'Manufacturers/Regions Downgrade (prorated)',
  disputedPaymentRepay: 'Disputed Charge Repayment',
  addedTechCert: 'Added Tech Certification',
};
