import React from 'react';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { clearRecentSearches, getRecentSearches } from '../libs/utils-ts';

interface FieldWithHistoryProps {
  fieldId?: string;
  searchKey: string;
  selected: string;
  placeholder?: string;

  onInputChange: (input: string, id: string) => void;
  onChange: (input: string, id: string) => void;
  onEnter?: () => void;
  tab?: boolean;
}

const FieldWithHistory = ({
  fieldId,
  searchKey,
  selected,
  placeholder,
  onInputChange,
  onChange,
  onEnter,
  tab,
}: FieldWithHistoryProps) => {
  let typeaheadRef = React.createRef<any>();

  const [isOpen, setIsOpen] = React.useState<undefined | boolean>(undefined);
  const [searchHistory, setSearchHistory] = React.useState(
    getRecentSearches(searchKey) || []
  );

  return (
    <Typeahead
      id={fieldId}
      ref={typeaheadRef}
      options={searchHistory || []}
      selected={[selected]}
      className="c-input u-font-mono"
      placeholder={placeholder}
      onInputChange={(input, e) => {
        setSearchHistory(getRecentSearches(searchKey));
        onInputChange(input, fieldId as string);
      }}
      onChange={(e) => {
        setSearchHistory(getRecentSearches(searchKey));
        onChange(e[0], fieldId as string);
      }}
      onFocus={() => {
        setSearchHistory(getRecentSearches(searchKey));
        setIsOpen(undefined);
      }}
      open={isOpen}
      onKeyDown={(e: any) => {
        setIsOpen(undefined);
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          if (selected === '') {
            if (searchHistory.length !== 0) {
              onInputChange(searchHistory[0], fieldId as string);
              setIsOpen(false);
            }
            return;
          }
          if (selected !== '') {
            if (isOpen && searchHistory.length !== 0) {
              setIsOpen(false);
              return;
            }
            if (onEnter) onEnter();
          }
        } else if (e.key === 'Tab') {
          if (tab === true) return;
          e.preventDefault();
          e.stopPropagation();
          onInputChange(searchHistory[0], fieldId as string);
          setIsOpen(false);
        }
      }}
      positionFixed={true}
      renderMenu={(results, menuProps, state) => {
        if (results.length === 0 || results[0] === '') return null;
        return (
          <Menu {...menuProps}>
            <Menu.Header>
              Recent Searches{' '}
              <button
                className="c-btn-small c-btn-error-brand"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  clearRecentSearches(searchKey);
                  setSearchHistory(getRecentSearches(searchKey));
                }}
              >
                Clear History
              </button>
            </Menu.Header>
            {results.map((item, index) => (
              <MenuItem key={item} option={item} position={index}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        );
      }}
    />
  );
};

export default FieldWithHistory;
