import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

type ScrollToTopProps = {
  children: JSX.Element;
};

export const ScrollToTop: React.FunctionComponent<ScrollToTopProps> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};
