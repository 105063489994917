export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_SHOW_VEHICLE_INFO = 'SET_SHOW_VEHICLE_INFO';
export const SET_VIN = 'SET_VIN';
export const SET_QUERIED_VIN = 'SET_QUERIED_VIN';
export const SET_ENTER_PRESSED = 'SET_ENTER_PRESSED';
export const SET_VEHICLE_DETAILS = 'SET_VEHICLE_DETAILS';

export const setIsLoading = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_IS_LOADING, payload });

export const setShowVehicleInfo = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_SHOW_VEHICLE_INFO, payload });

export const setVin = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_VIN, payload });

export const setQueriedVin = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_QUERIED_VIN, payload });

export const setEnterPressed = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_ENTER_PRESSED, payload });

export const setVehicleDetails = (
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  payload: any
) => dispatch({ type: SET_VEHICLE_DETAILS, payload });
