import React, { useState } from 'react';

import CountryPicker from './CountryPicker';
import StatePicker from './StatePicker';

import { AddressQuery } from '../types';

interface AddressFormProps {
  handleChange: (addressObj: AddressQuery) => any;
}

const AddressForm = (props: AddressFormProps) => {
  const [state, setState] = useState({
    country: 'US',
    state: 'AL',
  } as AddressQuery);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { id, value } = event.target;

    const newState = { ...state, [id as keyof AddressQuery]: value };
    setState(newState);

    props.handleChange(newState);
  };

  return (
    <div className="l-container-sm l-container-flex-box u-margin-left-none">
      <div className="c-field">
        <CountryPicker
          country={state.country as string}
          handleChange={handleChange}
        />
        <label
          htmlFor="streetAddress1"
          className="c-field__label u-margin-top-large"
        >
          Street Address 1
        </label>

        <input
          id="streetAddress1"
          type="text"
          className="c-input"
          value={state.streetAddress1 || ''}
          onChange={handleChange}
          placeholder="Street Address 1"
        />
        <label
          htmlFor="streetAddress2"
          className="c-field__label u-margin-top-large"
        >
          Street Address 2
        </label>

        <input
          id="streetAddress2"
          type="text"
          className="c-input"
          value={state.streetAddress2 || ''}
          onChange={handleChange}
          placeholder="Street Address 2"
        />

        <label htmlFor="city" className="c-field__label u-margin-top-large">
          City
        </label>

        <input
          id="city"
          type="text"
          className="c-input"
          value={state.city || ''}
          onChange={handleChange}
          placeholder="City"
        />

        <div className="l-flex-wrap l-flex-gap">
          {state.country === 'US' ? (
            <StatePicker
              state={state.state || ''}
              handleChange={handleChange}
            />
          ) : (
            <div
              className={`c-field l-container-xs u-margin-left-none u-margin-right-none u-padding-none`}
            >
              <label
                htmlFor="state"
                className="c-field__label u-margin-top-large"
              >
                State
              </label>
              <input
                id="state"
                type="text"
                className={`c-input u-input-side-by-side`}
                placeholder="State"
                value={state.state}
                onChange={handleChange}
              />
            </div>
          )}

          <div
            className={`c-field l-container-xs u-margin-left-none u-margin-right-none u-padding-none`}
          >
            <label
              htmlFor="postalCode"
              className="c-field__label u-margin-top-large"
            >
              Zip
            </label>

            <input
              id="postalCode"
              type="text"
              className="c-input u-input-side-by-side"
              value={state.postalCode || ''}
              onChange={handleChange}
              placeholder="Postal Code"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
