import React, { useEffect, useState } from 'react';
import _ from 'underscore';

interface SwitchProps {
  isChecked: boolean;
  states: SwitchStates;
  handleCheck: (checked: boolean) => void;
}

interface SwitchStates {
  active: string;
  inactive: string;
}

const Switch = (props: SwitchProps) => {
  const [checked, setChecked] = useState(props.isChecked);

  useEffect(() => {
    setChecked(props.isChecked);
  }, [props.isChecked]);

  const handleCheck = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    props.handleCheck(e.target.checked);
  }, 150);
  return (
    <div className="c-field l-flex-align-center u-margin-bottom-none">
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            e.persist();
            setChecked(e.target.checked);
            handleCheck(e);
          }}
        />
        <span className="slider round"></span>
      </label>
      <label
        htmlFor="shop"
        className="c-field__label u-padding-left-small u-padding-bottom-none"
      >
        {props.isChecked ? props.states.active : props.states.inactive}
      </label>
    </div>
  );
};

export default Switch;
