import DateRangePicker from 'react-bootstrap-daterangepicker';
import React, { Component } from 'react';
import moment from 'moment';
import { TextOnly } from '../components/Text';

export default class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: props.value.start,
      end: props.value.end,
      rangeName: 'Last 30 Days',
      key: 0,
    };
  }

  componentDidMount() {
    document.getElementById(
      (this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'
    ).value = this.calcRangeStr(this.props.value.start, this.props.value.end);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.setState({ key: this.state.key + 1 });
    }
  }

  handleRangeChange(range) {
    document.getElementById(
      (this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'
    ).value = this.calcRangeStr(range.start, range.end);
    if (this.props.onChange) {
      this.props.onChange({
        start: range.start,
        end: range.end,
      });
    }
  }

  handlePresetChange(name, range, event) {
    if (this.props.onChange) {
      this.props.onChange({
        start: range[0](),
        end: range[1](),
      });
    }
    this.setState({ rangeName: name });
  }

  calcRangeStr(start, end) {
    const userTimezone =
      window.localStorage.getItem('tempTimezone') ||
      this.props.user.userTimezone;
    if (
      JSON.parse(window.localStorage.getItem('convertingTimezones')) === true &&
      userTimezone
    ) {
      return (
        <span>
          <span>{start.tz(userTimezone).format('YYYY-MM-DD hh:mmA')}</span>{' '}
          <i className="fal fa-arrow-right"></i>{' '}
          <span>{end.tz(userTimezone).format('YYYY-MM-DD hh:mmA')}</span>{' '}
          {moment().tz(userTimezone).zoneAbbr()}
        </span>
      );
    } else {
      return (
        <span>
          <span>{start.utc().format('YYYY-MM-DD HH:mm:ss')}</span>{' '}
          <i className="fal fa-arrow-right"></i>{' '}
          <span>{end.utc().format('YYYY-MM-DD HH:mm:ss')}</span> UTC
        </span>
      );
    }
  }

  render() {
    let label = this.props.value.start
      ? this.calcRangeStr(this.props.value.start, this.props.value.end)
      : '';

    let today = TextOnly('Today');
    let yesterday = TextOnly('yesterday');
    let last30days = TextOnly('last30Days');
    let thisMonth = TextOnly('thisMonth');
    let lastMonth = TextOnly('lastMonth');
    let allTime = 'All Time';

    // The 'range' property is a 2-element array containing functions that return a particular moment in
    // time. This is done so that these moments can be lazily computed when a saved search is loaded.
    let ranges = {};
    ranges[today] = [moment.utc().startOf('day'), moment.utc().endOf('day')];
    ranges[yesterday] = [
      moment.utc().subtract(1, 'days').startOf('day'),
      moment.utc().subtract(1, 'days').endOf('day'),
    ];
    ranges[last30days] = [
      moment.utc().subtract(30, 'days').startOf('day'),
      moment.utc().endOf('day'),
    ];
    ranges[thisMonth] = [
      moment.utc().startOf('month').startOf('day'),
      moment.utc().endOf('day'),
    ];
    ranges[lastMonth] = [
      moment.utc().subtract(1, 'month').startOf('month').startOf('day'),
      moment.utc().subtract(1, 'month').endOf('month').endOf('day'),
    ];
    ranges[allTime] = [moment.utc().year(1970), moment.utc().endOf('day')];

    const locale = {
      language: TextOnly('hl'),
      format:
        JSON.parse(window.localStorage.getItem('convertingTimezones')) ===
          true && this.props.user.userTimezone
          ? 'YYYY-MM-DD hh:mmA'
          : 'YYYY-MM-DD HH:MM:SS z',
      separator: ' - ',
      applyLabel: TextOnly('apply'),
      cancelLabel: TextOnly('cancel'),
      monthNames: [
        TextOnly('January'),
        TextOnly('February'),
        TextOnly('March'),
        TextOnly('April'),
        TextOnly('May'),
        TextOnly('June'),
        TextOnly('July'),
        TextOnly('August'),
        TextOnly('September'),
        TextOnly('October'),
        TextOnly('November'),
        TextOnly('December'),
      ],
      daysOfWeek: [
        TextOnly('Sun'),
        TextOnly('Mon'),
        TextOnly('Tue'),
        TextOnly('Wed'),
        TextOnly('Thu'),
        TextOnly('Fri'),
        TextOnly('Sat'),
      ],
    };

    return (
      <DateRangePicker
        key={this.state.key}
        useCurrent={false}
        initialSettings={{
          startDate: this.props.value.start,
          endDate: this.props.value.end,
          ranges: ranges,
          locale,
          containerClass: 'width-100',
          showDropdowns: true,
          alwaysShowCalendars: true,
          timePicker: true,
          drops: this.props.drops || 'auto',
        }}
        onApply={(event, dateRangePicker) => {
          const { startDate, endDate } = dateRangePicker;
          this.handleRangeChange({
            start: startDate.utc(true),
            end: endDate.utc(true),
            name: 'Custom',
          });
          this.setState({ rangeName: 'Custom' });
        }}
      >
        <div
          className={`Bootstrap__dateRangePicker ${this.props.className}`}
          id={(this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'}
        >
          {label}
        </div>
      </DateRangePicker>
    );
  }
}
