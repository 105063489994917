import React from 'react';
import { Dialog } from '@reach/dialog';
import { TextOnly } from './Text';

// cell renderer for the actions column. this is a very basic cell renderer,

type AlertModalProps = {
  showModal: boolean;
  handleCancel: () => void;
  title?: string;
  message: string | React.ReactNode;
  size?: string;
  BtnText?: string;
};

const AlertModal = ({
  showModal,
  handleCancel,
  title,
  message,
  BtnText,
}: AlertModalProps) => {
  function handleCancelAlert() {
    document.querySelector('.c-modal.alert')?.classList.add('closed');
    setTimeout(() => {
      handleCancel();
    }, 350);
  }

  return (
    <Dialog
      isOpen={showModal}
      onDismiss={handleCancelAlert}
      className="c-modal alert"
      aria-label={title || 'alert modal'}
    >
      <button className="c-btn-icon c-modal__close" onClick={handleCancelAlert}>
        <div className="c-btn__inner">
          <i className="c-btn__icon fa fa-times" />
        </div>
      </button>
      {title ? <h1 className="c-modal__heading">{title}</h1> : null}

      <div className="c-modal__body">
        <>{message}</>
        <div className="l-flex-between">
          <div />
          <button
            className="c-btn-outline u-margin-top-large"
            onClick={handleCancelAlert}
          >
            {BtnText ? BtnText : TextOnly('dismiss')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertModal;
