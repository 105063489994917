import React from 'react';
import { useMenu } from '../context/Menu';
import menu from '../assets/icons/menu.svg';
// import { useUser } from '../context/User';
// import { USER_TYPES } from '../CONSTANTS';

type HeaderPropType = {
  context?: string;
  title: string;
};

export function Header({ context, title }: HeaderPropType) {
  const { toggleShowMobileNav, showMobileNav } = useMenu();
  // const { user } = useUser();
  // const isAdmin = user?.userType === USER_TYPES.ADMIN;
  // const isStandard = user?.userType === USER_TYPES.STANDARD;
  // const isExternal = user?.userType === USER_TYPES.EXTERNAL;

  return (
    <>
      <div className="l-flex-between u-margin-top-large">
        <div className="c-mobile-nav">
          <button
            className={`c-btn-link c-btn-link-mobile-nav ${
              showMobileNav ? 'c-btn-link__active' : ''
            }`}
            onClick={toggleShowMobileNav}
          >
            <div className="c-btn__inner">
              <img
                src={menu}
                className="c-btn__inner"
                alt="toggle menu button"
              />
            </div>
          </button>
        </div>
        <div />
      </div>

      <div className="c-header">
        <div className="l-flex-start">
          <h2 className="c-page-heading">
            {context ? (
              <>
                <span className="c-page-heading__context">{context}</span>
                <span className="c-page-heading__divider">/</span>
              </>
            ) : null}
            <span className="c-page-heading__title">{title}</span>
          </h2>
        </div>
      </div>
    </>
  );
}
