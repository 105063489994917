export const timezones = [
  {
    sub: '(GMT-11:00)',
    label: 'Pago Pago/Samoa Standard Time',
    tzCode: 'Pacific/Pago_Pago',
  },
  { sub: '(GMT-10:00)', label: 'Hawaii Time', tzCode: 'Pacific/Honolulu' },
  {
    sub: '(GMT-10:00)',
    label: 'Tahiti Time',
    abbr: 'TAHT',
    tzCode: 'Pacific/Tahiti',
  },
  { sub: '(GMT-09:00)', label: 'Alaska Time', tzCode: 'America/Anchorage' },
  { sub: '(GMT-08:00)', label: 'Pacific Time', tzCode: 'America/Los_Angeles' },
  { sub: '(GMT-07:00)', label: 'Mountain Time', tzCode: 'America/Denver' },
  { sub: '(GMT-06:00)', label: 'Central Time', tzCode: 'America/Chicago' },
  { sub: '(GMT-05:00)', label: 'Eastern Time', tzCode: 'America/New_York' },
  {
    sub: '(GMT-04:00)',
    label: 'Atlantic Time - Halifax',
    tzCode: 'America/Halifax',
  },
  {
    sub: '(GMT-03:00)',
    label: 'Buenos Aires',
    abbr: 'ART',
    tzCode: 'America/Argentina/Buenos_Aires',
  },
  {
    sub: '(GMT-02:00)',
    label: 'Sao Paulo',
    abbr: 'BRT/BRST',
    tzCode: 'America/Sao_Paulo',
  },
  {
    sub: '(GMT-01:00)',
    label: 'Azores',
    abbr: 'AZOT/AZOST',
    tzCode: 'Atlantic/Azores',
  },
  { sub: '(GMT+00:00)', label: 'London', tzCode: 'Europe/London' },
  { sub: '(GMT+01:00)', label: 'Berlin', tzCode: 'Europe/Berlin' },
  { sub: '(GMT+02:00)', label: 'Helsinki', tzCode: 'Europe/Helsinki' },
  { sub: '(GMT+03:00)', label: 'Moscow', tzCode: 'Europe/Moscow' },
  {
    sub: '(GMT+04:00)',
    label: 'Dubai/Gulf Standard Time',
    abbr: 'GST',
    tzCode: 'Asia/Dubai',
  },
  {
    sub: '(GMT+04:30)',
    label: 'Afghanistan Time',
    abbr: 'AFT',
    tzCode: 'Asia/Kabul',
  },
  {
    sub: '(GMT+05:00)',
    label: 'Maldives',
    abbr: 'MVT',
    tzCode: 'Indian/Maldives',
  },
  { sub: '(GMT+05:30)', label: 'India Standard Time', tzCode: 'Asia/Calcutta' },
  {
    sub: '(GMT+05:45)',
    label: 'Kathmandu/Nepal Time',
    abbr: 'NPT',
    tzCode: 'Asia/Kathmandu',
  },
  {
    sub: '(GMT+06:00)',
    label: 'Dhaka/Bangladesh Standard Time',
    abbr: 'BST',
    tzCode: 'Asia/Dhaka',
  },
  {
    sub: '(GMT+06:30)',
    label: 'Cocos Islands Time',
    abbr: 'CCT',
    tzCode: 'Indian/Cocos',
  },
  {
    sub: '(GMT+07:00)',
    label: 'Bangkok/Indochina Time',
    abbr: 'ICT',
    tzCode: 'Asia/Bangkok',
  },
  { sub: '(GMT+08:00)', label: 'Hong Kong', tzCode: 'Asia/Hong_Kong' },
  { sub: '(GMT+08:30)', label: 'Pyongyang', tzCode: 'Asia/Pyongyang' },
  { sub: '(GMT+09:00)', label: 'Tokyo', tzCode: 'Asia/Tokyo' },
  {
    sub: '(GMT+09:30)',
    label: 'Central Time - Darwin',
    tzCode: 'Australia/Darwin',
  },
  {
    sub: '(GMT+10:00)',
    label: 'Eastern Time - Brisbane',
    tzCode: 'Australia/Brisbane',
  },
  {
    sub: '(GMT+10:30)',
    label: 'Central Time - Adelaide',
    tzCode: 'Australia/Adelaide',
  },
  {
    sub: '(GMT+11:00)',
    label: 'Eastern Time - Melbourne, Sydney',
    tzCode: 'Australia/Sydney',
  },
  { sub: '(GMT+12:00)', label: 'Nauru', abbr: 'NRT', tzCode: 'Pacific/Nauru' },
  { sub: '(GMT+13:00)', label: 'Auckland', tzCode: 'Pacific/Auckland' },
  {
    sub: '(GMT+14:00)',
    label: 'Kiritimati/Line Islands Time',
    abbr: 'LINT',
    tzCode: 'Pacific/Kiritimati',
  },
];

export const abbreviations = {
  'Pacific/Tahiti': 'TAHT',
  'America/Argentina/Buenos_Aires': 'ART',
  'America/Sao_Paulo': 'BRT/BRST',
  'Atlantic/Azores': 'AZOT/AZOST',
  'Asia/Dubai': 'GST',
  'Asia/Kabul': 'AFT',
  'Indian/Maldives': 'MVT',
  'Asia/Kathmandu': 'NPT',
  'Asia/Dhaka': 'BST',
  'Indian/Cocos': 'CCT',
  'Asia/Bangkok': 'ICT',
  'Pacific/Nauru': 'NRT',
  'Pacific/Kiritimati': 'LINT',
};
