import React from 'react';

import { QueriedUser, Shop, Address, PaymentInfo } from '../types';
import {
  userInfoKeymap,
  shopStateInfoKeymap,
  addressInfoKeymap,
  paymentInfoKeymap,
  languageMap,
} from '../libs/tables/tableKeymaps';
import { formatTimezone } from './utils';
import HelpTooltip from '../components/Tooltips/HelpTooltip';
import { getStateVarColor, getShopStateVarDesc } from './utils-ts';
import { Led } from '../components/Led';

export const createUserInfoCard = (
  queriedUser: QueriedUser,
  windowWidth: number
) => {
  return Object.keys(queriedUser).map((key) => {
    if (key === 'userShops') {
      return null;
    }
    let value = queriedUser[key as keyof QueriedUser] as
      | string
      | Shop[]
      | JSX.Element;

    if (!value) return null;

    let monoStyle =
      key !== 'createdOn' && key !== 'language' ? 'u-font-mono' : '';

    if (['createdOn','lastDateAttempted','lastDateSucceded'].includes(key)) {
      value = formatTimezone(value);
    }

    if (key === 'language') {
      value = languageMap[value as keyof Object] as any;
    }

    if (key === 'userState') {
      value = <Led status={queriedUser.userState as any} />;
    }

    return windowWidth < 1220 ? (
      <div className="u-margin-bottom-large" key={key}>
        <label className="mobile-display-label">
          <>{userInfoKeymap[key as keyof Object]}</>
        </label>
        <div className={`mobile-display-value ${monoStyle}`}><>{value}</></div>
      </div>
    ) : (
      <tr className="card-tr" key={key}>
        <th className="card-th">{userInfoKeymap[key as keyof Object]+':'}</th>
        <td className={`card-td ${monoStyle}`}><>{value}</></td>
      </tr>
    );
  });
};

export const createShopStateInfoCard = (
  shop: Shop,
  windowWidth: number,
  showAlert?: (
    alertTitle: string,
    alertMessage: string | JSX.Element,
    stringify?: boolean | undefined
  ) => void
) => {
  return Object.keys(shopStateInfoKeymap).map((key) => {
    let value = shop[key as keyof Shop];

    if (value === 'Canceled') {
      value = 'Payment method removed';
    }
    if (!value) return null;

    let tooltipContent = (
      <div>
        {getShopStateVarDesc(key, value as string)}
        {shop.suspendReason && shop.shopState === 'SUSPENDED' && (
          <>
            <br />
            <strong>Reason for suspension: </strong>
            {shop.suspendReason}
          </>
        )}
      </div>
    );
    let element = (
      <>
        <span style={{ color: getStateVarColor(value as string) }}>
          <strong>{(value as string).toUpperCase()}</strong>
        </span>

        {getShopStateVarDesc(key, value as string) && (
          <HelpTooltip
            onClick={() =>
              showAlert && showAlert('Info', tooltipContent, false)
            }
            label={
              <div>
                {getShopStateVarDesc(key, value as string)}
                {shop.suspendReason && (shop.shopState === 'TERMINATED' || shop.shopState === 'SUSPENDED') && (
                  <>
                    <br />
                    <strong>Reason for suspension/termination: </strong>
                    {shop.suspendReason}
                  </>
                )}
              </div>
            }
          />
        )}
      </>
    );
    return (
      <React.Fragment key={key}>
        {windowWidth < 1220 ? (
          <div className="u-margin-bottom-large" key={key}>
            <label className="mobile-display-label">
              <>{shopStateInfoKeymap[key as keyof Object]}</>
            </label>
            <div className={`mobile-display-value l-flex`}>{element}</div>
          </div>
        ) : (
          <tr className="card-tr" key={key}>
            <th className="card-th">
              <>{shopStateInfoKeymap[key as keyof Object]+': '}</>
            </th>
            <td className="card-td l-inline-flex">{element}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
};

export const createAddressInfoCard = (shop: Shop | any, windowWidth: number) => {
  if(!shop.addressInfo){
    shop.addressInfo = {
      streetAddress1: shop.streetAddress1,
      streetAddress2: shop.streetAddress2,
      city: shop.city,
      state: shop.state,
      zip: shop.zip,
      country: shop.country
    }
  }
  return Object.keys(addressInfoKeymap).map((key) => {
    let value = '-' as string | undefined;
    if (key === 'phone') {
      value = shop.phone || '-';
    } else {
      value = (shop.addressInfo[key as keyof Address]) || '-';
    }

    return (
      <React.Fragment key={key}>
        {windowWidth < 1220 ? (
          <div className="u-margin-bottom-large" key={key}>
            <label className="mobile-display-label">
              <>{addressInfoKeymap[key as keyof Object]}</>
            </label>
            <div className={`mobile-display-value`}>{value}</div>
          </div>
        ) : (
          <tr className="card-tr" key={key}>
            <th className="card-th">
              <>{addressInfoKeymap[key as keyof Object]+': '}</>
            </th>
            <td className="card-td">{value}</td>
          </tr>
        )}
      </React.Fragment>
    );
  });
};

export const createPaymentInfoCard = (
  shop: Shop,
  windowWidth: number,
  config: any,
  showTransactionsTableModal: () => void
) => {
  let braintreeUrl =
    process.env.NODE_ENV === 'development'
      ? `https://sandbox.braintreegateway.com/merchants/${config.merchantId}`
      : `https://braintreegateway.com/merchants/${config.merchantId}`;

  return Object.keys(paymentInfoKeymap).map((key) => {
    if (!shop.shopPaymentInfo) return null;
    let value = shop.shopPaymentInfo[key as keyof PaymentInfo];
    if (key === 'newBillAmount' && shop.newBillAmount) {
      value = shop.newBillAmount;
    }

    if (!value) return null;

    let element;

    switch (key) {
      case 'transactions':
        let transactionsCount =
          shop.transactionsCount || shop.shopPaymentInfo.transactions.length;
        if (windowWidth >= 1220) {
          element =
            transactionsCount > 0 ? (
              <div className="l-flex-align-center">
                {transactionsCount}{' '}
                <button
                  className="c-btn-icon"
                  onClick={() => showTransactionsTableModal()}
                >
                  <div className="c-btn__inner">
                    <i className="c-btn__icon fa fa-chevron-right" />
                  </div>
                </button>
              </div>
            ) : (
              transactionsCount
            );
        } else {
          element =
            transactionsCount > 0 ? (
              <div className="l-flex-align-stretch">
                <div className="mobile-display-value-with-button">
                  {transactionsCount}{' '}
                </div>
                <div
                  className="mobile-display-value-button"
                  onClick={() => showTransactionsTableModal()}
                >
                  <i className="c-btn__icon fa fa-chevron-right u-padding-left" />
                </div>
              </div>
            ) : (
              transactionsCount
            );
        }
        break;

      case 'customerId':
        element =
          windowWidth >= 1220 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${braintreeUrl}/customers/${value}`}
              className="u-font-mono"
            >
              <>{value}</>
            </a>
          ) : (
            <div className={`mobile-display-value`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${braintreeUrl}/customers/${value}`}
                className="u-font-mono"
              >
                <>{value}</>
              </a>
            </div>
          );
        break;

      case 'subscriptionId':
        element =
          windowWidth >= 1220 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${braintreeUrl}/subscriptions/${value}`}
              className="u-font-mono"
            >
              <>{value}</>
            </a>
          ) : (
            <div className={`mobile-display-value`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${braintreeUrl}/subscriptions/${value}`}
                className="u-font-mono"
              >
                <>{value}</>
              </a>
            </div>
          );
        break;

      case 'token':
        element =
          windowWidth >= 1220 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${braintreeUrl}/payment_methods/any/${value}`}
              className="u-font-mono"
            >
              <>{value}</>
            </a>
          ) : (
            <div className={`mobile-display-value`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${braintreeUrl}/payment_methods/any/${value}`}
                className="u-font-mono"
              >
                <>{value}</>
              </a>
            </div>
          );
        break;

      case 'nextBillingDate':
        let displayValue = <span><>{value}</></span>;
        if (shop.shopSubscriptionState === 'PAST_DUE') {
          displayValue = <span className="text-bold u-text-warning"><strong>PAST DUE</strong></span>;
        }
        element =
          windowWidth < 1220 ? (
            <div className={`mobile-display-value`}>{displayValue}</div>
          ) : (
            displayValue
          );
        break;

      case 'newBillAmount':
        let newBill:number = value as number;
        let displayValue2 = <span className="text-bold u-text-warning"><strong><>{newBill.toFixed(2)}</></strong></span>;
        element =
          windowWidth < 1220 ? (
            <div className={`mobile-display-value`}><>{displayValue2}</></div>
          ) : (
            displayValue2
          );
          break;

      default:
        element =
          windowWidth < 1220 ? (
            <div className={`mobile-display-value`}><>{value}</></div>
          ) : (
            value
          );
        break;
    }

    return windowWidth < 1220 ? (
      <div className="u-margin-bottom-large" key={key}>
        <>
          <label className="mobile-display-label">
            <>{paymentInfoKeymap[key as keyof Object]}</>
          </label>
          {element}
        </>
      </div>
    ) : (
      <tr className="card-tr" key={key}>
        <th className="card-th"><>{paymentInfoKeymap[key as keyof Object]+':'}</></th>
        <td className="card-td"><>{element}</></td>
      </tr>
    );
  });
};
