import React, { Component } from 'react';
import { Dialog } from '@reach/dialog';
import { Text, TextOnly } from '../../components/Text';
import FieldRow from '../../components/FieldRow';
import {
  fieldChangeCase,
  usernameValidate,
  isValidEmail,
  isValidRole,
  nameValidate,
  passwordValidate,
} from '../../libs/utils';
import { createSupportUser } from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import { SupportUser } from '../../types';

interface AddUserProps {
  user: SupportUser;
  supportUsers: SupportUser[];
  windowWidth: number;
  baseURL: string;
  onDismiss: () => void;
  handleShowAlert: (
    alertTitle: string,
    alertMessage: string | JSX.Element
  ) => void;
  handleAddUser: (newUser: SupportUser) => void;
}

type ActivityType = 'SINGLE_USER' | 'BULK_USERS';

interface AddUserState {
  userName: string;
  password: string;
  newUserFirstName: string;
  newUserLastName: string;
  newUserEmail: string;
  newUserType: string;
  authProvider: string;
  isLoading: boolean;
  isSendingRequest: boolean;

  cognitoFieldClass: string;
}

export default class AddUser extends Component<AddUserProps, AddUserState> {
  constructor(props: AddUserProps) {
    super(props);

    this.state = {
      userName: '',
      password: '',
      newUserFirstName: '',
      newUserLastName: '',
      newUserEmail: '',
      newUserType: 'STANDARD',
      authProvider: 'COGNITO',
      isLoading: false,
      isSendingRequest: false,
      cognitoFieldClass: '',
    };
  }

  handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (event.target.id === 'userName') {
      fieldChangeCase(this, event.target, 'lower', false);
    } else {
      this.setState(
        {
          [event.target.id]: event.target.value,
        } as any,
        () => {
          console.log(this.state);
        }
      );
    }
  };

  validateForm() {
    const {
      userName,
      password,
      newUserFirstName,
      newUserLastName,
      newUserEmail,
      newUserType,
      authProvider,
    } = this.state;
    if (authProvider === 'DIRECTORY') {
      return (
        nameValidate(newUserFirstName) &&
        nameValidate(newUserLastName) &&
        isValidEmail(newUserEmail) &&
        isValidRole(newUserType)
      );
    }
    return (
      usernameValidate(userName) &&
      passwordValidate(password) &&
      nameValidate(newUserFirstName) &&
      nameValidate(newUserLastName) &&
      isValidEmail(newUserEmail) &&
      isValidRole(newUserType) &&
      (authProvider === 'COGNITO' || authProvider === 'DIRECTORY')
    );
  }

  handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    this.setState({ isSendingRequest: true, isLoading: true });

    try {
      const data = this.state;
      // Check if this user already exists with the username or email
      const userFound = this.props.supportUsers.filter((user) => {
        return (
          user.email === data.newUserEmail || user.userName === data.userName
        );
      });

      if (userFound.length > 0) {
        this.setState({
          isSendingRequest: false,
          isLoading: false,
        });

        this.props.handleShowAlert(
          TextOnly('error'),
          TextOnly('usernameAlreadyInUse')
        );
      } else {
        const {
          userName,
          password,
          newUserFirstName,
          newUserLastName,
          newUserEmail,
          newUserType,
          authProvider,
        } = this.state;
        const ret = await createSupportUser(
          userName,
          password,
          newUserFirstName,
          newUserLastName,
          newUserEmail,
          newUserType,
          authProvider
        );

        this.setState({ isSendingRequest: false, isLoading: false });
        if (Object.hasOwn(ret, 'error')) {
          if ([401, 403].indexOf(ret.error.status) === -1) {
            this.props.handleShowAlert(TextOnly('error'), ret.error);
          }
          return;
        } else {
          const newUser = {
            userID: data.newUserEmail,
            userName:
              authProvider === 'COGNITO' ? data.userName : data.newUserEmail,
            firstName: data.newUserFirstName,
            lastName: data.newUserLastName,
            email: data.newUserEmail,
            userType: data.newUserType,
            userState: 'PENDING',
            name: `${data.newUserFirstName} ${data.newUserLastName}`,
          };
          // partial user being added
          // @ts-ignore
          this.props.handleAddUser(newUser);
          this.props.onDismiss();
        }
      }
    } catch (e) {
      console.log('Error', e);
      // Need to figure out how to pass back a message from the REST call about the cause of the error
      // @ts-ignore - error type is not checked
      this.props.handleShowAlert(TextOnly('error'), e.message);
    } finally {
      this.setState({
        isSendingRequest: false,
        isLoading: false,
      });
    }
  };

  render() {
    const { onDismiss } = this.props;

    return (
      <Dialog
        onDismiss={onDismiss}
        className="c-modal-slider"
        aria-label={TextOnly('addUser')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={onDismiss}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="addUser" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={this.handleSubmit}>
            <div className="c-field">
              <label htmlFor="authProvider" className="c-field__label">
                Auth Provider
              </label>
              <div className="c-select">
                <select
                  onChange={this.handleChange}
                  id="authProvider"
                  defaultValue="STANDARD"
                >
                  {['COGNITO', 'DIRECTORY'].map((type, i) => {
                    return <option key={i}>{type}</option>;
                  })}
                </select>
              </div>
            </div>
            {this.state.authProvider === 'COGNITO' && (
              <>
                <FieldRow
                  id="userName"
                  title="Username"
                  placeholder={TextOnly('username')}
                  value={this.state.userName}
                  onChange={this.handleChange}
                />
                <FieldRow
                  id="password"
                  title="Password"
                  placeholder={TextOnly('password')}
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </>
            )}

            <FieldRow
              id="newUserFirstName"
              title="First Name"
              placeholder="First Name"
              value={this.state.newUserFirstName}
              onChange={this.handleChange}
            />
            <FieldRow
              id="newUserLastName"
              title="Last Name"
              placeholder="Last Name"
              value={this.state.newUserLastName}
              onChange={this.handleChange}
            />
            <FieldRow
              id="newUserEmail"
              title="Email"
              placeholder="Email"
              value={this.state.newUserEmail}
              onChange={this.handleChange}
            />
            <div className="c-field">
              <label htmlFor="newUserType" className="c-field__label">
                User Type
              </label>
              <div className="c-select">
                <select
                  onChange={this.handleChange}
                  id="newUserType"
                  defaultValue="STANDARD"
                >
                  {['ADMIN', 'MANAGER', 'STANDARD', 'EXTERNAL'].map(
                    (type, i) => {
                      return <option key={i}>{type}</option>;
                    }
                  )}
                </select>
              </div>
            </div>

            <div className="c-field">
              <LoaderButton
                type="submit"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text="Add User"
                loadingText="Adding..."
              />
            </div>
          </form>
        </div>
      </Dialog>
    );
  }
}
