import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Text } from '../components/Text';
import './Welcome.css';

export default class Welcome extends Component {
  getServiceCenter() {
    return API.get('echo-dev', '/getCenter', { response: true });
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      const serviceCenter = await this.getServiceCenter();
      this.state = {
        isLoading: true,
        serviceCenter: serviceCenter,
      };
      if (serviceCenter.data && serviceCenter.data.length > 0) {
        const record = serviceCenter.data[0];
        this.setState({
          firstName: record.content.firstName,
          lastName: record.content.lastName,
          company: record.content.company,
          email: record.content.email,
        });
        this.props.updateUserInfo({
          firstName: record.content.firstName,
          lastName: record.content.lastName,
          company: record.content.company,
          email: record.content.email,
        });
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }

  handleNoteClick = (event) => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute('href'));
  };

  renderLander() {
    return (
      <div>
        <div>
          <div>
            <Text tid="welcome" />
          </div>
          <div>
            <Text tid="click" />{' '}
            <Link to="/">
              <Text tid="here" />
            </Link>{' '}
            <Text tid="toGetStarted" />
          </div>
        </div>
      </div>
    );
  }

  renderNotAuthenticated() {
    return (
      <div>
        <div>
          <Text tid="problemAuthenticating" />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.isAuthenticated
          ? this.renderLander()
          : this.renderNotAuthenticated}
      </div>
    );
  }
}
