import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './containers/Login';
import Home from './containers/Home';
import ResetPassword from './containers/ResetPassword';
import UserProfile from './containers/UserProfile';
import ManageUsers from './containers/ManageSupportUsers/ManageUsers';
import GetUserSummary from './containers/UserFunctions/GetUserSummary';
import GetUserLog from './containers/UserFunctions/GetUserLog/GetUserLog';
import GetEmailLog from './containers/UserFunctions/GetEmailLog/GetEmailLog';
import GetShopSummary from './containers/ShopFunctions/GetShopSummary';
import GetShopLog from './containers/ShopFunctions/GetShopLog/GetShopLog';
import GetToolLog from './containers/ShopFunctions/GetToolLog/GetToolLog';
import ToolLookup from './containers/ShopFunctions/ToolLookup';
import ToolRecoveryImage from './containers/Dashboard/ToolRecoveryImage';
import VINDecoder from './containers/MiscFunctions/VINDecoder/VINDecoder';
import EmailUsers from './containers/MiscFunctions/EmailUsers';
import AddBanner from './containers/MiscFunctions/AddBanner';
import Dashboard from './containers/Dashboard';
import SystemInfo from './containers/SystemInfo';
import Welcome from './containers/Welcome';
import NotFound from './containers/NotFound';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import { USER_TYPES } from './CONSTANTS';
import GetLoginActivityLog from './containers/UserFunctions/GetLoginActivityLog/GetLoginActivityLog';

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Login} props={childProps} />

    {/* Unauth routes */}
    <UnauthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/Home"
      exact
      component={Home}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/resetPassword"
      exact
      component={ResetPassword}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/SystemInfo"
      exact
      component={SystemInfo}
      props={childProps}
    />

    {/* Auth Routes */}
    <AuthenticatedRoute
      path="/welcome"
      exact
      component={Welcome}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/dashboard"
      exact
      component={Dashboard}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/SystemInfo"
      exact
      component={SystemInfo}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/userProfile"
      exact
      component={UserProfile}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/manageUsers/currentUsers"
      exact
      component={ManageUsers}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN],
      }}
    />
    <AuthenticatedRoute
      path="/userFunctions/getUserSummary"
      exact
      component={GetUserSummary}
      props={childProps}
      permissions={{
        roles: [
          USER_TYPES.ADMIN,
          USER_TYPES.STANDARD,
          USER_TYPES.MANAGER,
          USER_TYPES.EXTERNAL,
        ],
      }}
    />
    <AuthenticatedRoute
      path="/shopFunctions/getShopSummary"
      exact
      component={GetShopSummary}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN, USER_TYPES.STANDARD, USER_TYPES.MANAGER],
      }}
    />
    <AuthenticatedRoute
      path="/userFunctions/getUserLog"
      exact
      component={GetUserLog}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN, USER_TYPES.STANDARD, USER_TYPES.MANAGER],
      }}
    />
    <AuthenticatedRoute
      path="/userFunctions/getEmailLog"
      exact
      component={GetEmailLog}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN, USER_TYPES.STANDARD, USER_TYPES.MANAGER],
      }}
    />
    <AuthenticatedRoute
      path="/supportui/getLoginActivityForUsername"
      exact
      component={GetLoginActivityLog}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN, USER_TYPES.STANDARD, USER_TYPES.MANAGER],
      }}
    />
    <AuthenticatedRoute
      path="/shopFunctions/getShopLog"
      exact
      component={GetShopLog}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN, USER_TYPES.STANDARD, USER_TYPES.MANAGER],
      }}
    />
    <AuthenticatedRoute
      path="/shopFunctions/getToolLog"
      exact
      component={GetToolLog}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN, USER_TYPES.STANDARD, USER_TYPES.MANAGER],
      }}
    />
    <AuthenticatedRoute
      path="/shopFunctions/toolLookup"
      exact
      component={ToolLookup}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN, USER_TYPES.STANDARD, USER_TYPES.MANAGER],
      }}
    />
    <AuthenticatedRoute
      path="/miscFunctions/vinDecoder"
      exact
      component={VINDecoder}
      props={childProps}
      permissions={{
        roles: [
          USER_TYPES.ADMIN,
          USER_TYPES.MANAGER,
          USER_TYPES.STANDARD,
          USER_TYPES.EXTERNAL,
        ],
      }}
    />
    <AuthenticatedRoute
      path="/miscFunctions/emailUsers"
      exact
      component={EmailUsers}
      props={childProps}
      permissions={{
        roles: [
          USER_TYPES.ADMIN,
          USER_TYPES.MANAGER,
        ],
      }}
    />
    <AuthenticatedRoute
      path="/miscFunctions/addBanner"
      exact
      component={AddBanner}
      props={childProps}
      permissions={{
        roles: [
          USER_TYPES.ADMIN,
        ],
      }}
    />
    <AuthenticatedRoute
      path="/toolRecoveryImage"
      exact
      component={ToolRecoveryImage}
      props={childProps}
      permissions={{
        roles: [USER_TYPES.ADMIN, USER_TYPES.STANDARD, USER_TYPES.MANAGER],
      }}
    />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
