import React, { Component } from 'react';
import config from '../config';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      newPassword: '',
      alertMessage: '',
      showModal: false,
      showAlertModal: false,
      requestNewPassword: false,
      language: null,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    if (window.location.href.split('=').length > 2) {
      let searchStr = window.location.search;
      let values = searchStr.substring(searchStr.indexOf('?') + 1);
      values.split('&').forEach((elem) => {
        let nameValue = elem.split('=');
        if (nameValue[0] !== 'redirect') {
          window.localStorage.setItem(nameValue[0], nameValue[1]);
        } else {
          let searchStr = elem.substring(elem.indexOf('=')+1);
          let values = searchStr.substring(searchStr.indexOf('?') + 1);
          values.split('&').forEach((elem) => {
            let nameValue = elem.split('=');
            window.localStorage.setItem(nameValue[0], nameValue[1]);
          });
        }
      });
    }
    const redirectURL =
    config.AUTH_DIAG_ENVIRONMENT === 'DEV'
      ? 'http://localhost:3000/Home'
      : 'https://' + window.location.hostname + '/Home';

    window.location.assign(
      config.cognito.DOMAIN +
        '/authorize?client_id=' +
        config.cognito.APP_CLIENT_ID +
        '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=' +
        redirectURL
    );

    if (this.props.history.location.search !== '') {
      // Parse the url for values
      let searchStr = this.props.history.location.search;
      let values = searchStr.substring(searchStr.indexOf('?', 1) + 1);
      values.split('&').forEach((elem) => {
        let nameValue = elem.split('=');

        let content = nameValue[1];
        let type = '';

        // Perform redirect and assign window.name if necessary to allow auto-searches
        switch (nameValue[0]) {
          case 'shopID':
            type = 'SHOP ID';
            break;
          case 'userID':
            type = 'USER ID';
            break;
          case 'toolID':
            type = 'TOOL ID';
            break;
          case 'toolSerial':
            type = 'TOOL SERIAL';
            break;
          default:
            type = nameValue[0];
        }
        window.name = JSON.stringify({
          content:
            typeof content === 'string' ? content : content.props.children,
          type: type,
          action: 'search',
        });
      });
    }
  }

  render() {
    return (
      <div>
        <div className="Login col-md-offset-4 col-md-4 col-sm-offset-2 col-sm-8 white-container"></div>
      </div>
    );
  }
}
