import React from 'react';
import { toast } from 'react-toastify';

const CopyData = ({info}: any) => {
  return (
    <div className="l-flex-end u-margin-bottom">
                  <button
                    className="c-btn-medium"
                    onClick={() => {
                      if (!info) {
                        toast.error('No data to copy!', {
                          containerId: 'standard',
                        });
                        return;
                      }

                      let data = JSON.stringify(info, null, 2);
                      navigator.clipboard
                        .writeText(data as string)
                        .then(function () {
                          toast.success('Copied!', {
                            containerId: 'standard',
                          });
                        });
                    }}
                  >
                    <div className="c-btn__inner">
                      <i className="c-btn__icon fa fa-clipboard" />
                      Copy Data
                    </div>
                  </button>
                </div>
  );
};

export default CopyData;
