import React, { useEffect, useState } from 'react';
import { FullNameQuery } from '../types';
import FieldWithHistory from './FieldWithHistory';

interface FullNameFormProps {
  handleChange: (fullNameObj: FullNameQuery) => void;
  handleSubmit: () => void;
}

const FullNameForm = (props: FullNameFormProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const { handleChange } = props;

  useEffect(() => {
    let name = {
      firstName: firstName,
      lastName: lastName,
    };
    handleChange(name);
  }, [firstName, lastName, handleChange]);

  const validateForm = () => {
    return firstName.length > 0 || lastName.length > 0;
  };

  return (
    <div className="l-flex-gap-1">
      <FieldWithHistory
        fieldId="firstName"
        searchKey="firstName"
        selected={firstName}
        onInputChange={(e) => {
          if (e !== undefined) {
            setFirstName(e);
          }
        }}
        onChange={(e) => {
          if (e !== undefined) {
            setFirstName(e);
          }
        }}
        placeholder='ex. "John"'
        onEnter={() => {
          if (validateForm()) props.handleSubmit();
        }}
        tab={true}
      />
      <FieldWithHistory
        fieldId="lastName"
        searchKey="lastName"
        selected={lastName}
        onInputChange={(e) => {
          if (e !== undefined) {
            setLastName(e);
          }
        }}
        onChange={(e) => {
          if (e !== undefined) {
            setLastName(e);
          }
        }}
        placeholder='ex. "Smith"'
        onEnter={() => {
          if (validateForm()) props.handleSubmit();
        }}
        tab={true}
      />
    </div>
  );
};

export default FullNameForm;
