import {
  createSelfAccountColumns,
  confirmSelfAccountColumns,
  importUsersColumns,
  updateUserInfoColumns,
  createShopColumns,
  updateShopInfoColumns,
  updateShopPaymentInfoColumns,
  removeShopPaymentInfoColumns,
  updateShopMaxUsersColumns,
  addUserToShopColumns,
  removeUserFromShopColumns,
  respondToShopInviteColumns,
  setUserStateAtShopColumns,
  registerToolForShopColumns,
  removeToolFromShopColumns,
  importShopToolsColumns,
  userToolLoginColumns,
  userToolADInitiateColumns,
  userToolADRequestColumns,
  userToolADExecuteColumns,
  inviteUsersColumns,
  revokeInviteColumns,
  createSignupLinkColumns,
  updateSignupLinkColumns,
  userToolADInitiateErrorColumns,
  userToolADRequestErrorColumns,
  userToolADExecuteErrorColumns,
  acAutoShopSubStateChangeColumns,
  baseEmailLogColumns,
  editToolForShopColumns,
} from '../../libs/tables/columnDefinitions';

const baseFilterKeys = ['actionMessage', 'actionCode', 'actionDate', 'userID'];
const baseShopFilterKeys = [...baseFilterKeys, 'shopID'];
const baseToolFilterKeys = [
  ...baseShopFilterKeys,
  'oemID',
  'sourceIP',
  'toolID',
  'toolMfgUUID',
  'toolModelUUID',
  'toolSerial',
];

const baseEmailFilterKeys = [
  ...baseFilterKeys,
  'email',
  'subject',
  'messageBodyText',
];

export const allFilterKeys = [
  'actionMessage',
  'actionCode',
  'actionDate',
  'userID',
  'addUserID',
  'removeUserID',
  'shopUserRole',
  'shopUserState',
  'targetUserID',
  'email',
  'userName',
  'userEmail',
  'numToAdd',
  'shopID',
  'shopName',
  'statusChange',
  'response',
  'linkID',
  'linkState',
  'shopSubscriptionNotificationState',
  'shopSubscriptionState',
  'toolID',
  'toolMfgUUID',
  'toolModelUUID',
  'toolManufacturer',
  'toolModel',
  'toolSerial',
  'toolName',
  'toolNotes',
  'apiErrorMessage',
  'errorDetail',
  'oemID',
  'sourceIP',
  'vin',
  'addedBy',
  'email',
  'subject',
  'messageBodyText',
];

export const logTableStructure = {
  CREATE_SELF_ACCOUNT: {
    filterKeys: [...baseFilterKeys, 'email', 'userName'],
    columnDefs: createSelfAccountColumns,
    savedColumnsId: 'createSelfAccountColumns',
  },
  CONFIRM_SELF_ACCOUNT: {
    filterKeys: baseFilterKeys,
    columnDefs: confirmSelfAccountColumns,
    savedColumnsId: 'confirmSelfAccountColumns',
  },
  IMPORT_USERS: {
    filterKeys: baseFilterKeys,
    columnDefs: importUsersColumns,
    savedColumnsId: 'importUsersColumns',
  },
  UPDATE_USER_INFO: {
    filterKeys: baseFilterKeys,
    columnDefs: updateUserInfoColumns,
    savedColumnsId: 'updateUserInfoColumns',
  },
  CREATE_SHOP: {
    filterKeys: baseShopFilterKeys,
    columnDefs: createShopColumns,
    savedColumnsId: 'createShopColumns',
  },
  UPDATE_SHOP_INFO: {
    filterKeys: [...baseShopFilterKeys, 'statusChange'],
    columnDefs: updateShopInfoColumns,
    savedColumnsId: 'updateShopInfoColumns',
  },
  UPDATE_SHOP_PAYMENT_INFO: {
    filterKeys: baseShopFilterKeys,
    columnDefs: updateShopPaymentInfoColumns,
    savedColumnsId: 'updateShopPaymentInfoColumns',
  },
  REMOVE_SHOP_PAYMENT_INFO: {
    filterKeys: baseShopFilterKeys,
    columnDefs: removeShopPaymentInfoColumns,
    savedColumnsId: 'removeShopPaymentInfoColumns',
  },
  UPDATE_SHOP_MAX_USERS: {
    filterKeys: [...baseShopFilterKeys, 'numToAdd'],
    columnDefs: updateShopMaxUsersColumns,
    savedColumnsId: 'updateShopMaxUsersColumns',
  },
  ADD_USER_TO_SHOP: {
    filterKeys: [...baseShopFilterKeys, 'addUserID'],
    columnDefs: addUserToShopColumns,
    savedColumnsId: 'addUserToShopColumns',
  },
  REMOVE_USER_FROM_SHOP: {
    filterKeys: [...baseShopFilterKeys, 'removeUserID'],
    columnDefs: removeUserFromShopColumns,
    savedColumnsId: 'removeUserFromShopColumns',
  },
  RESPOND_TO_SHOP_INVITE: {
    filterKeys: [...baseFilterKeys, 'response'],
    columnDefs: respondToShopInviteColumns,
    savedColumnsId: 'respondToShopInviteColumns',
  },
  SET_USER_STATE_AT_SHOP: {
    filterKeys: [
      ...baseShopFilterKeys,
      'targetUserID',
      'shopUserRole',
      'shopUserState',
    ],
    columnDefs: setUserStateAtShopColumns,
    savedColumnsId: 'setUserStateAtShopColumns',
  },
  REGISTER_TOOL_FOR_SHOP: {
    filterKeys: [
      ...baseShopFilterKeys,
      'toolModel',
      'toolManufacturer',
      'toolSerial',
      'toolID',
    ],
    columnDefs: registerToolForShopColumns,
    savedColumnsId: 'registerToolForShopColumns',
  },
  REMOVE_TOOL_FROM_SHOP: {
    filterKeys: [...baseShopFilterKeys, 'toolID'],
    columnDefs: removeToolFromShopColumns,
    savedColumnsId: 'removeToolFromShopColumns',
  },
  INITIATE_TOOL_RECOVERY: {
    filterKeys: [
      ...baseShopFilterKeys,
      'toolModel',
      'toolManufacturer',
      'toolSerial',
      'imageUrl',
      'recoveryStatus',
      'requesterTicketID',
    ],
    columnDefs: registerToolForShopColumns,
    savedColumnsId: 'registerToolForShopColumns',
  },
  EDIT_TOOL_FOR_SHOP: {
    filterKeys: [...baseShopFilterKeys, 'toolID', 'toolName', 'toolNotes'],
    columnDefs: editToolForShopColumns,
    savedColumnsId: 'editToolForShopColumns',
  },
  IMPORT_SHOP_TOOLS: {
    filterKeys: baseShopFilterKeys,
    columnDefs: importShopToolsColumns,
    savedColumnsId: 'importShopToolsColumns',
  },
  USER_TOOL_LOGIN: {
    filterKeys: baseFilterKeys,
    columnDefs: userToolLoginColumns,
    savedColumnsId: 'userToolLoginColumns',
  },
  USER_TOOL_ADINITIATE: {
    filterKeys: [
      ...baseToolFilterKeys,
      'toolManufacturer',
      'toolModel',
      'unlockReason',
      'vin,',
    ],
    columnDefs: userToolADInitiateColumns,
    savedColumnsId: 'userToolADInitiateColumns',
  },
  USER_TOOL_ADREQUEST: {
    filterKeys: [...baseToolFilterKeys, 'toolManufacturer', 'toolModel'],
    columnDefs: userToolADRequestColumns,
    savedColumnsId: 'userToolADRequestColumns',
  },
  USER_TOOL_ADEXECUTE: {
    filterKeys: [...baseToolFilterKeys, 'toolManufacturer', 'toolModel', 'vin'],
    columnDefs: userToolADExecuteColumns,
    savedColumnsId: 'userToolADExecuteColumns',
  },
  INVITE_USERS: {
    filterKeys: baseShopFilterKeys,
    columnDefs: inviteUsersColumns,
    savedColumnsId: 'inviteUsersColumns',
  },
  REVOKE_INVITE: {
    filterKeys: [...baseShopFilterKeys, 'userEmail'],
    columnDefs: revokeInviteColumns,
    savedColumnsId: 'revokeInviteColumns',
  },
  CREATE_SIGNUP_LINK: {
    filterKeys: [...baseShopFilterKeys, 'linkID'],
    columnDefs: createSignupLinkColumns,
    savedColumnsId: 'createSignupLinkColumns',
  },
  UPDATE_SIGNUP_LINK: {
    filterKeys: [...baseShopFilterKeys, 'linkID', 'linkState'],
    columnDefs: updateSignupLinkColumns,
    savedColumnsId: 'updateSignupLinkColumns',
  },
  USER_TOOL_ADINITIATE_ERROR: {
    filterKeys: [
      ...baseToolFilterKeys,
      'apiErrorMessage',
      'errorDetail',
      'vin',
    ],
    columnDefs: userToolADInitiateErrorColumns,
    savedColumnsId: 'userToolADInitiateColumns',
  },
  USER_TOOL_ADREQUEST_ERROR: {
    filterKeys: [...baseToolFilterKeys, 'toolModel', 'toolManufacturer'],
    columnDefs: userToolADRequestErrorColumns,
    savedColumnsId: 'userToolADRequestColumns',
  },
  USER_TOOL_ADEXECUTE_ERROR: {
    filterKeys: [...baseToolFilterKeys, 'toolModel', 'toolManufacturer', 'vin'],
    columnDefs: userToolADExecuteErrorColumns,
    savedColumnsId: 'userToolADExecuteErrorColumns',
  },
  AC_AUTO_SHOP_SUB_STATE_CHANGE: {
    filterKeys: [
      ...baseShopFilterKeys,
      'shopSubscriptionNotificationState',
      'shopSubscriptionState',
    ],
    columnDefs: acAutoShopSubStateChangeColumns,
    savedColumnsId: 'acAutoShopSubStateChangeColumns',
  },
  SEND_INVITE_APPROVED_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendInviteApprovedEmailColumns',
  },
  SEND_INVITE_DENIED_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendInviteDeniedEmailColumns',
  },
  SEND_SHOP_SUB_BILL_UPCOMING_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendShopSubBillUpcomingEmailColumns',
  },
  SEND_SHOP_SUB_PAST_DUE_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendShopSubPastDueEmailColumns',
  },
  SEND_SHOP_SUB_TERMINATED_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendShopSubTerminatedEmailColumns',
  },
  SEND_TRANS_REFUND_RECEIPT: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendTransRefundEmailColumns',
  },
  FORWARD_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendForwardEmailColumns',
  },
  SEND_EMAIL_WITH_ATTACH: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendEmailWithAttachColumns',
  },
  SEND_INVITE_REQ_RECEIVED: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendInviteReqReceivedEmailColumns',
  },
  SEND_ACC_ACTIVE_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendAccActiveEmailColumns',
  },
  SEND_SHOP_INVITE_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendShopInviteEmailColumns',
  },
  SEND_NON_USER_SHOP_INVITE_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendNonUserShopInviteEmailColumns',
  },
  SEND_SHOP_PAYMENT_RECEIPT_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendShopPaymentReceiptEmailColumns',
  },
  SEND_SHOP_SUB_PAYMENT_FAIL_EMAIL: {
    filterKeys: baseEmailFilterKeys,
    columnDefs: baseEmailLogColumns,
    savedColumnsId: 'sendShopSubPaymentFailEmailColumns',
  },
  SUPPORT_REMOVE_TOOL: {
    filterKeys: [...baseShopFilterKeys, 'toolID'],
    columnDefs: removeToolFromShopColumns,
    savedColumnsId: 'supportRemoveToolFromShopColumns',
  },
};
