// app environments
export const AA_ENV = {
  DEV: 'DEV',
  STAGE: 'STAGE',
  PROD: 'PROD',
};

export const USER_TYPES = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  STANDARD: 'STANDARD',
  EXTERNAL: 'EXTERNAL',
};

export const USER_STATES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const LOG_EVENTS = {
  CREATE_SELF_ACCOUNT: 'CREATE_SELF_ACCOUNT',
  CONFIRM_SELF_ACCOUNT: 'CONFIRM_SELF_ACCOUNT', //?
  IMPORT_USERS: 'IMPORT_USERS', // ?
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  CREATE_SHOP: 'CREATE_SHOP',
  UPDATE_SHOP_INFO: 'UPDATE_SHOP_INFO',
  UPDATE_SHOP_PAYMENT_INFO: 'UPDATE_SHOP_PAYMENT_INFO',
  REMOVE_SHOP_PAYMENT_INFO: 'REMOVE_SHOP_PAYMENT_INFO',
  UPDATE_SHOP_MAX_USERS: 'UPDATE_SHOP_MAX_USERS',
  ADD_USER_TO_SHOP: 'ADD_USER_TO_SHOP',
  REMOVE_USER_FROM_SHOP: 'REMOVE_USER_FROM_SHOP',
  RESPOND_TO_SHOP_INVITE: 'RESPOND_TO_SHOP_INVITE',
  SET_USER_STATE_AT_SHOP: 'SET_USER_STATE_AT_SHOP',
  REGISTER_TOOL_FOR_SHOP: 'REGISTER_TOOL_FOR_SHOP',
  REMOVE_TOOL_FROM_SHOP: 'REMOVE_TOOL_FROM_SHOP',
  INITIATE_TOOL_RECOVERY: 'INITIATE_TOOL_RECOVERY',
  IMPORT_SHOP_TOOLS: 'IMPORT_SHOP_TOOLS',
  USER_TOOL_LOGIN: 'USER_TOOL_LOGIN', // ?
  USER_TOOL_ADINITIATE: 'USER_TOOL_ADINITIATE',
  USER_TOOL_ADREQUEST: 'USER_TOOL_ADREQUEST',
  USER_TOOL_ADEXECUTE: 'USER_TOOL_ADEXECUTE', // ?
  INVITE_USERS: 'INVITE_USERS', // ?
  REVOKE_INVITE: 'REVOKE_INVITE', // ?
  CREATE_SIGNUP_LINK: 'CREATE_SIGNUP_LINK', // ?
  UPDATE_SIGNUP_LINK: 'UPDATE_SIGNUP_LINK', // ?
  USER_TOOL_ADINITIATE_ERROR: 'USER_TOOL_ADINITIATE_ERROR',
  USER_TOOL_ADREQUEST_ERROR: 'USER_TOOL_ADREQUEST_ERROR',
  USER_TOOL_ADEXECUTE_ERROR: 'USER_TOOL_ADEXECUTE_ERROR', // ?
  AC_AUTO_SHOP_SUB_STATE_CHANGE: 'AC_AUTO_SHOP_SUB_STATE_CHANGE',
};
