import React from 'react';
import { Dialog } from '@reach/dialog';

interface DisplayDialogProps {
  title: string;
  isOpen: boolean;
  is90?: boolean;
  children: React.ReactNode;
  onDismiss: () => void;
}

const DisplayDialog = (props: DisplayDialogProps) => {
  return (
    <Dialog
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      className={props.is90 ? "c-modal-slider-90"  : "c-modal-slider-75"}
      aria-label={props.title || ''}
    >
      <button
        className="c-btn-icon c-modal-slider__close"
        onClick={props.onDismiss}
      >
        <div className="c-btn__inner">
          <i className="c-btn__icon fal fa-times" />
        </div>
      </button>
      <h1 className="c-modal__heading">{props.title}</h1>

      <div className="c-modal__body">{props.children}</div>
    </Dialog>
  );
};

export default DisplayDialog;
