import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../context/User';
import { ChildProps } from '../types';
import { Loading } from './Loading';
import AlertModal from './AlertModal';

export type ProtectedRouteProps = {
  component: React.Component | any;
  props: ChildProps;
  permissions?: {
    roles?: string[];
  };
  permissionPath: string;
} & RouteProps;

let userHasAccess = true;

export default function AuthenticatedRoute({
  component: C,
  props: cProps,
  permissions,
  permissionPath,
  ...routeProps
}: ProtectedRouteProps) {
  const location = useLocation();
  const { user, error, fetchUser } = useUser();
  // Assume user has access unless we fetch user info and get an error

  useEffect(() => {
    if (!user && cProps.isAuthenticated && userHasAccess) {
      fetchUser();
    }
  }, [user, error, cProps, fetchUser]);

  function performLogout() {
    cProps.logout();
  }

  if (!cProps.isAuthenticated) {
    localStorage.setItem('lastVisitedPage', location.pathname);
    return (
      <Redirect to={`/?redirect=${location.pathname}${location.search}`} />
    );
  } else if (error) {
    userHasAccess = false;
    toast.error(error?.dataRetrieveError, {
      containerId: 'standard',
      autoClose: false,
    });
    return (
      <AlertModal
        title={'Login Error'}
        message={'Login Failed. You may not have access to this application.'}
        showModal={true}
        size={'small'}
        handleCancel={performLogout}
        BtnText={'OK'}
      />
    );
  } else if (!user) {
    return <Loading />;
  } else {
    return (
      <Route {...routeProps} render={(props) => <C {...props} {...cProps} />} />
    );
  }
}
