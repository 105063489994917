import React from 'react';

interface FieldRowProps {
  id: string;
  title: string;
  errorCondition?: boolean;
  errorMsg?: string;
  maxLength?: number | undefined;
  placeholder: string;
  value: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
}

const FieldRow = (props: FieldRowProps) => {
  return (
    <div className="c-field">
      <label htmlFor={props.id} className="c-field__label">
        {props.title}
      </label>
      <input
        className={`c-input ${props.errorCondition ? 'error' : ''}`}
        type="text"
        id={props.id}
        maxLength={props.maxLength || 50}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        readOnly={props.readOnly}
        disabled={props.readOnly}
      />
      {props.errorCondition ? (
        <div className="c-field__error">{props.errorMsg}</div>
      ) : null}
    </div>
  );
};

export default FieldRow;
