import React, { useRef } from 'react';

import Select from 'react-select';
import Option, { OptionType } from './ReactSelect/Option';
import DropdownIndicator from './ReactSelect/DropdownIndicator';

interface CustomReactSelectProps {
  id: string;
  options: Array<OptionType>;
  placeholder: string;
  value: Array<OptionType>;
  onChange: (newValue: any, actionMeta: any) => void;
}

const CustomReactSelect = (props: CustomReactSelectProps) => {
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: 'ALL ACTION CODES',
    label: 'ALL ACTION CODES',
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length;

  const isOptionSelected = (option: OptionType) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue: any, actionMeta: any) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === 'deselect-option' &&
        option.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <Select
      id={props.id}
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      isMulti
      isSearchable
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option,
        DropdownIndicator,
      }}
      placeholder={props.placeholder}
      menuPortalTarget={document.body}
      onChange={onChange}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base) => ({
          ...base,
          borderRadius: '26px',
          ':hover': {
            boxShadow: `0 0 0 0.05em #38bcfe, 0 0 0.15em 0.1em #38bcfe`,
            // borderColor: "#08c",
            border: '1px solid #08c',
          },
          ':focus-visible': {
            boxShadow: `0 0 0 0.05em #38bcfe, 0 0 0.15em 0.1em #38bcfe`,
            // borderColor: "#08c",
            border: '1px solid #08c',
          },
          ':focus-within': {
            boxShadow: `0 0 0 0.05em #38bcfe, 0 0 0.15em 0.1em #38bcfe`,
            // borderColor: "#08c",
            border: '1px solid #08c',
          },
          ':focus': {
            boxShadow: `0 0 0 0.05em #38bcfe, 0 0 0.15em 0.1em #38bcfe`,
            // borderColor: "#08c",
            border: '1px solid #08c',
          },
          borderColor: '#08c',
          padding: '0 1rem',
        }),
        option: (base, props) => ({
          ...base,
          font: 'Roboto, Helvetica, Arial, sans-serif',
          backgroundColor: props.isSelected ? '#e6e9eb' : 'transparent',
          color: 'red',
        }),
        input: (base) => ({
          ...base,
          border: 'none',
          '> input[type=text]:focus': {
            boxShadow: 'none',
          },
        }),
        valueContainer: (base) => ({
          ...base,
          boxShadow: 'none',
        }),
        placeholder: (base) => ({
          ...base,
          color: '#8a9499',
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: '#e6e9eb',
          color: '#08c',
          borderRadius: '15px',
          paddingLeft: '0.5em',
        }),
        multiValueRemove: (base) => ({
          ...base,
          borderRadius: '15px',
        }),
      }}
    />
  );
};

export default CustomReactSelect;
