import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import _ from 'underscore';

import Accordion from '../../components/Accordion';
import CopyLink from '../../components/CopyLink';
import { Header } from '../../components/Header';
import FieldWithHistory from '../../components/FieldWithHistory';
import LoaderButton from '../../components/LoaderButton';

import { getSystemConfig, getExtendedToolInfo } from '../../libs/db-lib';
import { toolInfoKeymap } from '../../libs/tables/tableKeymaps';

import {
  getRecentSearches,
  setRecentSearches,
  storeClipboard,
} from '../../libs/utils-ts';

import { ChildProps, Tool, RuntimeConfig } from '../../types';
import {
  toolValidate,
  generateFilterRegex,
  formatTimezone,
} from '../../libs/utils';
import CopyData from '../../components/CopyData';

interface ToolLookupState {
  queryType: string;
  query: string;
  isLoading: boolean;
  queriedTools: Tool[];
  queriedToolsFiltered: Tool[];
  filter: string;
  showToolInfo: boolean;
  queryError: string;
  config: RuntimeConfig;
  windowWidth: number;
  baseURL: string;
}

let state = {
  queryType: 'TOOL ID',
  query: '',
  isLoading: false,
  queriedTools: [] as Tool[],
  queriedToolsFiltered: [] as Tool[],
  filter: '',
  config: {
    baseUrl: '',
    braintreeMode: '',
    merchantId: '',
  },
  windowWidth: 0,
  baseURL: '',
  showToolInfo: false,
  queryError: '',
};

export default class ToolLookup extends Component<ChildProps, ToolLookupState> {
  constructor(props: ChildProps) {
    super(props);
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams.get('query');
    const queryType = searchParams.get('queryType');
    if(query && queryType) {
      state.query = query;
      state.queryType = queryType;
    }
    // This will initial state on first time, but stores previous state
    // when navigating back to the page
    this.state = state;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.location !== prevProps.location) {
      // URL has changed
      this.checkURLChange();
    }
  }
  checkURLChange() {
    const searchParams = new URLSearchParams(window.location.search);
    const query = (searchParams.get('query'))?.replace(/ /g, '+');
    const queryType = searchParams.get('queryType');
    const toolSerial = localStorage.getItem('toolSerial');
    const toolID = localStorage.getItem('toolID');
    if(query && queryType) {
      this.setState({
        query,
        queryType,
      });
      this.handleSubmit(undefined, query, queryType);
    } else if (toolID) {
      this.setState({
        query: toolID,
        queryType: 'TOOL ID'
      });
      localStorage.removeItem('toolID');
      this.handleSubmit(undefined, toolID, "TOOL ID");
    } else if (toolSerial) {
      this.setState({
        query: toolSerial,
        queryType: 'TOOL SERIAL'
      });
      localStorage.removeItem('toolSerial');
      this.handleSubmit(undefined, toolSerial, "TOOL SERIAL");
    }
  }

  async componentDidMount() {
    this.checkURLChange();
    let config = await getSystemConfig();
    this.setState({
      config: config,
      baseURL: config.baseUrl,
      windowWidth: window.outerWidth,
    });

    storeClipboard(this.state.queriedTools);
    window.addEventListener('resize', this.setWindowWidth);
  }

  componentWillUnmount() {
    // Setting state (global file variable) to this.state allowed persistance
    // of state when navigating back to the component later
    state = { ...this.state, isLoading: false };
  }

  setWindowWidth = () => {
    setTimeout(this.setStateWidth.bind(this), 20);
  };

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | string,    // If event is from a FieldWithHistory the event will just be a string
    fieldId?: string
  ) => {
    if (typeof event === 'string') {
      if (this.state.queryType === 'TOOL ID') {
        this.setState({
          queryError: !toolValidate(event)
            ? 'Tool ID must be exactly 64 characters long (lowercase letters and numbers).'
            : '',
        });
        event = event.toLowerCase();
      }
      this.setState({
        [fieldId as any]: event,
      } as any);
    } else if (event) {
      this.setState({
        [event.target.id]: event.target.value,
        queryError: '',
        query: '',
      } as any);
    }
  };

  handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter: e.target.value,
    });

    const updateFilter = _.debounce((f: string) => {
      this.updateFilteredList(f);
    }, 300);

    updateFilter(e.target.value);
  };

  updateFilteredList = (filter: string) => {
    let toolList = this.state.queriedTools;

    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);
      toolList = this.state.queriedTools.filter((u) => {
        if (
          regexStr.test(u.toolID) ||
          regexStr.test(u.toolManufacturer) ||
          regexStr.test(u.toolModel) ||
          regexStr.test(u.toolSerial)
        ) {
          return true;
        }
        return false;
      });
    }
    this.setState({
      queriedToolsFiltered: toolList || [],
    });
  };

  validateForm() {
    const { query, queryType } = this.state;
    switch (queryType) {
      case 'TOOL ID':
        return toolValidate(query);
      case 'TOOL SERIAL':
        return query.length > 0;
      default:
        return false;
    }
  }

  handleSubmit = async (
    e?: React.SyntheticEvent,
    query?: string,
    queryType?: string,
  ) => {
    e?.preventDefault();

    this.setState({ isLoading: true });
    if (!queryType) {
      queryType = this.state.queryType;
    }
    if (!query) {
      query = this.state.query;
    }

    try {
      let result = await getExtendedToolInfo(query, queryType);

      if (Object.hasOwn(result, 'error')) {
        if ([401, 403].indexOf(result.error.status) === -1) {
          this.props.handleShowAlert('Error', result.error);
          this.setState({
            showToolInfo: false,
            queriedTools: [],
            queriedToolsFiltered: [],
          });
        }
        return;
      } else {
        let pageData: { [k: string]: any } = {};
        if (!Array.isArray(result)) {
          result = [result];
        }
        result.forEach((tool: Tool, index: number) => {
          pageData[index] = tool;
        });
        storeClipboard(pageData);
        this.setState({
          queriedTools: result[0].tools,
          queriedToolsFiltered: result[0].tools,
          showToolInfo: true,
        });
      }
    } catch (e: any) {
      this.props.handleShowAlert(
        'Error',
        <span>
          {`Could not find a tool using a ${queryType} for this query: `}
          <strong>{query}</strong>
        </span>,
        false
      );
      console.log(e.message);
      this.setState({
        showToolInfo: false,
      });
    } finally {
      this.setState({
        isLoading: false,
      });

      let recentSearches = getRecentSearches(queryType);

      if (recentSearches) {
        if (recentSearches.length > 4) recentSearches.pop();
        if (query && recentSearches.indexOf(query) === -1) recentSearches.unshift(query);
      } else {
        recentSearches = query ? [query] : [];
      }
      setRecentSearches(queryType, recentSearches);
    }
  };

  getPlaceholder = () => {
    switch (this.state.queryType) {
      case 'TOOL ID':
        return '64 characters long';
      case 'TOOL SERIAL':
        return 'Serial numbers vary';
    }
  };

  getQueryTypeHelper = () => {
    switch (this.state.queryType) {
      case 'TOOL ID':
        return `${this.state.queryType} (lowercase)`;
      case 'TOOL SERIAL':
        return `${this.state.queryType} (case-sensitive)`;
    }
  };

  render() {
    const { queriedTools, queriedToolsFiltered } = this.state;

    let toolInfoAccordions = queriedToolsFiltered
      .sort((a, b) => a.toolManufacturer ? a.toolManufacturer.localeCompare(b.toolManufacturer) : 0)
      .map((tool: Tool) => {
        let reordered = {
          toolManufacturer: null,
          toolModel: null,
          toolSerial: null,
          toolID: null,
          addedBy: null,
          addedOn: null,
          toolModelUUID: null,
          toolManufacturerUUID: null,
        };

        tool = Object.assign(reordered, tool);
        let toolInfo = Object.keys(tool).map((key) => {
          let monoFields = [
            'toolSerial',
            'toolID',
            'currentShopID',
            'addedBy',
            'toolModelUUID',
            'toolManufacturerUUID',
          ];

          let clickable = {
            currentShopID: {
              type: 'SHOP ID',
              redirect: '/shopFunctions/getShopSummary',
              queryType: 'SHOP+ID',
            },
            addedBy: {
              type: 'USER ID',
              redirect: '/userFunctions/getUserSummary',
              queryType: 'USER+ID',
            },
          };

          let monoStyle = monoFields.indexOf(key) === -1 ? '' : 'u-font-mono';

          let value = tool[key as keyof Tool] as string | JSX.Element;

          if (key === 'addedOn') {
            value = formatTimezone(value, this.props.user.userTimezone);
          }

          if (key === 'isCurrentlyAssignedToShop') {
            value = value ? 'Yes' : 'No';
          }

          if (clickable.hasOwnProperty(key)) {
            value = (
              <CopyLink
                content={value}
                type={clickable[key].type}
                redirect={clickable[key].redirect}
                urlParams={`?query=${value}&queryType=${clickable[key].queryType}`}
              />
            );
          }

          return (
            <tr className="card-tr" key={key}>
              <th
                className={
                  this.state.windowWidth > 450
                    ? 'card-th card-table-col-labels-20'
                    : ''
                }
              >
                {toolInfoKeymap[key as keyof Object]+': '}
              </th>
              <td className={`card-td ${monoStyle}`}>{value}</td>
            </tr>
          );
        });

        return (
          <Accordion
            title={`Manufacturer: ${tool.toolManufacturer === 'none' ? 'Unkown' : tool.toolManufacturer}.   Model: ${tool.toolModel === 'none' ? 'Unknown' : tool.toolModel}.`}
            key={tool.toolID}
          >
            <div className="c-card u-margin-bottom-large l-flex-center">
              <div className="c-card__description">
                <table className="card-table">
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <tbody>{toolInfo}</tbody>
                </table>
              </div>
            </div>
          </Accordion>
        );
      });

    return (
      <>
        <Header context="Shop Functions" title="Tool Lookup" />

        <div className="l-container">
          <form>
            <div className="c-field">
              <label htmlFor="queryType" className="c-field__label">
                Query Type
              </label>
              <div className="c-select">
                <select
                  onChange={this.handleChange}
                  id="queryType"
                  value={this.state.queryType}
                >
                  {['TOOL ID', 'TOOL SERIAL'].map((type, i) => {
                    return <option key={i}>{type}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="c-field">
              <label
                htmlFor="query"
                className="c-field__label u-margin-top-large"
              >
                {this.getQueryTypeHelper()}
              </label>
              <FieldWithHistory
                fieldId="query"
                searchKey={this.state.queryType}
                selected={this.state.query}
                onInputChange={this.handleChange}
                onChange={this.handleChange}
                placeholder={this.getPlaceholder()}
                onEnter={() => {
                  if (this.validateForm()) this.handleSubmit();
                }}
              />
              {this.state.queryError && (
                <div className="u-text-error u-margin-left-large">
                  <small>{this.state.queryError}</small>
                </div>
              )}
            </div>

            <div className="c-field">
              <LoaderButton
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text="Get Tool"
                loadingText="Searching..."
                onClick={this.handleSubmit}
              />
            </div>
          </form>
        </div>
        {this.state.showToolInfo && (
          <>
          <CopyData info={queriedTools} />
          <FadeIn visible={!this.state.isLoading}>
            {queriedTools.length > 2 && (
              <div className="c-field u-margin-top-small u-margin-right-small">
                <label
                  htmlFor="filter"
                  className="c-field__label u-is-vishidden"
                >
                  Filter
                </label>
                <input
                  type="text"
                  id="filter"
                  maxLength={50}
                  className="c-input"
                  placeholder="Filter (Manufacturer, Model, Serial, ID)"
                  value={this.state.filter}
                  onChange={this.handleChangeFilter}
                />
                <i className="c-field__input-icon fal fa-search" />
              </div>
            )}
            <div>{toolInfoAccordions}</div>
          </FadeIn>
          </>
        )}
      </>
    );
  }
}
